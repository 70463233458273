import { render, staticRenderFns } from "./_edit.vue?vue&type=template&id=00e32e7b&scoped=true&"
import script from "@/assets/js/components/admin/course/_edit.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/admin/course/_edit.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/admin/course/_edit.scss?vue&type=style&index=0&id=00e32e7b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e32e7b",
  null
  
)

export default component.exports