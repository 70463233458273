import Layout from "@/layouts"

// components
const CountyReport = resolve => require(["@/components/report/_county_report.vue"], resolve)

export default {
    name: 'Report',
    components: {
        Layout,
        CountyReport,
    },
    computed: {
    },
    created: function() {
    },
    methods: {
    }
}
