import Layout from "@/layouts"

// common
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const Course = resolve => require(["@/components/learning_center/course.vue"], resolve)

export default {
    name: 'LearningCenter',
    components: {
        Layout,
        MobileUnsupported,
        Course,
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        componentType() {
            // '學習總覽' 頁面完成後即可移除
            if (!this.$route.params.page) {
                return Course;
            }
            return this.$route.params.page;
        },
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        this.setQueryRole();
    },
    methods: {
        setQueryRole() {
            if (!Object.keys(this.userInfo).length) {
                return;
            }

            const roleName = this.userInfo.permission === 'user' ? 'student' : 'teacher';
            if (!this.$route.query.role || (this.$route.query.role !== roleName)) {
                this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, role: roleName } });
            }
        },
    },
}
