import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allUserCouponIds', 'newCouponList', 'deletingUserCouponIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            userCouponList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        newCouponList(newValue) {
            if (newValue) {
                this.userCouponList = newValue.concat(this.userCouponList);
                this.loadingIndex = this.userCouponList.length;
            }
        },
        deletingUserCouponIds(newValue) {
            if (newValue) {
                for (let id of newValue) {
                    this.userCouponList = this.$_.remove(this.userCouponList, el => {
                        return el.id !== id;
                    });
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        orderUtil() {
            return this.$store.state.order.orderUtil;
        },
        selectedCoupon: {
            get() {
                return this.$store.state.order.selectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setSelectedCoupon', value);
            }
        }
    },
    created: function() {
        if (this.allUserCouponIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                setTimeout(async () => {
                    await this.getUserCouponList();
                    this.busy = false;
                }, 200);
            }
        },
        async getUserCouponList() {
            let userCouponIds = [];

            for (let i = 0; i < this.loadCountPerPage; i++) {
                let tempCouponId = this.allUserCouponIds[this.loadingIndex];
                if (!tempCouponId) {
                    this.isGetAllList = true;
                    break;
                }
                userCouponIds.push(tempCouponId);
                this.loadingIndex++;
            }

            if (userCouponIds.length == 0) {
                return;
            }

            let newCouponList = await this.$store.dispatch('user/getCouponsInfo', { userCouponIds: userCouponIds });
            this.userCouponList = this.userCouponList.concat(newCouponList);
        }
    }
}
