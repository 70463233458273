// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const TitleBar = resolve => require(["@/components/admin/course/edit/_title_bar.vue"], resolve)
const Sidebar = resolve => require(["@/components/admin/course/_sidebar.vue"], resolve)
const Info = resolve => require(["@/components/admin/course/edit/info.vue"], resolve)
const Articles = resolve => require(["@/components/admin/course/edit/articles.vue"], resolve)
const Sales = resolve => require(["@/components/admin/course/edit/sales.vue"], resolve)
const Editors = resolve => require(["@/components/admin/course/edit/editors.vue"], resolve)
const RemoveSettings = resolve => require(["@/components/admin/course/edit/remove_settings.vue"], resolve)
const Statistics = resolve => require(["@/components/admin/course/edit/statistics.vue"], resolve)

export default {
    components: {
        Dialogue,
        TitleBar,
        Sidebar,
        Info,
        Articles,
        Sales,
        Editors,
        RemoveSettings,
        Statistics
    },
    data: function() {
        return {
            courseBasicInfoParams: {
                courseId: this.$route.params.id
            },

            editLogList: [],

            isSetDataReady: {
                getCourseBasicInfo: false  // 取得課程基本資訊
            },
            isPostingApi: {
                changeState: false  // 改變課程狀態
            }
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '課程資料',
                    url: `/admin/course/edit/info/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: '課程文章',
                    url: `/admin/course/edit/articles/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: '銷售方案',
                    url: `/admin/course/edit/sales/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: '負責編輯',
                    url: `/admin/course/edit/editors/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: this.getRemoveSettingsTitle(),
                    url: `/admin/course/edit/remove_settings/${this.$route.params.id}`,
                    isShow: this.$store.state.common.userInfo.permission == 'admin' || (this.$store.state.common.userInfo.permission == 'teacher' && this.courseBasicInfo.state == '0')
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        additionalSidebarList() {
            let list = [];

            list = [
                {
                    name: '課程報表',
                    url: `/admin/course/edit/statistics/${this.$route.params.id}`,
                    isShow: ['3', '4', '5'].includes(this.courseBasicInfo.state)
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        componentType() {
            switch (this.$route.params.subPage) {
                case 'info':
                    return Info;
                case 'articles':
                    return Articles;
                case 'sales':
                    return Sales;
                case 'editors':
                    return Editors;
                case 'remove_settings':
                    return RemoveSettings;
                case 'statistics':
                    return Statistics;
            }
        },
        editLogTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "tab_name",
                    itemText: "頁籤名稱",
                    ownClass: "edit-log-tab-name",
                    isShow: true
                },
                {
                    itemName: "time",
                    itemText: "編輯時間",
                    ownClass: "edit-log-time",
                    isShow: true
                },
                {
                    itemName: "user",
                    itemText: "編輯人員",
                    ownClass: "edit-log-user",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        courseBasicInfo() {
            return this.$store.state.adminCourse.courseBasicInfo;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminCourse.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminCourse/setIsClickSidebarLink', value);
            }
        },
        isFinishUpdatingData: {
            get() {
                return this.$store.state.adminCourse.isFinishUpdatingData;
            },
            set(value) {
                this.$store.commit('adminCourse/setIsFinishUpdatingData', value);
            }
        }
    },
    created: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            let refreshCourseBasicInfo = () => {
                this.isSetDataReady.getCourseBasicInfo = false;

                this.$store.dispatch('adminCourse/getCourseBasicInfo', this.courseBasicInfoParams)
                    .then(() => {
                        this.isSetDataReady.getCourseBasicInfo = true;

                        // 課程狀態 編輯中/待審核/已排程, 沒有 課程報表 頁面
                        if (!['3', '4', '5'].includes(this.courseBasicInfo.state) && this.$route.params.subPage == 'statistics') {
                            this.$router.push('/admin/course/list/all');
                        }
                        // 使用者權限為 teacher 時, 如果課程狀態不是編輯中, 沒有 移除/停售/封存課程 頁面
                        if (this.$store.state.common.userInfo.permission == 'teacher' &&
                            this.courseBasicInfo.state != '0' &&
                            this.$route.params.subPage == 'remove_settings') {
                            this.$router.push(`/admin/course/edit/info/${this.$route.params.id}`);
                        }
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                        this.$router.push('/admin/course/list/all').catch(() => {});
                    });
            }

            refreshCourseBasicInfo();
        },

        getRemoveSettingsTitle() {
            switch (this.courseBasicInfo.state) {
                // 編輯中(0) / 待審核(1) / 已排程(2) / 已封存(5)
                case '0': case '1': case '2': case '5':
                    return '移除設定';
                // 上架中(3)
                case '3':
                    return '停售設定';
                // 停售中(4)
                case '4':
                    return '封存設定';
            }
        },
        getEditLogs() {
            $('.lms-btn.icon-circle.log').blur();

            let params = {
                courseId: this.$route.params.id
            }

            this.editLogList = [];

            this.$httpRequest.get('/admin_api/course/get_course_edit_log', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            result.forEach(el => {
                                this.editLogList.push({
                                    tabName: el.behavior,
                                    editedTime: this.$util.unixTimestampToDatetime(el.enterdate, this.$util.getBrowserCurrentTimeZone()),
                                    editedUser: el.name
                                });
                            });

                            $('#courseEditLogDialogue').modal('show');
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        changeCourseState(options) {
            // options = {
            //     activeDialogue: 'xxx',  // 目前開啟彈窗
            //     targetAction: 'xxx',  // 'edit' | 'audit' | 'schedule' | 'stopSelling' | 'archive' | 'delete'
            //     isPublishNow: '0' | '1',  // 審核 -> 排程/上架 (action = 'schedule')
            //     publishTime: '1649637000',  // 排程上架時間 (action = 'schedule')
            //     stopSellingTime: '1649637000',  // 排程停售時間 (action = 'schedule')
            //     noticeMsg: 'xxx'  // 移除/停售/封存通知 (action = 'delete' | 'stopSelling' | 'archive')
            // }

            if (!this.$route.params.id || this.isPostingApi.changeState) {
                return;
            }

            let params = {
                courseId: this.$route.params.id,
                action: options.targetAction
            }

            // 課程狀態將改為 { 已排程 | 上架中 }
            if (params.action === 'schedule') {
                params.isNow = options.isPublishNow;  // 是否立即上架

                // 如果課程狀態將改為"已排程", 則參數需加上排程上架時間
                if (params.isNow == '0') {
                    params.time = options.publishTime;
                }
                // 如果排程停售時間有做設定, 則參數需加上排程停售時間
                // 課程上架時, 如果排程停售時間有做設定, 則可修改排程停售時間
                params.unpublishTime = options.stopSellingTime;
            }

            // 移除課程 or 課程狀態將改為 { 停售中 | 已封存 }
            if (params.action === 'delete' || params.action === 'stopSelling' || params.action === 'archive') {
                // 如果通知訊息有內容, 則參數需加上通知內容
                if (options.noticeMsg) {
                    params.msg = options.noticeMsg;
                }
            }

            this.isPostingApi.changeState = true;

            this.$httpRequest.post('/admin_api/course/change_course_state', params)
                .then(response => {
                    this.isPostingApi.changeState = false;

                    if (response.data.state == 'OK') {
                        $(`#${options.activeDialogue}`).modal('hide');  // 關閉提示彈窗
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        // 修改課程狀態需重新取得 header '購物車' 資訊
                        this.$store.state.common.isSetHeaderDataReady.getMyCartData = false;

                        // 除了移除課程導向"課程列表"頁面外, 其他成功改變狀態後, 皆導向編輯頁面中的"課程資料"頁面
                        let targetPage = '';
                        if (params.action === 'delete') {
                            targetPage = '/admin/course/list/all';
                        } else {
                            targetPage = `/admin/course/edit/info/${this.$route.params.id}`;
                            // 重新取得課程基本資料
                            this.$store.dispatch('adminCourse/getCourseBasicInfo', this.courseBasicInfoParams);
                        }
                        setTimeout(() => {
                            this.$router.push(targetPage).catch(() => {});
                        }, 100);
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
    }
}
