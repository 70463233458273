import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const AccountInfoDialogue = resolve => require(["@/components/user/profile/_account_info_dialogue.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        AccountInfoDialogue
    },
    data: function() {
        return {
            countrySelectedList: [],
            countyCitySelectedList: [''],
            eduLevelSelectedList: [''],

            avatarCropper: null,  // 個人頭像裁剪器
            isSettingAvatarCropper: false,  // 是否正在設置個人頭像裁剪器
            tempCroppedAvatar: {
                name: '',
                file: null
            },

            isSetDataReady: {
                getUserDetailInfo: false,  // 取得個人資訊
            },
            isPostingApi: {
                updateAvatar: false,  // 更新個人頭像
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userDetailInfo: {
            get() {
                return this.$store.state.user.userDetailInfo;
            },
            set(value) {
                this.$store.commit('user/setUserDetailInfo', value);
            }
        },
        oldUserDetailInfo: {
            get() {
                return this.$store.state.user.oldUserDetailInfo;
            },
            set(value) {
                this.$store.commit('user/setOldUserDetailInfo', value);
            }
        },
        countyDistrict() {
            return this.$store.state.common.countyDistrict;
        },
        genderSelectedList() {
            let list = [
                {
                    text: '男',
                    value: '1'
                },
                {
                    text: '女',
                    value: '2'
                },
                {
                    text: '保密',
                    value: '0'
                }
            ]
            return list;
        },
        isCellPhoneInputValid() {
            let regExp = /^0{0,1}9[0-9]{8}$/; // 手機號碼必須09開頭
            return value => value == '' ? true : regExp.test(value);
        },
        isEmailInputValid() {
            let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // email格式
            // 不是email格式則為 false
            return value => !value || regExp.test(value);  // 排除 value 為空的情況
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'userProfile');

        this.getUserDetailInfo();
    },
    mounted: function() {
        // 關閉裁剪個人頭像圖片彈窗後, 銷毀並初始 avatarCropper
        $('#cropAvatarImageDialogue').on('hidden.bs.modal', () => {
            if (!this.$_.isEmpty(this.avatarCropper)) {
                this.avatarCropper.destroy();
                this.avatarCropper = null;
            }
            $('#cropAvatarImageDialogue').off('shown.bs.modal');  // 銷毀已註冊的事件, 防止重複註冊
        });
    },
    methods: {
        async getUserDetailInfo() {
            await this.getOptions();
            await this.$store.dispatch('common/getCountyDistrict');
            this.countyCitySelectedList = this.$_.concat(this.countyCitySelectedList, this.countyDistrict.counties);

            this.$httpRequest.get('/api/user/get_profile_detail')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.userDetailInfo = {
                                id: result.id,
                                account: result.account,
                                accountType: result.type,
                                isShowChangePassword: result.can_change_password,
                                avatar: result.avatar.origin_url,
                                name: result.name,
                                birth: result.birth ? result.birth.replace(/-/g, '/') : null,
                                gender: result.gender,
                                country: result.country ? result.country : '臺灣',
                                counties: result.counties,
                                eduLevel: result.edu_level,
                                buyerName: result.buyer_name,
                                buyerPhone: result.buyer_phone,
                                buyerTelephone: result.buyer_telephone,
                                contactEmail: result.contact_email
                            }

                            // 暫存原本的使用者資訊, 以用來判斷是否需更新使用者資訊
                            this.oldUserDetailInfo = this.$_.cloneDeep(this.userDetailInfo);

                            this.isSetDataReady.getUserDetailInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getOptions() {
            // 若選項陣列裡已有內容時, 則不再 call api
            if (this.countrySelectedList.length && this.eduLevelSelectedList.length) {
                return;
            }

            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/user/get_options')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.countrySelectedList = result.country;
                                this.eduLevelSelectedList = this.$_.concat(this.eduLevelSelectedList, result.edu_level);

                                resolve();
                            } else {
                                reject("user/profile.js: 'getOptions' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("user/profile.js: 'getOptions' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("user/profile.js: 'getOptions' get error");
                    });
            });
        },

        uploadAvatarFile() {
            let tempUploadFile = this.$refs.uploadAvatar.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg', 'heic'];
            let perFileLimitSize = 5242880;  // 5mb

            let uploadErrorMsg = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!uploadErrorMsg && tempUploadFile.type.match(/^image\//)) {
                this.tempCroppedAvatar.name = tempUploadFile.name;

                // 鎖住點擊非彈窗區域關閉彈窗, 避免拉大裁剪框時而關閉彈窗
                $('#cropAvatarImageDialogue').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                this.isSettingAvatarCropper = true;

                $('#cropAvatarImageDialogue').on('shown.bs.modal', () => {
                    let reader = new FileReader();
                    reader.readAsDataURL(tempUploadFile);
                    reader.onload = () => {
                        let dataURL = reader.result;
                        document.querySelector('#cropAvatarImg').src = dataURL;
                        const image = document.getElementById('cropAvatarImg');
                        // 建立cropper實例
                        this.avatarCropper = new Cropper(image, {
                            aspectRatio: 1,
                            viewMode: 3,
                            dragMode: 'move',
                        });
                        this.isSettingAvatarCropper = false;
                    }
                });
            } else {
                // 上傳格式或大小錯誤, 則顯示 toast 訊息提示使用者
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: uploadErrorMsg });
            }

            this.$refs.uploadAvatar.value = null;
        },
        cropAvatarImage() {
            return new Promise((resolve, reject) => {
                let dataURL = this.avatarCropper.getCroppedCanvas().toDataURL("image/jpeg", 1.0);

                // dataURL to file
                this.$util.dataURLToFile(dataURL, this.tempCroppedAvatar.name, "image/jpeg")
                    .then(file => {
                        this.tempCroppedAvatar.file = file;

                        resolve();
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("user/profile.js: 'cropAvatarImage' error");
                    });
            });
        },
        async updateAvatar() {
            await this.cropAvatarImage();

            let params = new FormData();
            params.append('upload', this.tempCroppedAvatar.file);

            this.isPostingApi.updateAvatar = true;

            this.$httpRequest.post('/api/user/update_avatar', params)
                .then(response => {
                    this.isPostingApi.updateAvatar = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.userDetailInfo.avatar = result.origin_url;

                            // 更新 header 個人頭像
                            this.$store.dispatch('common/updateUserInfo', { avatar: result.thumb_url });

                            // 銷毀個人頭像裁剪器並關閉裁剪個人頭像彈窗
                            this.avatarCropper.destroy();
                            this.avatarCropper = null;
                            $('#cropAvatarImageDialogue').modal('hide');

                            this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.updateAvatar = false;
                    console.log('catched error: ' + error);
                });
        },

        showAccountInfoDialogue() {
            $('#accountInfoDialogue').modal('show');
        },

        disableDateAfterToday(date) {
            // disable大於今天以前的日期
            return date > new Date().setHours(0, 0, 0, 0);
        }
    }
}
