import Layout from "@/layouts"

// components
const Index = resolve => require(["@/components/feedback/index.vue"], resolve)
const Success = resolve => require(["@/components/feedback/success.vue"], resolve)

export default {
    name: 'Feedback',
    components: {
        Layout,
        Index,
        Success,
    },
    computed: {
        layout() {
            return this.$store.state.common.layout;
        },
        componentType() {
            switch (this.$route.params.page) {
                case 'success':
                    return Success;
                default:
                    return Index;
            }
        },
        renderComponentKey() {
            return Object.values(this.$route.query).join('|');
        },
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);
    },
}
