import Layout from "@/layouts"

// common
const CourseCard = resolve => require(["@/components/common/card/course.vue"], resolve)
const ArticleCard = resolve => require(["@/components/common/card/article.vue"], resolve)
// components
const AdCarousel = resolve => require(["@/components/home/_ad_carousel.vue"], resolve)
const AnnouncementDialogue = resolve => require(["@/components/announcement/_announcement_dialogue.vue"], resolve)

export default {
    name: 'Home',
    components: {
        Layout,
        CourseCard,
        ArticleCard,
        AdCarousel,
        AnnouncementDialogue
    },
    data: function() {
        return {
            adCarouselList: [],

            allPublicArticleList: [],
            articleListPageIndex: {},
            renderedArticleList: [],

            allRecommendSegmentList: [],

            renderCarouselKey: 0,
            isSetDataReady: {
                getAdCarouselList: false,  // 取得banner資訊
                getPublicArticleList: false,  // 取得試閱文章列表
                getRecommendCourseList: false,  // 取得推薦課程列表
            }
        }
    },
    watch: {
        windowWidth(newValue, oldValue) {
            if ((newValue < 768 && oldValue >= 768) || (newValue >= 768 && oldValue < 768)) {
                this.renderCarouselKey++;
                // 處理列表資料
                this.handleArticleListData();
                for (const segment of this.allRecommendSegmentList) {
                    let renderInfo = this.handleCourseListData(segment.courseList);
                    segment.courseListPageIndex = renderInfo.courseListPageIndex;
                    segment.renderedCourseList = renderInfo.renderedCourseList;
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        layout() {
            return this.$store.state.common.layout;
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        this.getAdCarouselList();
        this.getPublicArticleData();
        this.getRecommendCourseData();

        this.$util.updateMeta(process.env.VUE_APP_HOME_TITLE, process.env.VUE_APP_HOME_DESCRIPTION);
    },
    methods: {
        getAdCarouselList() {
            this.$httpRequest.get('/api/home/get_banner')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let list = [];
                            for (let el of result) {
                                let targetUrl = '';
                                if (el.is_device_link.Web) {  // Web需要做素材連結
                                    // 依據各觸發類型區分連結位置
                                    switch (el.resource.type) {
                                        case '1':  // 產品介紹
                                            targetUrl = '/introduction';
                                            break;
                                        case '2':  // 課程
                                            targetUrl = `/course/${el.resource.alias}`;
                                            break;
                                        case '3':  // 外部連結
                                            targetUrl = el.resource.url;
                                            break;
                                    }
                                }

                                list.push({
                                    id: el.id,
                                    url: targetUrl,
                                    image: el.image.file_url,
                                    isNewTab: el.resource.type === '3'  // 是否開啟新分頁
                                });
                            }

                            this.adCarouselList = list;

                            this.isSetDataReady.getAdCarouselList = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        // Course & Article List common
        initPageIndex(allListLength, perPageItemCount) {
            if (!allListLength) {
                return;
            }

            return {
                current: 0,
                max: allListLength % perPageItemCount == 0 ?
                        parseInt(allListLength / perPageItemCount) - 1 :
                        parseInt(allListLength / perPageItemCount)
            }
        },
        filterRenderList(allRenderList, pageIndex, perPageItemCount, action = '') {
            if (!allRenderList.length) {
                return;
            }

            if (action) {
                this.changePage(action, pageIndex);
            }

            if (this.windowWidth < 768) {
                return allRenderList;
            }
            // 解析度寬度在768以上, 一頁顯示 {perPageItemCount} 個課程
            else {
                return allRenderList.slice(perPageItemCount * pageIndex.current, perPageItemCount * (pageIndex.current + 1));
            }
        },
        changePage(action, pageIndex) {
            switch (action) {
                case 'prev':
                    pageIndex.current--;
                    if (pageIndex.current < 0) {
                        pageIndex.current = pageIndex.max;
                    }
                    break;
                case 'next':
                    pageIndex.current++;
                    if (pageIndex.current > pageIndex.max) {
                        pageIndex.current = 0;
                    }
                    break;
            }
        },

        async getPublicArticleData() {
            await this.getPublicArticleList();
            this.handleArticleListData();  // 處理文章列表資料

            this.isSetDataReady.getPublicArticleList = true;
        },
        getPublicArticleList() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/home/get_public_article_list')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let el of result) {
                                    this.allPublicArticleList.push({
                                        id: el.id,
                                        name: el.article_name,
                                        cover: el.file_path,
                                        category: el.category_name,
                                        difficultyLevel: el.difficulty_level,
                                        practiceTypes: el.practice_types,
                                        courseName: el.course_name
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        handleArticleListData() {
            let perPageItemCount = 8;  // 文章列表一頁顯示8篇 (解析度 768~)

            // 初始左右箭頭 pageIndex
            this.articleListPageIndex = this.initPageIndex(this.allPublicArticleList.length, perPageItemCount);

            // 濾出目前要渲染在畫面上的資料
            this.renderedArticleList = this.filterRenderList(this.allPublicArticleList, this.articleListPageIndex, perPageItemCount);
        },

        async getRecommendCourseData() {
            let courseInfo = await this.getRecommendCourseInfo();

            if (courseInfo) {
                for (let item of courseInfo) {
                    await this.getRecommendCourseList(item);
                }
            }

            this.isSetDataReady.getRecommendCourseList = true;
        },
        getRecommendCourseInfo() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/home/get_landing_page_segment_info')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                resolve(result);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        getRecommendCourseList(item) {
            return new Promise((resolve, reject) => {
                let params = {
                    courseIds: item.course_ids
                }

                this.$httpRequest.post('/api/home/get_landing_page_course_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let courseList = [];
                                for (let id of item.course_ids) {
                                    let tempCourse = result[id];
                                    if (tempCourse) {
                                        courseList.push({
                                            id: tempCourse.course_id,
                                            alias: tempCourse.alias,
                                            isPreOrder: tempCourse.is_pre_order,
                                            name: tempCourse.course_name,
                                            subName: tempCourse.sub_name,
                                            cover: tempCourse.cover_url,
                                            purchaseType: tempCourse.purchase_type,
                                            price: tempCourse.price,
                                            suitable: tempCourse.suitable,
                                            difficultyLevel: tempCourse.difficulty_level,
                                            isPurchased: tempCourse.is_purchased == '1'
                                        });
                                    }
                                }

                                let renderInfo = this.handleCourseListData(courseList); // 處理課程列表資料

                                this.allRecommendSegmentList.push({
                                    name: item.seg_name,
                                    content: item.content,
                                    courseList: courseList,
                                    courseListPageIndex: renderInfo.courseListPageIndex,
                                    renderedCourseList: renderInfo.renderedCourseList
                                });

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        handleCourseListData(courseList) {
            let perPageItemCount = 4,  // 課程列表一頁顯示4個 (解析度 768~)
                courseListPageIndex = this.initPageIndex(courseList.length, perPageItemCount);

            return {
                // 初始左右箭頭 pageIndex
                courseListPageIndex: courseListPageIndex,
                // 濾出目前要渲染在畫面上的資料
                renderedCourseList: this.filterRenderList(courseList, courseListPageIndex, perPageItemCount)
            }
        },
    }
}
