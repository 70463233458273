import { mapGetters } from 'vuex'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        Dialogue
    },
    data: function() {
        return {
            nextLoginType: null,  // 1: 接下來進行註冊/登入  2: 接下來離開
        }
    },
    computed: {
        ...mapGetters({
            isShowLoginPrompt: 'article/isShowLoginPrompt'
        }),
        nextTargetPath() {
            return this.$store.state.article.nextTargetPath;
        }
    },
    mounted: function() {
        // 關閉註冊/登入提示彈窗後
        $('#loginPromptDialogue').on('hidden.bs.modal', () => {
            if (!this.$_.isNull(this.nextLoginType)) {
                // 接下來進行註冊/登入, 則開啟註冊/登入彈窗
                if (this.nextLoginType === 1) {
                    $('#loginDialogue').modal('show');
                }
                // 接下來不進行註冊/登入, 直接導向下一個頁面
                else if (this.nextLoginType === 2) {
                    if (this.nextTargetPath) {
                        // 初始化使用者收藏單字與筆記
                        this.$store.commit('article/setUserMarkedWords', []);
                        this.$store.commit('article/setArticleNotes', {});
                        // 導向下一個頁面
                        this.$router.push(this.nextTargetPath).catch(() => {});
                    }
                }
            }

            this.nextLoginType = null;
        });
    },
    methods: {
        redirectToLogin(type) {
            this.nextLoginType = type;
            $('#loginPromptDialogue').modal('hide');
        }
    }
}
