// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const zhTW = resolve => require(["@/components/agreement/zh-TW.vue"], resolve)

export default {
    components: {
        Dialogue,
        zhTW
    },
    computed: {
        componentType() {
            switch (this.$store.state.common.lang) {
                case 'zh-TW':
                    return zhTW;
                default:
                    return zhTW;
            }
        }
    },
    mounted: function() {
        // 開啟購買暨服務契約彈窗後
        $('#agreementDialogue').on('shown.bs.modal', () => {
            $('.agreement-content').scrollTop(0);
        });
    }
}
