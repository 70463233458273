import LayoutDefault from "@/layouts/LayoutDefault.vue"
import LayoutFull from "@/layouts/LayoutFull.vue"

export default {
    name: 'Layout',
    props: {
        type: {
            required: true,
            type: String
        }
    },
    created: function() {
        /**
         * type
         * - Default: Header - Content - Footer
         * - Full: Content
         */
        switch (this.type) {
            case 'LayoutDefault':
                this.$parent.$emit('update:layout', LayoutDefault);
                break;
            case 'LayoutFull':
                this.$parent.$emit('update:layout', LayoutFull);
                break;
            default:
                this.$parent.$emit('update:layout', LayoutDefault);
                break;
        }
    },
    render: function() {
        return this.$slots.default[0];
    }
}
