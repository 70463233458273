import Layout from "@/layouts"

// components
const LoginEntry = resolve => require(["@/components/account/login_entry.vue"], resolve)
const PwdForm = resolve => require(["@/components/account/pwd_form.vue"], resolve)
const VerifyForm = resolve => require(["@/components/account/verify_form.vue"], resolve)
const AuthError = resolve => require(["@/components/account/auth_error.vue"], resolve)

export default {
    name: 'OAuth',
    components: {
        Layout,
        LoginEntry,
        PwdForm,
        VerifyForm,
        AuthError
    },
    data: function() {
        return {
            isParamsChecked: false,

            accountType: '',
            account: '',
            pwdFormType: '',
            loginFail: false,
            verifyType: '',
            verificationCode: '',
            oidcState: '',

            clientInfo: {},
            appHost: process.env.VUE_APP_HOST,

            isPostingApi: {
                generalLogin: false,  // 登入一般帳號
            }
        }
    },
    computed: {
        layout() {
            return this.$store.state.common.layout;
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutFull') return;

        this.checkParameters();
    },
    methods: {
        checkParameters() {
            const routeState = this.$route.params.state;
            const routeQuery = this.$route.query;

            // 若從後端直接導到錯誤畫面, 則不需進入檢查參數流程
            if (routeState === 'error') {
                this.isParamsChecked = true;
                return;
            }

            // AD
            if (~['login', 'verifyaccount'].indexOf(routeState)) {
                // 沒有帳號資訊, 導向入口登入頁面
                if (!routeQuery.AD) {
                    this.isParamsChecked = true;
                    this.redirectAuthPage();
                    return;
                } else {
                    const decoded = atob(routeQuery.AD);
                    let split = decoded.split('-');
                    this.accountType = split[0] || '';
                    this.account = split[1] || '';
                    this.verifyType = split[2] || '';
                    this.pwdFormType = split[2] || '';

                    // 帳號資訊錯誤, 導向入口登入頁面
                    if (!this.accountType || !this.account || !~['phone', 'email'].indexOf(this.accountType)) {
                        this.isParamsChecked = true;
                        this.redirectAuthPage();
                        return;
                    }
                    // 密碼輸入表單類型錯誤, 導向入口登入頁面
                    if (routeState === 'login' && (!this.pwdFormType || !~['login', 'register', 'resetPWD'].indexOf(this.pwdFormType))) {
                        this.isParamsChecked = true;
                        this.redirectAuthPage();
                        return;
                    }
                    // 驗證類型錯誤, 導向入口登入頁面
                    if (routeState === 'verifyaccount' && (!this.verifyType || !~['auth', 'resetPWD'].indexOf(this.verifyType))) {
                        this.isParamsChecked = true;
                        this.redirectAuthPage();
                        return;
                    }
                }
            }

            let params = {
                response_type: routeQuery.response_type,
                client_id: routeQuery.client_id,
                redirect_uri: routeQuery.redirect_uri,
                scope: routeQuery.scope
            };

            this.$httpRequest.get('/api/user/oauth', params, false)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.clientInfo = {
                                logo: result.url,
                                name: result.name,
                            };

                            let stateData = {
                                client_id: routeQuery.client_id,
                                redirect_uri: routeQuery.redirect_uri,
                                state: routeQuery.state,
                                scope: routeQuery.scope
                            };
                            this.oidcState = `oauth|${btoa(JSON.stringify(stateData))}`;
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.redirectErrorPage(response.data.msg);
                    }

                    this.isParamsChecked = true;
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },

        enterGeneralEntry(accType, acc) {
            this.accountType = accType;
            this.account = acc;
            this.redirectLoginPage();
        },

        generalLogin(pwd) {
            this.loginFail = false;

            const routeQuery = this.$route.query;

            let params = {
                type: this.accountType,
                account: this.account,
                pwdUUID: pwd,
                client_id: routeQuery.client_id,
                redirect_uri: routeQuery.redirect_uri,
                scope: routeQuery.scope
            };

            this.isPostingApi.generalLogin = true;

            this.$httpRequest.post('/api/user/oauth_general_login', params, false)
                .then(response => {
                    this.isPostingApi.generalLogin = false;

                    if (response.data.state == 'OK') {
                        let code = response.data.code;

                        // 登入成功, 導向請求網址
                        let state = routeQuery.state ? `&state=${routeQuery.state}` : '';
                        location.href = `${routeQuery.redirect_uri}?code=${code}${state}`;
                    }
                    if (response.data.state == 'ERROR') {
                        let errorType = response.data.error_type;

                        switch (errorType) {
                            // 密碼錯誤
                            case 'password':
                                this.loginFail = true;
                                break;
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.generalLogin = false;
                    console.error('Catched Error:', error);
                });
        },

        redirectAuthPage() {
            this.$delete(this.$route.query, 'AD');
            this.$router.push({
                name: 'OAuth',
                params: { state: 'auth' },
                query: this.$route.query
            });
        },
        redirectLoginPage(code = '') {
            this.verificationCode = code;

            // 依據條件定義密碼輸入表單的類型
            this.pwdFormType = this.verificationCode ? 'resetPWD' : 'login';

            this.$set(this.$route.query, 'AD', btoa(`${this.accountType}-${this.account}-${this.pwdFormType}`));
            this.$router.push({
                name: 'OAuth',
                params: { state: 'login' },
                query: this.$route.query
            });
        },
        redirectVerifyPage(type) {
            this.verifyType = type;

            this.$set(this.$route.query, 'AD', btoa(`${this.accountType}-${this.account}-${this.verifyType}`));
            this.$router.push({
                name: 'OAuth',
                params: { state: 'verifyaccount' },
                query: this.$route.query
            });
        },
        redirectErrorPage(msg) {
            this.$router.push({
                name: 'OAuth',
                params: { state: 'error' },
                query: { authError: btoa(msg) }
            });
        }
    }
}
