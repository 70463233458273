import Layout from "@/layouts"
import '@/global/markdown/md_preview.css'

// components
const Index = resolve => require(["@/components/course/index.vue"], resolve)
const Purchased = resolve => require(["@/components/course/purchased.vue"], resolve)

export default {
    name: 'Course',
    components: {
        Layout,
        Index,
        Purchased
    },
    data: function() {
        return {
            courseInfo: null,

            isSetDataReady: {
                getCourseInfo: false  // 取得課程資訊
            }
        }
    },
    watch: {
        '$route.params.id'(newValue) {
            // 切換至其他課程
            if (newValue) {
                this.isSetDataReady.getCourseInfo = false;
                $(window).scrollTop(0);
                this.getCourseInfo();
            }
        },
        userInfo(newValue) {
            // 未登入 -> 已登入
            if (newValue) {
                this.isSetDataReady.getCourseInfo = false;
                $(window).scrollTop(0);
                this.getCourseInfo();
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        componentType() {
            if (!this.isSetDataReady.getCourseInfo) {
                return;
            }

            // 已購買課程的使用者, 進入課程內容頁
            if ((this.courseInfo.isPurchased && !this.courseInfo.isExpired) &&
                this.$route.params.page === 'home') {
                return Purchased;
            }

            // 若進入課程內容頁時, 但使用者未購買課程, 則需導向課程首頁
            if (!this.courseInfo.isPurchased && this.$route.params.page === 'home') {
                this.$router.replace(`/course/${this.$route.params.id}`).catch(() => {});
            }

            return Index;
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', 'course');
        this.$store.commit('common/setIsAdminPage', false);

        this.getCourseInfo();
    },
    methods: {
        getCourseInfo() {
            let params = {
                courseAlias: this.$route.params.id
            }

            this.$httpRequest.get('/api/course/get_home', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.courseInfo = {
                                // 課程基本資訊
                                id: result.id,
                                state: result.state,
                                isPreOrder: result.is_pre_order,
                                isNotAllowOrder: this.userInfo.isNotAllowOrder,
                                groupId: result.course_group_id,
                                name: result.name,
                                subName: result.sub_name,
                                cover: result.cover_url,
                                purchaseType: result.purchase_type,  // 0: 訂閱  1: 購買
                                price: result.price,
                                subscribePlans: [],
                                suitable: result.suitable,
                                difficultyLevel: result.difficulty_level,
                                // meta data for SEO
                                metaTitle: result.meta_title,
                                metaDescription: result.meta_description,
                                // 課程簡介、試閱內容、好評口碑
                                introduction: result.introduction_html,
                                publicArticleIds: result.public_article_ids,
                                witness: result.witness_html,
                                // 購買狀況
                                isOrdered: result.is_ordered,  // 是否有下訂單, 但尚未付款成功
                                isPurchased: result.is_purchased,  // 購買課程是否有完成付款
                                isExpired: result.is_expired,  // 課程使用期限是否已到期
                                paymentDeadline: result.payment_deadline,  // 訂單截止時間
                                isInCart: result.is_in_cart,  // 是否已加入購物車
                                // 創造栗
                                externalLink: result.external_link // 創造栗課程連結
                            }
                            // 取得縮圖用於meta data
                            let thumb_url = '';
                            if (result.cover_url) {
                                let str = result.cover_url;
                                thumb_url = str.substring(0, str.lastIndexOf('.')) + '_thumb' + str.substr(str.lastIndexOf('.') - str.length);
                            }
                            this.$util.updateMeta(result.meta_title, result.meta_description, thumb_url);
                            // 訂閱方案
                            for (let el of result.subscribe_plan) {
                                let tempTotalPrice = +el.unit * +el.price;

                                this.courseInfo.subscribePlans.push({
                                    id: el.subscribe_price_id,
                                    name: el.name.substring(0, 4),  // 取前4個字, 以防跑版
                                    unit: el.unit,
                                    price: el.price,
                                    totalPrice: tempTotalPrice,
                                    discountPrice: (+el.unit * +this.courseInfo.price) - tempTotalPrice
                                });
                            }

                            this.isSetDataReady.getCourseInfo = true;
                        }
                    }
                    // 後端回傳錯誤訊息時, 則導回首頁
                    if (response.data.state == 'ERROR') {
                        this.$router.push('/').catch(() => {});
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
