import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// utils
import announcementUtil from '@/assets/js/utils/announcement'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const CourseTableList = resolve => require(["@/components/admin/push_notification/common/_course_table_list.vue"], resolve)
const InstructionTableList = resolve => require(["@/components/admin/push_notification/common/_instruction_table_list.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        CourseTableList,
        InstructionTableList
    },
    data: function() {
        return {
            announcementInfo: {},
            oldAnnouncementInfo: {},
            updatingInfo: {},  // 編輯公告有更動的項目

            inputSearchCourseText: '',  // 搜尋輸入的課程 id 或 名稱
            inputSearchCouponCode: '',  // 搜尋輸入的優惠券代碼
            inputSearchInstructionText: '',  // 搜尋輸入的說明 id 或 名稱
            oldInputSearchText: '',

            allCourseIds: [],
            allInstructionIds: [],

            uploadErrorMsg: {
                contentImage: '',
            },

            renderTableListKey: 0,

            isSettingImagePreviewer: false,  // 是否正在設置公告內容圖片預覽器
            isGettingCoupon: false,  // 是否正在透過優惠碼取得優惠券資訊
            isSetDataReady: {
                getAnnouncementInfo: false,  // 取得公告資訊
            },
            isPostingApi: {
                saveAnnouncement: false,  // 新增/編輯公告資訊
            }
        }
    },
    watch: {
        editingAnnouncementId(newValue) {
            if (!this.$_.isNull(newValue)) {
                this.getAnnouncementInfo();
            }
        },
        linkedResourceInfo(newValue) {
            if (!this.$_.isEmpty(newValue)) {
                this.announcementInfo.linkedResource = newValue;
                this.linkedResourceInfo = {};
            }
        }
    },
    computed: {
        editingAnnouncementId: {
            get() {
                return this.$store.state.adminPnAnnouncement.editingAnnouncementId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setEditingAnnouncementId', value);
            }
        },
        linkedResourceInfo: {
            get() {
                return this.$store.state.adminPnAnnouncement.linkedResourceInfo;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setLinkedResourceInfo', value);
            }
        },
        newPublishedItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.newPublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setNewPublishedItem', value);
            }
        },
        newScheduledItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.newScheduledItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setNewScheduledItem', value);
            }
        },
        editedScheduledItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.editedScheduledItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setEditedScheduledItem', value);
            }
        },
        deletedScheduledId: {
            get() {
                return this.$store.state.adminPnAnnouncement.deletedScheduledId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setDeletedScheduledId', value);
            }
        },
        isUpdatingAnnouncement() {  // 是否為編輯公告
            return !this.$_.isNull(this.editingAnnouncementId) && this.editingAnnouncementId !== '0';
        },
        contentTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '文字'
                },
                {
                    value: '1',
                    text: '圖片'
                }
            ]
            return opts;
        },
        triggeredTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '無'
                },
                {
                    value: '1',
                    text: '課程'
                },
                {
                    value: '2',
                    text: '優惠'
                },
                // {
                //     value: '3',
                //     text: '說明'
                // }
            ]
            return opts;
        },
        triggeredTypeIconName() {
            return this.announcementInfo.triggeredType ? announcementUtil.getIconNameByTriggeredType(this.announcementInfo.triggeredType) : '';
        },
        itemValueMaxLength() {
            return key => {
                // 標題(限10字)
                if (key === 'title') {
                    return 10;
                }
                // 內容文字(限100字)
                if (key === 'contentText') {
                    return 100;
                }
                // 觸發按鈕文字(限4字)
                if (key === 'triggeredBtnText') {
                    return 4;
                }
            }
        },
        isItemValueInputLengthValid() {
            return (key, value) => !value || value.length <= this.itemValueMaxLength(key);  // 排除 value 為空的情況
        },
        isDisableSaveAnnouncement() {
            // 編輯公告, 檢查是否有更動項目
            if (this.isUpdatingAnnouncement && this.isSetDataReady.getAnnouncementInfo) {
                let excludeKeys = ['contentText', 'contentImage', 'linkedResource'];

                // 適用檢查物件第一層 keys 差異
                this.updatingInfo = this.$_.omitBy(this.$_.omit(this.announcementInfo, excludeKeys), (v, k) => {
                    return this.oldAnnouncementInfo[k] == v;
                });

                // 內容文字
                if (this.announcementInfo.contentType === '0' &&
                    (this.announcementInfo.contentText !== this.oldAnnouncementInfo.contentText)) {
                    this.updatingInfo.contentText = this.announcementInfo.contentText;
                }

                // 內容圖片
                if (this.announcementInfo.contentImage.url !== this.oldAnnouncementInfo.contentImage.url) {
                    this.updatingInfo.contentImage = {
                        file: this.announcementInfo.contentImage.file
                    }
                }

                // 連結項目(課程, 優惠, 說明)
                // 如果有更換觸發功能, 也需帶 resourceId
                if (this.announcementInfo.triggeredType !== '0' &&
                    (this.announcementInfo.triggeredType !== this.oldAnnouncementInfo.triggeredType || this.announcementInfo.linkedResource.id !== this.oldAnnouncementInfo.linkedResource.id)) {
                    this.updatingInfo.linkedResource = {
                        id: this.announcementInfo.linkedResource.id
                    }
                }
            }

            // 所有項目皆必填
            // 標題
            if (!this.announcementInfo.title || /^\s*$/.test(this.announcementInfo.title)) {
                return true;
            }
            // 標題字數是否在限制內
            if (this.itemValueMaxLength('title') &&
                !this.isItemValueInputLengthValid('title', this.announcementInfo.title)) {
                return true;
            }

            // 內容文字
            if (this.announcementInfo.contentType === '0') {
                if (!this.announcementInfo.contentText || /^\s*$/.test(this.announcementInfo.contentText)) {
                    return true;
                }
                // 內容文字字數是否在限制內
                if (this.itemValueMaxLength('contentText') &&
                    !this.isItemValueInputLengthValid('contentText', this.announcementInfo.contentText)) {
                    return true;
                }

                // 觸發按鈕文字
                if (this.announcementInfo.triggeredType !== '0') {
                    if (!this.announcementInfo.triggeredBtnText || /^\s*$/.test(this.announcementInfo.triggeredBtnText)) {
                        return true;
                    }
                    // 按鈕文字字數是否在限制內
                    if (this.itemValueMaxLength('triggeredBtnText') &&
                        !this.isItemValueInputLengthValid('triggeredBtnText', this.announcementInfo.triggeredBtnText)) {
                        return true;
                    }
                }
            }

            // 內容圖片
            if (this.announcementInfo.contentType === '1') {
                if (!this.announcementInfo.contentImage.url) {
                    return true;
                }
            }

            // 連結項目(課程, 優惠, 說明)
            if (this.announcementInfo.triggeredType !== '0' && !this.announcementInfo.linkedResource.id) {
                return true;
            }

            // 起始時間 & 結束時間
            if (!this.announcementInfo.publishStart || !this.announcementInfo.publishEnd) {
                return true;
            }

            // 編輯公告時如果沒有更新項目, 則 disable 儲存按鈕
            if (this.isUpdatingAnnouncement) {
                return this.$_.isEmpty(this.updatingInfo);
            }

            return false;
        }
    },
    mounted: function() {
        // 開啟新增/編輯公告彈窗後
        $('#editAnnouncementDialogue').on('shown.bs.modal', () => {
            if (!this.isUpdatingAnnouncement) {
                $('#announcementTitleInput').focus();
            }
            this.resetSettings();
        });

        // 關閉新增/編輯公告彈窗後
        $('#editAnnouncementDialogue').on('hidden.bs.modal', () => {
            this.editingAnnouncementId = null;
            this.announcementInfo = {};
            this.oldAnnouncementInfo = {};
            this.uploadErrorMsg = this.$_.mapValues(this.uploadErrorMsg, () => '');
            this.isSetDataReady.getAnnouncementInfo = false;
        });
    },
    methods: {
        resetSettings() {
            // 初始搜尋輸入框
            this.inputSearchCourseText = '';
            this.inputSearchCouponCode = '';
            this.inputSearchInstructionText = '';
            this.oldInputSearchText = '';

            this.allCourseIds = [];
            this.allInstructionIds = [];
        },

        setDefaultInfo() {
            this.announcementInfo = {
                title: '',
                contentType: '0',  // 0: 文字 ; 1: 圖片
                contentText: '',  // 公告內容文字
                contentImage: {  // 公告內容圖片
                    name: null,
                    url: null,
                    file: null
                },
                triggeredType: '0',  // 0: 無 ; 1: 課程 ; 2: 優惠 ; 3: 說明
                linkedResource: {  // 連結項目(課程, 優惠, 說明)
                    id: null,
                    name: null
                },
                triggeredBtnText: '',  // 觸發按鈕文字
                publishStart: this.$util.unixTimestampToDatetime(Math.floor(Date.now() / 1000), this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                // 2592000000 ms --> 1 month
                publishEnd: this.$util.unixTimestampToDatetime(Math.floor((Date.now() + 2592000000) / 1000), this.$util.getBrowserCurrentTimeZone(), 'datetime', true)
            }
        },
        getAnnouncementInfo() {
            // 新增公告, 初始公告資訊內容
            if (this.editingAnnouncementId === '0') {
                this.setDefaultInfo();
                this.isSetDataReady.getAnnouncementInfo = true;
                return;
            }

            let params = {
                announcementId: this.editingAnnouncementId
            }

            this.$httpRequest.get('/admin_api/announcement/get_announcement_info', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.announcementInfo = {
                                title: result.title,
                                contentType: null,
                                contentText: '',
                                contentImage: {
                                    name: null,
                                    url: null,
                                    file: null
                                },
                                triggeredType: result.type,
                                linkedResource: {},
                                triggeredBtnText: result.button_text,
                                publishStart: this.$util.unixTimestampToDatetime(result.start_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true),
                                publishEnd: this.$util.unixTimestampToDatetime(result.end_date, this.$util.getBrowserCurrentTimeZone(), 'datetime', true)
                            }

                            // 公告類型(文字, 圖片)
                            // 內容圖片
                            if (this.$_.has(result, 'file') && !this.$_.isEmpty(result.file)) {
                                this.announcementInfo.contentType = '1';
                                this.announcementInfo.contentImage.name = result.file.original_name;
                                this.announcementInfo.contentImage.url = result.file.file_name;
                            }
                            // 內容文字
                            else if (this.$_.has(result, 'content') && result.content) {
                                this.announcementInfo.contentType = '0';
                                this.announcementInfo.contentText = result.content;
                            }

                            // 觸發功能類型非 '無' 時, 會有連結項目
                            if (this.announcementInfo.triggeredType !== '0' && !this.$_.isEmpty(result.resource)) {
                                this.announcementInfo.linkedResource.id = result.resource.resource_id;
                                this.announcementInfo.linkedResource.name = result.resource.name;
                            }

                            // 暫存原本的公告資訊, 以用來判斷是否 enable 儲存按鈕
                            this.oldAnnouncementInfo = this.$_.cloneDeep(this.announcementInfo);

                            this.isSetDataReady.getAnnouncementInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },

        uploadContentImage() {
            this.uploadErrorMsg.contentImage = '';

            let tempUploadFile = this.$refs.uploadContentImage.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['png', 'jpg', 'jpeg'];
            let perFileLimitSize = 5242880;  // 5mb

            this.uploadErrorMsg.contentImage = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.contentImage && tempUploadFile.type.match(/^image\//)) {
                this.announcementInfo.contentImage.name = tempUploadFile.name;
                this.announcementInfo.contentImage.file = tempUploadFile;

                this.isSettingImagePreviewer = true;

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = () => {
                    let img = document.createElement("img");
                    img.onload = () => {
                        let width = 960;
                        let height = 1060;

                        let canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;

                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        let dataurl = canvas.toDataURL(tempUploadFile.type);
                        this.announcementInfo.contentImage.url = dataurl;

                        this.isSettingImagePreviewer = false;
                    }
                    img.src = reader.result;  // e.target.result
                }
            }

            this.$refs.uploadContentImage.value = null;
        },
        changeContentType() {
            if (this.announcementInfo.contentType === '0') {
                this.$nextTick(() => $('#contentTextInput').focus());
                this.setDefaultBtnText();
            }

            // 若切換類型前上傳檔案錯誤, 則清空檔案與錯誤提示文字
            if (this.uploadErrorMsg.contentImage) {
                this.announcementInfo.contentImage = this.$_.mapValues(this.announcementInfo.contentImage, () => null);
                this.uploadErrorMsg.contentImage = '';
            }
        },
        changeTriggeredType() {
            this.deleteLinkedResource();
            this.setDefaultBtnText();
        },
        deleteLinkedResource() {
            this.announcementInfo.linkedResource = this.$_.mapValues(this.announcementInfo.linkedResource, () => null);
            this.resetSettings();
            this.focusInput();
        },
        focusInput() {
            let inputDOM = '';  // 搜尋輸入框 DOM
            switch (this.announcementInfo.triggeredType) {
                case '1':  // 課程
                    inputDOM = '#searchCourseTextInput';
                    break;
                case '2':  // 優惠
                    inputDOM = '#searchCouponCodeInput';
                    break;
                case '3':  // 說明
                    inputDOM = '#searchInstructionTextInput';
                    break;
            }
            this.$nextTick(() => $(inputDOM).focus());
        },
        setDefaultBtnText() {
            let btnText = '';  // 觸發按鈕文字
            switch (this.announcementInfo.triggeredType) {
                case '1':  // 課程
                    btnText = '前往課程';
                    break;
                case '2':  // 優惠
                    btnText = '立即領取';
                    break;
                case '3':  // 說明
                    btnText = '查看更多';
                    break;
            }
            this.announcementInfo.triggeredBtnText = btnText;
        },
        getCourseIdsBySearchText() {
            if (!this.inputSearchCourseText || /^\s*$/.test(this.inputSearchCourseText)) {
                return;
            }
            if (this.inputSearchCourseText === this.oldInputSearchText) {
                return;
            }

            this.oldInputSearchText = this.inputSearchCourseText;

            let params = {
                state: '-1',
                key: this.inputSearchCourseText
            }

            this.allCourseIds = [];

            this.$httpRequest.get('/admin_api/course/get_course_ids_by_search', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCourseIds = result;

                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getCouponBySearchCode() {
            if (!this.inputSearchCouponCode || /^\s*$/.test(this.inputSearchCouponCode)) {
                return;
            }

            let params = {
                code: this.inputSearchCouponCode
            }

            this.isGettingCoupon = true;

            this.$httpRequest.get('/admin_api/coupon/get_coupon_id_name_by_code', params)
                .then(response => {
                    this.isGettingCoupon = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.announcementInfo.linkedResource.id = result.id;
                            this.announcementInfo.linkedResource.name = result.name;
                        }
                    }
                })
                .catch(error => {
                    this.isGettingCoupon = false;
                    console.log('catched error: ' + error);
                });
        },
        getInstructionIdsBySearchText() {
            if (!this.inputSearchInstructionText || /^\s*$/.test(this.inputSearchInstructionText)) {
                return;
            }
            if (this.inputSearchInstructionText === this.oldInputSearchText) {
                return;
            }

            this.oldInputSearchText = this.inputSearchInstructionText;

            let params = {
                state: '-1',
                key: this.inputSearchInstructionText
            }

            this.allInstructionIds = [];

            console.log('admin/push_notification/announcement/_edit_announcement_dialogue.js - getInstructionIdsBySearchText', params);

            // fake data
            let result = [];
            for (let i = 0; i < 20; i++) {
                result.push(`${i+1}`);
            }

            this.allInstructionIds = result;

            this.renderTableListKey++;

            // !!! api
            // this.$httpRequest.get('', params)
            //     .then(response => {
            //         if (response.data.state == 'OK') {
            //             let result = response.data.result;

            //             if (result) {
            //                 this.allInstructionIds = result;

            //                 this.renderTableListKey++;
            //             }
            //         }
            //     })
            //     .catch(error => {
            //         console.log('catched error: ' + error);
            //     });
        },
        disableStartDate(date) {
            // disable小於今天以前的日期與大於有設定的結束時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.announcementInfo.publishEnd && date.setHours(0, 0, 0, 0) > new Date(this.announcementInfo.publishEnd).setHours(0, 0, 0, 0));
        },
        disableStartTime(date) {
            // disable小於目前時間(時)以前的時間與大於有設定的結束時間(時)
            return date <= new Date() ||
                    (this.announcementInfo.publishEnd && date >= new Date(this.announcementInfo.publishEnd));
        },
        disableEndDate(date) {
            // disable小於有設定的開始時間
            return this.announcementInfo.publishStart && date.setHours(0, 0, 0, 0) < new Date(this.announcementInfo.publishStart).setHours(0, 0, 0, 0);
        },
        disableEndTime(date) {
            // disable小於有設定的開始時間(時)
            return this.announcementInfo.publishStart && date <= new Date(this.announcementInfo.publishStart);
        },

        saveAnnouncement() {
            let params = new FormData();

            let infoData = {};
            let apiUrl = '';

            // 新增公告
            if (!this.isUpdatingAnnouncement) {
                infoData = {
                    title: this.announcementInfo.title,
                    type: this.announcementInfo.triggeredType,
                    startDate: this.$util.datetimeToUnixTimestamp(this.announcementInfo.publishStart),
                    endDate: this.$util.datetimeToUnixTimestamp(this.announcementInfo.publishEnd)
                }

                // 內容文字
                if (this.announcementInfo.contentType === '0' &&
                    this.announcementInfo.contentText) {
                    infoData.content = this.announcementInfo.contentText;
                }

                // 觸發功能非 '無' 類型, 需帶連結項目 id
                if (infoData.type !== '0') {
                    infoData.resourceId = this.announcementInfo.linkedResource.id;
                    infoData.buttonText = this.announcementInfo.triggeredBtnText;
                }

                apiUrl = '/admin_api/announcement/create_announcement';
            }
            // 編輯公告
            else {
                infoData = {
                    id: this.editingAnnouncementId,
                    title: this.updatingInfo.title,
                    content: this.updatingInfo.contentText,
                    type: this.updatingInfo.triggeredType,
                    resourceId: !this.$_.isEmpty(this.updatingInfo.linkedResource) ? this.updatingInfo.linkedResource.id : null,
                    buttonText: this.updatingInfo.triggeredBtnText,
                    startDate: this.updatingInfo.publishStart ? this.$util.datetimeToUnixTimestamp(this.updatingInfo.publishStart) : null,
                    endDate: this.updatingInfo.publishEnd ? this.$util.datetimeToUnixTimestamp(this.updatingInfo.publishEnd) : null
                }
                infoData = this.$_.omitBy(infoData, this.$_.isNil);

                apiUrl = '/admin_api/announcement/update_announcement';
            }

            // 內容圖片檔案
            if (this.announcementInfo.contentType === '1' &&
                this.announcementInfo.contentImage.file) {
                params.append('image', this.announcementInfo.contentImage.file);
            }

            params.append('announcementInfo', JSON.stringify(infoData));

            this.isPostingApi.saveAnnouncement = true;

            this.$httpRequest.post(apiUrl, params)
                .then(response => {
                    this.isPostingApi.saveAnnouncement = false;

                    if (response.data.state == 'OK') {
                        let savedItem = {
                            id: this.isUpdatingAnnouncement ? this.editingAnnouncementId : response.data.result,
                            title: this.announcementInfo.title,
                            publishStart: this.$util.datetimeToUnixTimestamp(this.announcementInfo.publishStart),
                            publishEnd: this.$util.datetimeToUnixTimestamp(this.announcementInfo.publishEnd)
                        }

                        let nowTime = Math.floor(Date.now() / 1000);
                        // 起始時間 > 目前時間, 公告為 '排程' 狀態
                        if (savedItem.publishStart > nowTime) {
                            // 新增狀態下, 需將新增項目加入至排程列表內
                            if (!this.isUpdatingAnnouncement) {
                                this.newScheduledItem = savedItem;
                            }
                            // 編輯狀態下, 需更新排程列表上的內容
                            else {
                                this.editedScheduledItem = savedItem;
                            }
                        }
                        // 起始時間 <= 目前時間, 公告為 '上架' 狀態
                        else {
                            this.newPublishedItem = savedItem;

                            // 編輯狀態下, 公告需從排程列表移動至上架列表內
                            if (this.isUpdatingAnnouncement) {
                                this.deletedScheduledId = savedItem.id;
                            }
                        }

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                        $('#editAnnouncementDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.saveAnnouncement = false;
                    console.log('catched error: ' + error);
                });
        }
    }
}
