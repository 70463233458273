import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue.vue"
export default {
    props: ['allCourseIds'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            inputKeyword: '',
            courseList: [],
            oldCourseList: [],
            isCheckedAll: false,

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 10,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        inputKeyword(newValue) {
            if (!newValue) {
                this.$parent.$parent.getCourseList();
            }
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "check",
                    itemText: "",
                    ownClass: "check",
                    isShowCheckbox: true,
                    isShow: this.isEditGroupName || this.isGroupAssign
                },
                {
                    itemName: "name",
                    itemText: "班級名稱",
                    ownClass: "name",
                    isEdit: !this.isEditGroupName && !this.isGroupAssign,
                    isShow: true
                },
                {
                    itemName: "amount",
                    itemText: "人數",
                    ownClass: "amount",
                    isShow: true
                },
                {
                    itemName: "creater",
                    itemText: "創建教師",
                    ownClass: "creater",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        updateCourseListData() {
            let updateData = {
                courseIds: []
            };

            this.courseList.forEach((el) => {
                if (el.isChecked == true) {
                    updateData.courseIds.push(el.id);
                }
            });

            return updateData;
        }
    },
    created: function() {
        if (this.allCourseIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getCourseList()
                    .then(() => {
                        this.busy = false
                        // 監聽編輯內容是否更動
                        this.$watch('updateCourseListData', newValue => {
                            this.$store.commit('user/setUpdateCourseListData', newValue);
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getCourseList(ids) {
            return new Promise((resolve, reject) => {
                let courseIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempCourseId = ids ? ids[this.loadingIndex] : this.allCourseIds[this.loadingIndex];
                    if (!tempCourseId) {
                        this.isGetAllList = true;
                        break;
                    }
                    courseIds.push(tempCourseId);
                    this.loadingIndex++;
                }

                if (courseIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    courseIds: courseIds
                }

                this.$httpRequest.post('/api/group/get_teacher_course_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    courseIds.forEach(id => {
                                        let tempCourse = result[id];
                                        if (tempCourse) {
                                            this.courseList.push({
                                                id: id,
                                                isChecked: false,
                                                name: tempCourse.name
                                            });
                                        }
                                    });
                                    this.oldCourseList = this.$_.cloneDeep(this.courseList);
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        selectAllCheckbox() {
            this.isCheckedAll = !this.isCheckedAll
            if (this.isCheckedAll == true) {
                this.courseList.forEach(el => {
                    el.isChecked = true;
                })
            }
            else {
                this.courseList.forEach(el => {
                    el.isChecked = false;
                })
            }
        },
        checkSearchState() {
            if (!this.inputKeyword) {
                this.$parent.$parent.getCourseList();
            }
        },
        search() {
            let params = {
                keyword: this.inputKeyword
            }

            this.$httpRequest.post('/api/group/search_teacher_course', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.loadingIndex = 0;
                                this.courseList = [];
                                this.getCourseList(result);
                            }
                        }
                    })
                    .catch(error => console.log('catched error: ' + error));
        },
        clearInputKeyword() {
            this.inputKeyword = '';
            this.$parent.$parent.getCourseList();
        }
    }
}
