import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const Sidebar = resolve => require(["@/components/admin/coupon/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/coupon/list/_table_list.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        Sidebar,
        TableList
    },
    data: function() {
        return {
            // 優惠券類型(0:體驗、1:優訂、2:折扣)
            type: '',
            typeName: '',
            activePage: null,

            allCouponIds: [],
            searchText: '',
            renderTableListKey: 0,

            // 新增優惠券的參數
            name: '',
            organization: 'LearnMode Premium',
            applicableCourse: {
                id: '',
                name: '',
                price: '',
                showCourseName: false
            },
            countType: 0,
            codeType: 0,
            code: '',
            count: '',
            discount: 0,
            discountType: 1, // 預設1是減價，2才是折扣數
            publishStart: '',
            publishEnd: '',
            startDate: '',
            endDate: '',

            isPostingApi: {
                addCoupon: false  // 新增優惠券
            }
        }
    },
    watch: {
        nowPage(newValue) {
            if (newValue) {
                this.setCouponType(); // 換頁要重新設定type，不然getCouponIdsByState() api params.type若沒有改變，就無法取得對應的優惠券資料
                this.getCouponIdsByState();
            }
        },
        isClickSidebarLink(newValue) {
            if (newValue) {
                this.getCouponIdsByState();
            }
            this.isClickSidebarLink = false;
        }
    },
    computed: {
        nowPage() {
            return this.$route.params.page;
        },
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部',
                    state: 'all',
                    url: '/admin/' + this.$route.params.page + '/list/all',
                    isShow: true
                },
                {
                    name: '排程',
                    state: 'schedule',
                    url: '/admin/' + this.$route.params.page + '/list/schedule',
                    isShow: true
                },
                {
                    name: '進行',
                    state: 'on',
                    url: '/admin/' + this.$route.params.page + '/list/on',
                    isShow: true
                },
                {
                    name: '結束',
                    state: 'off',
                    url: '/admin/' + this.$route.params.page + '/list/off',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminBuyingBundles.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminBuyingBundles/setIsClickSidebarLink', value);
            }
        },
        countTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '不限'
                },
                {
                    value: '1',
                    text: '限量'
                }
            ]
            return opts;
        },
        codeTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '同碼'
                },
                {
                    value: '1',
                    text: '序號'
                }
            ]
            return opts;
        },
        discountTypeList() {
            let opts = [];
            opts = [
                {
                    value: '1',
                    text: '折價'
                },
                {
                    value: '2',
                    text: '折扣'
                }
            ]
            return opts;
        },
        isAddCouponDisable() { // 檢查優惠券新增的參數是否有沒填的

            if (!this.name
                || !this.organization
                || (this.type != 3 && !this.applicableCourse.showCourseName)
                || (!this.code && this.codeType != 1)
                || (this.countType == 1 && (!this.count || this.count == 0)) // 不能輸入0
                || !this.discount || this.discount == 0 // 不能輸入0
                || !this.publishStart
                || !this.publishEnd) {
                    return true;
                }
            return false;
        }
    },
    created: function() {
        this.setCouponType();
        this.isClickSidebarLink = false;
        this.getCouponIdsByState();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addCouponDialogue').on('shown.bs.modal', () => {  // 新增優惠券
            $('#addCouponInput').focus();
        });
    },
    methods: {
        setCouponType() { // 因為coupon共用一個js，需檢查目前在哪一個優惠券頁面，主要為重新設定參數type(0:體驗、1:優訂、2:折扣、3:全站)，或找出header哪個優惠券項目需要active(亮橘色邊條)
            switch(this.$route.params.page) {
                case 'coupon-experience':
                    this.type = 0;
                    this.typeName = '體驗';
                    this.$store.commit('common/setHeaderChildrenActiveLink', 'adminCouponExperience');
                    break;
                case 'coupon-subscrib':
                    this.type = 1;
                    this.typeName = '優訂';
                    this.$store.commit('common/setHeaderChildrenActiveLink', 'adminCouponSubscrib');
                    break;
                case 'coupon-discount':
                    this.type = 2;
                    this.typeName = '折扣';
                    this.$store.commit('common/setHeaderChildrenActiveLink', 'adminCouponDiscount');
                    break;
                case 'coupon-all':
                    this.type = 3;
                    this.typeName = '全站';
                    this.$store.commit('common/setHeaderChildrenActiveLink', 'adminCouponAll');
                    break;
            }
            this.searchText = '';
        },
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/coupon/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getCouponIdsByState() {
            await this.getActivePage();

            let params = {
                type: this.type,
                state: this.activePage.state
            }

            this.allCouponIds = [];
            this.searchText = '';

            this.$httpRequest.get('/admin_api/coupon/get_coupon_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCouponIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getCouponIdsBySearchText() {
            if (!this.searchText) {
                this.getCouponIdsByState();
                return;
            }

            let params = {
                type: this.type,
                text: this.searchText
            }

            this.allCouponIds = [];

            this.$httpRequest.post('/admin_api/coupon/search_coupon_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCouponIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        checkLen(name) {
            return name.substring(0, 250);
        },
        resetCount() { // 若[發行]、[發行範圍]項目有更改須把count歸零
            this.count = 0;
            if (this.countType == 0) { // 若[發行]選"不限"，[代碼類型]一定要是"同碼"
                this.codeType = 0;
            }
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        checkIsNumEng(text) { // 優惠碼只能英數字
            return text.replace(/[^0-9A-Za-z]/g, "");
        },
        checkDiscountNum(num) {
            num = this.checkIsNumber(num);
            if (this.discountType == 2 && (num > 99 || num < 1)) { // 如果折扣方式選折扣，數值只能介於1-99
                return '';
            }
            if (this.type == 1 && num >= this.applicableCourse.price || this.type == 2 && num > this.applicableCourse.price) { // 如果折扣金額大於等於課程價格，回傳0
                return 0;
            }
            return num;
        },
        checkCourseStatus(id) {
            if (!id) {
                return;
            }

            let params = {
                courseId: id,
                couponType: this.type == 2 ? 1 : 0
            }

            this.$httpRequest.get('/admin_api/coupon/check_course', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.applicableCourse.name = result.name;
                            this.applicableCourse.price = parseInt(result.price);
                            this.applicableCourse.showCourseName = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });

        },
        deleteBindCourse() {
            this.applicableCourse = {
                id: '',
                name: '',
                price: 0,
                showCourseName: false
            };
            if (this.type != 0) {
                this.discount = 0;
            }
        },
        disableStartDate(date) {
            // disable小於今天以前的日期與大於有設定的結束時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.publishEnd && date.setHours(0, 0, 0, 0) > new Date(this.publishEnd).setHours(0, 0, 0, 0));
        },
        disableStartTime(date) {
            // disable小於目前時間(時)以前的時間與大於有設定的結束時間(時)
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.publishEnd && date > new Date(this.publishEnd));
        },
        disableEndDate(date) {
            // disable小於有設定的開始時間
            return this.publishStart && date.setHours(0, 0, 0, 0) < new Date(this.publishStart).setHours(0, 0, 0, 0);
        },
        disableEndTime(date) {
            // disable小於有設定的開始時間(時)
            return this.publishStart && date <= new Date(this.publishStart);
        },
        showAddCouponDialogue() {
            this.name = '';
            this.organization = 'LearnMode Premium';
            this.applicableCourse = {
                    id: '',
                    name: '',
                    price: 0,
                    showCourseName: false
            };
            this.countType = 0;
            this.codeType = 0;
            this.code = '';
            this.count = '';
            this.discount = 0;
            this.discountType = 1;
            this.publishStart = '';
            this.publishEnd = '';
            this.startDate = '';
            this.endDate = '';
            $('#addCouponDialogue').modal('show');
        },
        addCoupon() {
            if (this.isAddCouponDisable || this.isPostingApi.addCoupon) {
                return;
            }

            let params = {
                couponInfo: {
                    couponType: this.type == 2 ? 1 : this.type == 3 ? 2 : 0,
                    name: this.name,
                    organization: this.organization,
                    courseId: this.applicableCourse.id,
                    count: this.countType == 0 ? 0 : this.count,
                    codeType: this.countType == 0 ? 0 : this.codeType,
                    code: this.type == 2 ? 'C-' + this.code : this.type == 3 ? 'P-' + this.code : 'V-' + this.code,
                    discount: this.discount,
                    startDate: this.$util.datetimeToUnixTimestamp(this.publishStart),
                    endDate: this.$util.datetimeToUnixTimestamp(this.publishEnd),
                    discountType: this.type == 0 ? 0 : this.discountType
                }
            }

            this.isPostingApi.addCoupon = true;

            this.$httpRequest.post('/admin_api/coupon/add_coupon', params)
                .then(response => {
                    this.isPostingApi.addCoupon = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addCouponDialogue').modal('hide');
                            this.getCouponIdsByState();
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
