// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const Sidebar = resolve => require(["@/components/admin/group_buying/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/group_buying/list/_table_list.vue"], resolve)

export default {
    components: {
        Dialogue,
        Sidebar,
        TableList
    },
    data: function() {
        return {
            activePage: null,

            allGroupBuyingIds: [],
            searchText: '',
            renderTableListKey: 0,

            // 課程類型
            selectedGroupId: 0,
            courseGroups: [],
            inputStartIssueNo: '',
            inputEndIssueNo: '',

            // 業務清單
            salesRepList: [],

            // 新增團購單pop up的參數
            newCourseItems: [
                {
                    id: '',
                    showName: false,
                    name: '',
                    price: 0
                }
            ],
            // 紙本物件
            paperItem: {
                id: '',
                showName: false,
                name: '',
                price: 0
            },
            // 委託人資訊
            inputBuyerName: '',
            inputBuyerPhone: '',
            inputBuyerTelephone: '',
            inputBuyerEmail: '',
            inputRemark: '',
            selectedSalesRep: '',
            selectedIsPayment: 0,
            inputQuantity: 0,
            inputPaperQuantity: 0,
            inputPaymentType: 'credit',
            // 發票形式
            selectedInvoiceType: 'ezpay',
            // 雲端發票
            inputCarrierNumber: '',
            // 統編發票
            inputUbn: '',
            inputCompanyName: '',
            inputCompanyEmail: '',
            // 愛心捐贈
            selectedLoveCode: '',

            isPostingApi: {
                addGroupBuying: false  // 新增團購
            }
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部',
                    state: 'all',
                    url: '/admin/group-buying/list/all',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminGroupBuying.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminGroupBuying/setIsClickSidebarLink', value);
            }
        },
        isPaymentList() { // 是否需要付款
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '否'
                },
                {
                    value: '1',
                    text: '是'
                }
            ]
            return opts;
        },
        inputPaymentTypeList() {
            let opts = [];
            opts = [
                {
                    value: 'credit',
                    text: '信用卡'
                },
                {
                    value: 'atm',
                    text: 'ATM轉帳'
                },
                {
                    value: 'cvs',
                    text: '超商代碼'
                },
                {
                    value: 'barcode',
                    text: '超商條碼'
                }
            ]
            return opts;
        },
        invoiceTypeList() {
            let types = [];
            types = [
                {
                    value: 'mobile',
                    text: '雲端發票'
                },
                {
                    value: 'ezpay',
                    text: '電子發票'
                },
                {
                    value: 'ubn',
                    text: '統編發票'
                },
                {
                    value: 'lovecode',
                    text: '愛心捐贈'
                }
            ]
            return types;
        },
        loveCodeList() {
            let items = [];
            items = [
                {
                    value: '',
                    text: '請選擇'
                },
                {
                    value: '51811',
                    text: '51811 財團法人第一社會福利基金會'
                },
                {
                    value: '531',
                    text: '531 財團法人董氏基金會'
                },
                {
                    value: '88518',
                    text: '88518 社團法人台北市脊髓損傷者協會'
                },
                {
                    value: '9217',
                    text: '9217 財團法人台北市關懷台灣文教基金會'
                }
            ]
            return items;
        },
        countTotalAmount() {
            if (this.newCourseItems.length < 1 || !this.inputQuantity) {
                return 0;
            }

            let sum = 0,
                price = 0;
            this.newCourseItems.forEach(el => {
                sum+= parseInt(el.price);
            });
            
            price = sum * parseInt(this.inputQuantity); // 算出總價

            if (this.paperItem.id && this.paperItem.id != 0) { // 紙本價格
                let paperPrice = 0;
                paperPrice = this.paperItem.price * this.inputPaperQuantity;
                price += paperPrice;
            }

            return Math.round(price);
        },
        isEmailInputValid() {
            let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;  // email格式
            // 不是email格式則為 false
            return value => !value || regExp.test(value);  // 排除 value 為空的情況
        },
        invoiceNameMaxLength() {
            return key => {
                // 發票形式非統編發票時, 則訂購人姓名為發票名稱(限30字)
                if (this.selectedInvoiceType !== 'ubn' && key === 'inputBuyerName') {
                    return 30;
                }
            }
        },
        isAddGroupBuyingDisable() { // 檢查團購參數是否有沒填的
            if (!this.newCourseItems[0].id ||
                !this.inputBuyerName ||
                (!this.inputBuyerPhone && !this.inputBuyerTelephone) ||
                !this.inputBuyerEmail ||
                !this.isEmailInputValid(this.inputBuyerEmail) ||
                !this.inputQuantity || this.inputQuantity == 0 ||
                (this.paperItem.id != 0 && (!this.inputPaperQuantity || this.inputPaperQuantity == 0)) ||
                (this.selectedIsPayment == 1 &&
                        (this.selectedInvoiceType === 'mobile' && !this.inputCarrierNumber) ||
                        (this.selectedInvoiceType === 'ubn' && (!this.inputUbn || !this.inputCompanyName)) ||
                        (this.selectedInvoiceType === 'lovecode' && !this.selectedLoveCode))) {
                    return true;
                }
            return false;
        },
        countCourseAmount() {
            let total = 0;
            this.newCourseItems.forEach(el => {
                if (el.id != '') {
                    total++;
                }
            });

            return total;
        },
        paymentPageHtml: {
            get() {
                return this.$store.state.order.paymentPageHtml;
            },
            set(value) {
                this.$store.commit('order/setPaymentPageHtml', value);
            }
        },
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getGroupBuyingIdsByState();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addGroupBuyingDialogue').on('shown.bs.modal', () => {  // 新增團購
            $('#addGroupBuyingInput').focus();
        });
    },
    methods: {
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/group_buying/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getGroupBuyingIdsByState() {
            await this.getActivePage();

            this.allGroupBuyingIds = [];
            this.searchText = '';

            this.$httpRequest.get('/admin_api/order/get_group_buying_list_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allGroupBuyingIds = result.order_ids;
                            this.salesRepList = result.sales_rep;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
            this.getCourseGroups();
        },
        getGroupBuyingIdsBySearchText() {
            let params = {
                    keyword: this.searchText,
                    status: this.$route.params.subPage
                }

            if (!this.searchText) {
                this.getGroupBuyingIdsByState();
                return;
            }

            this.allGroupBuyingIds = [];

            this.$httpRequest.get('/admin_api/order/get_group_buying_search_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allGroupBuyingIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        getCourseGroups() {
            this.$httpRequest.get('/admin_api/order/get_course_groups')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.courseGroups = result;
                        }
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        checkPrice(num) { // 檢查輸入的價格是否有空值或非0-9的數字
            if (!num) {
                return 1;
            }
            return num.replace(/[^0-9]/g, 1);
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        checkIsWord(val) { // 檢查是否有英文大寫或數字以外的字元，有的話會直接無法輸入
            return val.replace(/[^0-9A-Z]/g, "");
        },
        checkIssueNoStatus() {
            if (!this.inputStartIssueNo || !this.inputEndIssueNo) {
                return;
            }

            let params = {
                courseGroupId: this.selectedGroupId,
                startIssueNo: this.inputStartIssueNo,
                endIssueNo: this.inputEndIssueNo
            }

            this.$httpRequest.get('/admin_api/course/check_issue_no_state', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result,
                            msg = response.data.msg;

                        if (result) {
                            // 若第一筆資料的id是空的代表還沒有資料，所以必須把陣列清空
                            if (this.newCourseItems.length < 2) {
                                if (this.newCourseItems[0].id == '') {
                                    this.newCourseItems = [];
                                }
                            }
                            else {
                                let itemLen = this.newCourseItems.length-1;
                                for(let i = itemLen; i >= 0; i--) {
                                    // 若陣列有id為空的，會被刪除
                                    if (this.newCourseItems[i].id == '') {
                                        this.newCourseItems.splice(i, 1);
                                    }
                                }
                            }
                            result.forEach(el => {
                                // 檢查資料是否已存在於newCourseItems中，有的話continue
                                let sameData = this.$_.find(this.newCourseItems, ['id', el.id]);
                                if (sameData) {
                                    return;
                                }
                                let item = {
                                    id: el.id,
                                    name: el.name,
                                    showName: true,
                                    price: el.price
                                }
                                this.newCourseItems.push(item);
                            });

                            if (msg) {
                                this.$store.dispatch('common/setAlert', { msg: msg, status: 'danger', duration: 20000 });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        checkCourseStatus(index, id) { // 檢查課程是否可以團購
            if (!id || isNaN(id) || id == 0) {
                return;
            }

            if (!this.$_.isNull(index) && this.newCourseItems.length != 0) {
                let isRepeat = false;
                this.newCourseItems.forEach((item, key) => {
                    if (item.id == id && key != index) {
                        this.$store.dispatch('common/setAlert', { msg: '輸入的課程ID不能與另一個一樣', status: 'danger' });
                        isRepeat = true;
                        return;
                    }
                });
                if (isRepeat) {
                    return;
                }
            }

            let params = {
                courseId: id
            }

            if (this.$_.isNull(index)) { // 如果是紙本課程，必須一定是預購課程
                params.isPaperCourse = 1;
            }

            this.$httpRequest.get('/admin_api/course/check_course_state', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (!this.$_.isNull(index)) {
                                this.newCourseItems[index].name = result.name;
                                this.newCourseItems[index].showName = true;
                                this.newCourseItems[index].price = result.price;
                            }
                            else {
                                this.paperItem.name = result.name;
                                this.paperItem.showName = true;
                                this.paperItem.price = result.price;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
            
        },
        checkDiscount(num) { // 檢查折扣數是否符合規則
            if (num >= 1 || !num) { // 大於1 或空白
                return '';
            }
            if(!num.match(/^0/)) { // 不是0開頭
                return '';
            }
            num = num.replace(/^0{2}[.]/, ""); // 開頭超過1個0
            num = num.replace(/\d{3}$/, ""); //結尾超過三個數字
            return num;
        },
        addNewGroupBuying() { // 新增團購課程input所需參數
            this.newCourseItems.push({
                id: '',
                showName: false,
                name: '',
                price: 0
            });
        },
        deleteGroupBuying(index) { // 刪除團購課程input
            if (isNaN(index) && this.newCourseItems.length == 0) {
                return;
            }

            if (!this.$_.isNull(index)) {
                if (this.newCourseItems.length == 1) {
                    this.newCourseItems[0].id = '';
                    this.newCourseItems[0].name = '';
                    this.newCourseItems[0].showName = false;
                }
                else {
                    this.newCourseItems.forEach((item, key) => {
                        if (index == key) {
                            this.newCourseItems.splice(index, 1);
                        }
                    })
                }
            }
            else {
                this.paperItem = {
                        id: '',
                        showName: false,
                        name: '',
                        price: 0
                    }
            }
        },
        resetInvoiceSettings() {
            // 雲端發票
            this.inputCarrierNumber = '';

            // 統編發票
            this.inputUbn = '';
            this.inputCompanyName = '';
            this.inputCompanyEmail = '';
            this.isCheckSameEmail = false;

            // 愛心捐贈
            this.selectedLoveCode = '';
        },
        showAddGroupBuyingDialogue() {
            // 初始化pop up參數
            // 課程期數相關資訊
            this.selectedGroupId =  0;
            this.inputStartIssueNo = '';
            this.inputEndIssueNo = '';
            this.newCourseItems = [
                {
                    id: '',
                    showName: false,
                    name: '',
                    price: 0
                }
            ],
            this.paperItem = {
                id: '',
                showName: false,
                name: '',
                price: 0
            }
            // 訂購人資訊 (我的專區頁面帶入)
            this.inputBuyerName = '',
            this.inputBuyerPhone = '',
            this.inputBuyerTelephone = '',
            this.inputBuyerEmail = '',
            this.selectedSalesRep = '',
            this.selectedIsPayment = 0,
            this.inputQuantity = 0,
            // 發票形式
            this.selectedInvoiceType = 'ezpay',
            // 雲端發票
            this.inputCarrierNumber = '',
            // 統編發票
            this.inputUbn = '',
            this.inputCompanyName = '',
            this.inputCompanyEmail = '',
            $('#addGroupBuyingDialogue').modal('show');
        },
        addGroupBuying() {
            if (this.isAddGroupBuyingDisable || this.isPostingApi.addGroupBuying) {
                return;
            }

            let params = {
                items: [],
                paperItem: null, 
                purchaseType: '1',
                paymentType: this.inputPaymentType,
                userCouponId: '0',
                totalPrice: this.countTotalAmount,
                device: 'web',
                isGroupBuy: '1',
                isGift: '0',
                isGiftSubscribing: '0',
                exchangeCount: this.inputQuantity,
                buyerName: this.inputBuyerName,
                buyerPhone: this.inputBuyerPhone,
                buyerTelephone: this.inputBuyerTelephone,
                buyerEmail: this.inputBuyerEmail,
                remark: this.inputRemark,
                salesRep: this.selectedSalesRep,
                isPayment: this.selectedIsPayment
            }

            let isPriceNull = false;
            this.newCourseItems.forEach(el => {
                if(el.id) {
                    if (!el.price || el.price == 0) {
                        isPriceNull = true;
                    }
                    params.items.push({
                        courseId: el.id,
                        price: el.price
                    })
                }
            })

            if (this.paperItem.id && this.paperItem.id != 0) {
                    params.paperItem = {
                        courseId: this.paperItem.id,
                        name: this.paperItem.name,
                        count: this.inputPaperQuantity,
                        price: this.paperItem.price
                    }
            }

            if (isPriceNull) { // 價格不能是0
                return;
            }

            if (this.selectedIsPayment == 1) {
                params.invoiceType = this.selectedInvoiceType;
                params.invoiceCarrierNumber = this.inputCarrierNumber ? `/${this.inputCarrierNumber}` : '';
                params.invoiceUbn = this.inputUbn;
                params.invoiceName = this.inputCompanyName;
                params.invoiceEmail = this.inputBuyerEmail;
                params.invoiceLoveCode = this.selectedLoveCode;
            }

            this.isPostingApi.addGroupBuying = true;

            this.$httpRequest.post('/api/order/create_order', params)
                .then(response => {
                    this.isPostingApi.addGroupBuying = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addGroupBuyingDialogue').modal('hide');
                            if (this.selectedIsPayment == 1) {
                                // 導向金流支付頁面
                                this.paymentPageHtml = result.payment_page;
                                this.$router.push('/checkout/payment');
                            }
                            else {
                                this.getGroupBuyingIdsByState();
                                this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                            }
                            
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
