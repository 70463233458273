// components
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        DatePicker,
        Dialogue
    },
    data: function() {
        return {
            selectedCounty : '',
            selectedStartDate : '',
            selectedEndDate : '',
            selectedDownloadType : '',
            isCheckAgreement : false,
            isGettingFile: false,
            startDateLimit : new Date(2023, 0, 1), // 2023-01-01
        }
    },
    watch: {
        '$route.params.subPage'(newValue) {
            // 處理瀏覽器切換上一頁時, activeTab 沒有即時同步的狀況
            if (this.activeTab !== newValue) {
                this.changeTab(newValue);
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        countyList() {
            return this.userInfo.permission != 'admin' ? [ this.userInfo.county ] : [
                '臺北市', '新北市', '基隆市',
                '桃園市', '新竹市', '新竹縣', '苗栗縣',
                '臺中市', '彰化縣', '南投縣',
                '雲林縣', '嘉義市', '嘉義縣', '臺南市',
                '高雄市', '屏東縣',
                '宜蘭縣', '花蓮縣', '臺東縣',
                '澎湖縣', '金門縣', '連江縣'
            ];
        }
    },
    created: function() {
        this.$store.commit('common/setHeaderActiveLink', 'countyReport');

        this.getLastDate();
    },
    methods: {
        // 展開月曆或選定某一日期的時候都會被呼叫來判斷日期是否可用
        checkDisableStartDate(date) {
            return date.getTime() < this.startDateLimit.getTime() ||
                date.getTime() > new Date(this.selectedEndDate).getTime();   // 可以選擇和結束同一天
        },
        checkDisableEndDate(date) {
            return date.getTime() < this.startDateLimit.getTime() ||
                date.getTime() >= new Date().setHours(0, 0, 0, 0);           // 結束日期只能選在今天之前
        },
        getLastDate() {
            let now = new Date();
            now.setDate(now.getDate() - 1);
            let month = (now.getMonth() < 9 ? '0' : '') + (now.getMonth() + 1); // Month: 0-index
            let date = (now.getDate() < 10 ? '0' : '') + now.getDate();         // 補位數並轉為字串

            this.selectedStartDate = this.selectedEndDate = now.getFullYear() + '/' + month + '/' + date;
        },
        copyrightDialogueLoadedHandler() {
            // 關閉彈窗後
            $('#copyrightDialogue').on('hidden.bs.modal', () => {
                this.selectedDownloadType = '';
                this.isCheckAgreement = false;
            });
        },
        showDownloadCopyright(downloadType) {
            this.selectedDownloadType = downloadType;
            $('#copyrightDialogue').modal({backdrop: 'static', keyboard: false});
        },
        hideDownloadCopyright() {
            $('#copyrightDialogue').modal('hide');
        },
        checkAndDownload(type) {
            let diff = new Date(this.selectedEndDate).getTime() - new Date(this.selectedStartDate).getTime();   // 毫秒數
            if (diff < 0) {
                this.$store.dispatch('common/setAlert', { msg: '時間選擇有誤', status: 'danger' });
                return;
            }
            if (diff > 365 * 86400000) {    // 多緩衝一天
                this.$store.dispatch('common/setAlert', { msg: '時間範圍請勿超過一年', status: 'danger' });
                return;
            }
            if (!this.selectedCounty) {
                this.$store.dispatch('common/setAlert', { msg: '未選取縣市', status: 'danger' });
                return;
            }
            if (type != 'school' && type != 'user') {
                this.$store.dispatch('common/setAlert', { msg: '錯誤的下載類型', status: 'danger' });
                return;
            }

            this.showDownloadCopyright(type);
        },
        download() {
            let apiUrl = '';
            switch (this.selectedDownloadType) {
                case 'school':
                    apiUrl = '/api/report/get_county_report_by_school';
                    break;
                case 'user':
                    apiUrl = '/api/report/get_county_report_by_user';
                    break;
                default:
                    return;
            }

            this.isGettingFile = true;
            let params = {
                county : this.selectedCounty,
                startDate : this.selectedStartDate.replaceAll('/', '-'),
                endDate : this.selectedEndDate.replaceAll('/', '-'),
            }

            this.$httpRequest.get(apiUrl, params)
                .then(async response => {
                    if (response.data.state == 'OK') {
                        let url = response.data.result;

                        try {
                            await this.$util.downloadFile(url, url.substring(url.lastIndexOf('/') + 1));
                        }
                        catch (error) {
                            console.error('catched error: ' + error);
                        }
                        this.hideDownloadCopyright();
                    }
                    if (response.data.state == 'ERROR') {
                        this.$store.dispatch('common/setAlert', { msg: '讀取失敗', status: 'danger' });
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                })
                .finally(() => {
                    this.isGettingFile = false;
                });
        },
    }
}
