import Layout from "@/layouts"

// components
const Carousel = resolve => require(["@/components/introduction/_carousel.vue"], resolve)

export default {
    name: 'Introduction',
    components: {
        Layout,
        Carousel
    },
    data: function() {
        return {
            renderCarouselKey: 0
        }
    },
    watch: {
        windowWidth(newValue, oldValue) {
            if ((newValue < 768 && oldValue >= 768) || (newValue >= 768 && oldValue < 768)) {
                this.renderCarouselKey++;
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        appDownload() {
            return this.$store.state.common.appDownload;
        },
        carouselList() {
            let list = [
                {
                    id: '1',
                    image: require('@/assets/image/introduction/banner1.jpg')
                },
                {
                    id: '2',
                    image: require('@/assets/image/introduction/banner2.jpg')
                }
            ]

            // 隨機排序
            let renderedList = [];
            for (let i in list) {
                let rand = Math.floor(Math.random() * list.length);
                let temp = list[i];
                list[i] = list[rand];
                list[rand] = temp;
            }
            renderedList = list;

            return renderedList;
        },
        featureList() {
            let list = [
                {
                    title: "內容品質保證",
                    content: {
                        title: "國語日報社、空中英語教室品質把關",
                        subTitle: "最能放心的好內容"
                    }
                },
                {
                    title: "強大個人化功能",
                    content: {
                        title: "AI語音辨識、個人筆記及單字庫等功能",
                        subTitle: "用自己的方式，走自己的學習節奏"
                    }
                },
                {
                    title: "培養習慣、激發興趣",
                    content: {
                        title: "內容精要，輕量學習無負擔",
                        subTitle: "挑戰任務，收穫成就，改變看得到"
                    }
                },
                {
                    title: "掌握考試關鍵",
                    content: {
                        title: "聽力、閱讀、口說",
                        subTitle: "模擬最新題型，掌握趨勢重點"
                    }
                }
            ]
            return list;
        },
        courseList() {
            let list = [
                {
                    id: '1',
                    name: '10分鐘看世界學英語',
                    subName: '每天一篇英文新聞，搭配解說易讀易懂，適合擁有高中程度的任何人。',
                    purchaseType: '0',  // 0: 訂閱  1: 購買
                    price: {
                        bargain: '299',
                        fixed: '390'
                    }
                },
                {
                    id: '2',
                    name: '會考A++ 英聽攻略',
                    subName: '由外師錄製日常對話，國中生考前必備模擬會考題型。',
                    purchaseType: '1',  // 0: 訂閱  1: 購買
                    price: {
                        bargain: '199',
                        fixed: '290'
                    }
                }
            ]
            return list;
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        this.$util.updateMeta(process.env.VUE_APP_INTRODUCTION_TITLE, process.env.VUE_APP_INTRODUCTION_DESCRIPTION);
    },
    methods: {
        scrollToPublicArticleTab() {
            // 寫入新 cookie, 跳轉至未購買課程首頁時可滑動至 '試閱文章' tab
            this.$cookieStore.setCookie('is_scroll_to_pa', '1', 5*60);
        }
    }
}
