import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const PublishedList = resolve => require(["@/components/admin/push_notification/banner/_published_list.vue"], resolve)
const ScheduledList = resolve => require(["@/components/admin/push_notification/banner/_scheduled_list.vue"], resolve)
const UnpublishedList = resolve => require(["@/components/admin/push_notification/banner/_unpublished_list.vue"], resolve)
const EditBannerDialogue = resolve => require(["@/components/admin/push_notification/banner/_edit_banner_dialogue.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        PublishedList,
        ScheduledList,
        UnpublishedList,
        EditBannerDialogue
    },
    data: function() {
        return {
            allBannerIds: {},

            publishingItem: {},  // 準備上架的橫幅
            unpublishingItem: {},  // 準備下架的橫幅
            deletingItem: {},  // 準備刪除的橫幅
            republishingItem: {},  // 準備重新上架的橫幅
            deletedFromState: '',  // 從哪個狀態列表刪除橫幅 (scheduled: 排程 ; unpublished: 下架)

            isSetDataReady: {
                getAllBannerIds: false,  // 取得各狀態橫幅 ids
            },
            isPostingApi: {
                handleBanner: false,  // 上架/下架/刪除橫幅
                republishBanner: false,  // 重新上架橫幅
                deleteAllBanners: false,  // 刪除所有下架橫幅
            }
        }
    },
    computed: {
        newPublishedItem: {
            get() {
                return this.$store.state.adminPnBanner.newPublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setNewPublishedItem', value);
            }
        },
        newUnpublishedItem: {
            get() {
                return this.$store.state.adminPnBanner.newUnpublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setNewUnpublishedItem', value);
            }
        },
        deletedPublishedId: {
            get() {
                return this.$store.state.adminPnBanner.deletedPublishedId;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setDeletedPublishedId', value);
            }
        },
        deletedScheduledId: {
            get() {
                return this.$store.state.adminPnBanner.deletedScheduledId;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setDeletedScheduledId', value);
            }
        },
        deletedUnpublishedId: {
            get() {
                return this.$store.state.adminPnBanner.deletedUnpublishedId;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setDeletedUnpublishedId', value);
            }
        },
        isDeleteAllBanners: {
            get() {
                return this.$store.state.adminPnBanner.isDeleteAllBanners;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setIsDeleteAllBanners', value);
            }
        },
        editingBannerInfo: {
            get() {
                return this.$store.state.adminPnBanner.editingBannerInfo;
            },
            set(value) {
                this.$store.commit('adminPnBanner/setEditingBannerInfo', value);
            }
        },
        isHasDeadlineList() {
            let opts = [];
            opts = [
                {
                    value: true,
                    text: '限期'
                },
                {
                    value: false,
                    text: '不限期'
                }
            ]
            return opts;
        }
    },
    created: function() {
        this.initial();
        this.getAllBannerIds();
    },
    methods: {
        // 初始橫幅所有設定值
        initial() {
            // 列表相關變數
            this.$store.commit('adminPnBanner/setNewPublishedItem', {});
            this.$store.commit('adminPnBanner/setNewScheduledItem', {});
            this.$store.commit('adminPnBanner/setNewUnpublishedItem', {});
            this.$store.commit('adminPnBanner/setEditedPublishedItem', {});
            this.$store.commit('adminPnBanner/setEditedScheduledItem', {});
            this.$store.commit('adminPnBanner/setDeletedPublishedId', null);
            this.$store.commit('adminPnBanner/setDeletedScheduledId', null);
            this.$store.commit('adminPnBanner/setDeletedUnpublishedId', null);
            this.$store.commit('adminPnBanner/setIsDeleteAllBanners', false);

            // 新增或編輯橫幅相關變數
            this.$store.commit('adminPnBanner/setEditingBannerInfo', { index: null, id: null, state: null });
            this.$store.commit('adminPnBanner/setLinkedResourceInfo', {});
        },

        // 取得所有狀態的橫幅資訊
        getAllBannerIds() {
            this.$httpRequest.get('/admin_api/banner/get_banner_list_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allBannerIds = result;

                            this.isSetDataReady.getAllBannerIds = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getBannerInfoList(bannerIds) {
            return new Promise((resolve, reject) => {
                let params = {
                    bannerIds: bannerIds
                }

                this.$httpRequest.post('/admin_api/banner/get_banner_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result && !this.$_.isEmpty(result)) {
                                let bannerList = [];

                                bannerIds.forEach(id => {
                                    let tempBanner = result[id];
                                    if (tempBanner) {
                                        bannerList.push({
                                            id: id,
                                            name: tempBanner.name,
                                            image: tempBanner.image.file_url,
                                            publishStart: tempBanner.start_date,
                                            publishEnd: tempBanner.end_date
                                        });
                                    }
                                });

                                resolve(bannerList);
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        // 新增/編輯橫幅
        editBanner(index, bannerId, state) {
            this.editingBannerInfo = { index: index, id: bannerId, state: state };
            $('#editBannerDialogue').modal('show');
        },

        // 上架/下架/刪除橫幅
        openPublishPromptDialogue(item) {
            this.publishingItem = item;
            $('#publishBannerPromptDialogue').modal('show');
        },
        openUnpublishPromptDialogue(item) {
            this.unpublishingItem = item;
            $('#unpublishBannerPromptDialogue').modal('show');
        },
        openDeletePromptDialogue(item, state) {
            this.deletingItem = item;
            this.deletedFromState = state;
            $('#deleteBannerPromptDialogue').modal('show');
        },
        handleBanner(item, event) {
            let params = {
                bannerId: item.id,
                event: event
            }

            this.isPostingApi.handleBanner = true;

            this.$httpRequest.post('/admin_api/banner/handle_banner', params)
                .then(response => {
                    this.isPostingApi.handleBanner = false;

                    if (response.data.state == 'OK') {
                        switch (event) {
                            // 上架
                            case 'publish': {
                                item.publishStart = Math.floor(Date.now() / 1000);  // 起始時間改為目前時間

                                // 新增指定上架橫幅
                                this.allBannerIds.published.unshift(item.id);
                                this.newPublishedItem = item;

                                // 刪除指定排程橫幅
                                let foundIndex = this.allBannerIds.scheduled.indexOf(item.id);
                                this.$delete(this.allBannerIds.scheduled, foundIndex);
                                this.deletedScheduledId = item.id;

                                $('#publishBannerPromptDialogue').modal('hide');
                                break;
                            }
                            // 下架
                            case 'unpublish': {
                                item.publishEnd = Math.floor(Date.now() / 1000);  // 結束時間改為目前時間

                                // 新增指定下架橫幅
                                this.allBannerIds.unpublished.unshift(item.id);
                                this.newUnpublishedItem = item;

                                // 刪除指定上架橫幅
                                let foundIndex = this.allBannerIds.published.indexOf(item.id);
                                this.$delete(this.allBannerIds.published, foundIndex);
                                this.deletedPublishedId = item.id;

                                $('#unpublishBannerPromptDialogue').modal('hide');
                                break;
                            }
                            // 刪除
                            case 'delete': {
                                if (this.deletedFromState === 'scheduled') {
                                    // 刪除指定排程橫幅
                                    let foundIndex = this.allBannerIds.scheduled.indexOf(item.id);
                                    this.$delete(this.allBannerIds.scheduled, foundIndex);
                                    this.deletedScheduledId = item.id;
                                }
                                if (this.deletedFromState === 'unpublished') {
                                    // 刪除指定下架橫幅
                                    let foundIndex = this.allBannerIds.unpublished.indexOf(item.id);
                                    this.$delete(this.allBannerIds.unpublished, foundIndex);
                                    this.deletedUnpublishedId = item.id;
                                }
                                $('#deleteBannerPromptDialogue').modal('hide');
                                break;
                            }
                        }

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });
                    }
                })
                .catch(error => {
                    this.isPostingApi.handleBanner = false;
                    console.error('Catched Error:', error);
                });
        },

        // 重新上架橫幅
        openRepublishPromptDialogue(item) {
            this.republishingItem = item;
            // 重置結束時間
            this.$set(this.republishingItem, 'isHasDeadline', true);
            this.$set(this.republishingItem, 'publishEnd', null);
            $('#republishBannerPromptDialogue').modal('show');
        },
        disableEndDate(date) {
            // disable小於今天以前的日期與小於有設定的開始時間
            return date < new Date().setHours(0, 0, 0, 0) ||
                    (this.republishingItem.publishStart && date.setHours(0, 0, 0, 0) < new Date(this.republishingItem.publishStart).setHours(0, 0, 0, 0));
        },
        disableEndTime(date) {
            // disable小於目前時間(時)以前的時間與小於有設定的開始時間(時)
            return date <= new Date() ||
                    (this.republishingItem.publishStart && date <= new Date(this.republishingItem.publishStart));
        },
        republishBanner() {
            let params = new FormData();

            let infoData = {
                id: this.republishingItem.id,
                endDate: this.republishingItem.publishEnd ? this.$util.datetimeToUnixTimestamp(this.republishingItem.publishEnd) : null
            }

            params.append('updateInfo', JSON.stringify(infoData));

            this.isPostingApi.republishBanner = true;

            this.$httpRequest.post('/admin_api/banner/update_banner', params)
                .then(response => {
                    this.isPostingApi.republishBanner = false;

                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                        // 新增指定上架橫幅
                        this.allBannerIds.published.unshift(this.republishingItem.id);
                        this.newPublishedItem = this.republishingItem;
                        this.$set(this.newPublishedItem, 'publishStart', Math.floor(Date.now() / 1000));  // 起始時間改為目前時間
                        this.$set(this.newPublishedItem, 'publishEnd', infoData.endDate);

                        // 刪除指定下架橫幅
                        let foundIndex = this.allBannerIds.unpublished.indexOf(this.republishingItem.id);
                        this.$delete(this.allBannerIds.unpublished, foundIndex);
                        this.deletedUnpublishedId = this.republishingItem.id;

                        $('#republishBannerPromptDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.republishBanner = false;
                    console.error('Catched Error:', error);
                });
        },

        // 刪除所有下架橫幅
        openDeleteAllPromptDialogue() {
            $('#deleteAllBannersPromptDialogue').modal('show');
        },
        deleteAllBanners() {
            this.isPostingApi.deleteAllBanners = true;

            this.$httpRequest.get('/admin_api/banner/delete_all_unpublished_banner')
                .then(response => {
                    this.isPostingApi.deleteAllBanners = false;

                    if (response.data.state == 'OK') {
                        this.allBannerIds.unpublished = [];
                        this.isDeleteAllBanners = true;

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                        $('#deleteAllBannersPromptDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.deleteAllBanners = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}
