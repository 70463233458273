// consts
import { punctuationRegexp } from '@/global/consts/regexp'

/**
 * 高亮化單一關鍵字
 * @param {String} content 原始內容
 * @param {String} keyword 關鍵字
 * @returns {String} 高亮化後的新內容
 */
function highlightKeyword(content, keyword, multi = false) {
    if (!keyword) {
        return content;
    }

    // 輸入的關鍵字內容轉為小寫比對並移除前後空白
    let pattern = keyword.toLowerCase().trim();

    // 輸入的關鍵字內容若有標點符號, 則需在符號前加上 "\"
    pattern = pattern.replace(punctuationRegexp, match => {
        return `\\${match[0]}`;
    });

    // 若要複數個關鍵字搜尋 (e.g. keyword = 'a b c'), 則需將空格替換成管線
    if (multi) {
        pattern = pattern.replace(/ +/g, '|');
    }

    // 將原始內容中的關鍵字加上顯著樣式
    const regexp = new RegExp(pattern, 'gi');
    const newContent = content.replace(regexp, '<em class="keyword">$&</em>');

    return newContent;
}


const methods = {
    highlightKeyword
};

export default methods
