import Layout from "@/layouts"

// components
const Profile = resolve => require(["@/components/user/profile.vue"], resolve)
const Coupons = resolve => require(["@/components/user/coupons.vue"], resolve)
const Orders = resolve => require(["@/components/user/orders.vue"], resolve)
const Gifts = resolve => require(["@/components/user/gifts.vue"], resolve)
const Favorite = resolve => require(["@/components/user/favorite.vue"], resolve)
const Group = resolve => require(["@/components/user/group.vue"], resolve)
const Mission = resolve => require(["@/components/user/mission.vue"], resolve)
const Portfolio = resolve => require(["@/components/user/portfolio.vue"], resolve)

export default {
    name: 'User',
    components: {
        Layout,
        Profile,
        Coupons,
        Orders,
        Gifts,
        Favorite,
        Group,
        Mission,
        Portfolio,
    },
    watch: {
        userInfo(newValue) {
            // 已登入->未登入
            if (this.$_.isEmpty(newValue)) {
                this.$router.push('/').catch(() => {});
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        isGiftOrder: {
            get() {
                return this.$store.state.user.isGiftOrder;
            },
            set(value) {
                this.$store.commit('user/setIsGiftOrder', value);
            }
        },
        componentType() {
            if (!this.$route.params.page) {
                return Profile;
            }

            // 是否為贈送訂單
            this.isGiftOrder = this.$route.params.page === 'gift-orders';

            // 訂購紀錄 - 課程贈送
            if (this.$route.params.page === 'gift-orders') {
                return Orders;
            }

            return this.$route.params.page;
        }
    },
    // 切換 user.vue 下的子層頁面 (history mode)
    beforeRouteUpdate(to, from, next) {
        if (this.$_.isEmpty(this.userInfo)) {
            next();
        } else {
            this.uploadUserDetailInfo();
            next();
        }
    },
    // 跳離 user.vue 這個主路由 (history mode)
    beforeRouteLeave(to, from, next) {
        if (this.$_.isEmpty(this.userInfo)) {
            next();
        } else {
            this.uploadUserDetailInfo();
            next();
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', 'user');
        this.$store.commit('common/setIsAdminPage', false);

        // 使用者尚未登入時, 無法進入我的專區頁面
        if (this.$_.isEmpty(this.userInfo)) {
            this.$router.push('/').catch(() => {});
            $('#loginDialogue').modal('show');
        } else {
            this.initial();

            // 離開頁面前 (redirect mode)
            window.onbeforeunload = () => {
                this.uploadUserDetailInfo();
            }
        }
    },
    beforeDestroy: function() {
        window.onbeforeunload = null;
    },
    methods: {
        initial() {
            // 使用者資訊相關變數
            this.$store.commit('user/setUserDetailInfo', {});
            this.$store.commit('user/setOldUserDetailInfo', {});
            // 我的優惠券相關變數
            this.$store.commit('user/setCouponsCanUseInfo', {});
            // 訂購紀錄相關變數
            this.$store.commit('user/setActiveUnsubscribeOrder', {});
        },

        // 更新使用者個人資訊
        uploadUserDetailInfo() {
            // 非 '個人資訊' 頁面, 則不需進行以下處理
            if (this.$route.params.page) {
                return;
            }

            this.$store.dispatch('user/uploadUserDetailInfo');
        }
    }
}
