// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const UserCouponList = resolve => require(["@/components/order/index/_user_coupon_list.vue"], resolve)

export default {
    props: ['checkCouponCourseIds'],
    components: {
        Dialogue,
        UserCouponList
    },
    data: function() {
        return {
            // 選擇優惠券
            allUserCouponIds: [],
            inputCouponCode: '',
            newCouponList: [],  // 新領取的優惠券列表
            deletingUserCouponIds: [],  // 準備刪除的優惠券 ucIds (遇到重複狀況時)
            isInputCodeError: false,  // 優惠代碼是否輸入錯誤
            isGettingNewCoupon: false,  // 是否正在取得新優惠券

            isSetDataReady: {
                getUserCouponIds: false,  // 取得個人優惠券的 ids
            }
        }
    },
    watch: {
        inputCouponCode() {
            this.isInputCodeError = false;
        }
    },
    computed: {
        isGiftOrder: {
            get() {
                return this.$store.state.order.isGiftOrder;
            },
            set(value) {
                this.$store.commit('order/setIsGiftOrder', value);
            }
        },
        selectedCoupon: {
            get() {
                return this.$store.state.order.selectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setSelectedCoupon', value);
            }
        },
        confirmSelectedCoupon: {
            get() {
                return this.$store.state.order.confirmSelectedCoupon;
            },
            set(value) {
                this.$store.commit('order/setConfirmSelectedCoupon', value);
            }
        },
        couponsCanUseInfo() {
            return this.$store.state.user.couponsCanUseInfo;
        }
    },
    mounted: function() {
        // 一開啟選擇優惠券彈窗時
        $('#selectCouponDialogue').on('show.bs.modal', () => {
            this.getUserCouponIds();
        });

        // 開啟選擇優惠券彈窗後
        $('#selectCouponDialogue').on('shown.bs.modal', () => {
            $('#couponCodeInput').focus();
        });

        // 關閉選擇優惠券彈窗後
        $('#selectCouponDialogue').on('hidden.bs.modal', () => {
            this.isSetDataReady.getUserCouponIds = false;
            this.inputCouponCode = '';
            this.newCouponInfo = {};
        });
    },
    methods: {
        async getUserCouponIds() {
            let payload = {
                type: 'order',
                courseIds: this.checkCouponCourseIds,
                isGift: this.isGiftOrder
            }

            this.allUserCouponIds = await this.$store.dispatch('user/getUserCouponIds', payload);
            this.isSetDataReady.getUserCouponIds = true;
            this.selectedCoupon = this.confirmSelectedCoupon;
        },
        async getNewCoupon() {
            $('.lms-btn.get-coupon-btn').blur();

            if (!this.inputCouponCode || !this.checkCouponCourseIds.length) {
                return;
            }

            let payload = {
                code: this.inputCouponCode,
                courseIds: this.checkCouponCourseIds,
                isGift: this.isGiftOrder
            }

            this.isGettingNewCoupon = true;

            try {
                let newCoupons = await this.$store.dispatch('user/getNewCoupons', payload);

                this.deletingUserCouponIds = [];
                let concatingUserCouponIds = [];

                for (let newCoupon of newCoupons) {
                    // 使用者已領取過目前輸入的優惠券
                    if (newCoupon.isExist) {
                        // 從原 userCouponIds 陣列中, 刪除該 userCouponId element
                        this.allUserCouponIds = this.$_.remove(this.allUserCouponIds, el => {
                            return el !== newCoupon.id;
                        });
                        // 準備刪除的 ucIds
                        this.deletingUserCouponIds.push(newCoupon.id);
                    }

                    concatingUserCouponIds.push(newCoupon.id);
                }

                // 把新增的 ucIds 至 userCouponIds 陣列前面
                this.allUserCouponIds = concatingUserCouponIds.concat(this.allUserCouponIds);

                // 取得優惠券資訊
                let newCouponList = await this.$store.dispatch('user/getCouponsInfo', { userCouponIds: concatingUserCouponIds });
                // 設置列表新增的項目資訊
                this.newCouponList = newCouponList;

                // 預設選取目前新增並且可使用的優惠券
                if (newCouponList.length && this.couponsCanUseInfo[newCouponList[0].id]) {
                    this.selectedCoupon = {
                        id: newCouponList[0].id,
                        type: newCouponList[0].couponType,
                        name: newCouponList[0].couponName,
                        discountType: newCouponList[0].discountType,
                        discount: newCouponList[0].discount
                    }
                }

                this.inputCouponCode = '';
                this.isGettingNewCoupon = false;

                // 卷軸移動回到列表最上方
                $('.coupon-list').animate({
                    scrollTop: 0
                }, 500);
            } catch (error) {
                this.isGettingNewCoupon = false;
                this.isInputCodeError = true;
                console.error('Catched Error:', error);
            }
        },
        useCoupon() {
            this.confirmSelectedCoupon = this.selectedCoupon;
            $('#selectCouponDialogue').modal('hide');
        },
        clearInputCouponCode() {
            this.inputCouponCode = '';
            $('#couponCodeInput').focus();
        }
    }
}
