// utils
import announcementUtil from '@/assets/js/utils/announcement'
// common
import Dialogue from "@/components/common/dialogue.vue"

export default {
    components: {
        Dialogue
    },
    data: function() {
        return {
            announcement: {},

            isGettingNewCoupon: false,  // 取得新優惠券
            isSetDataReady: {
                getAnnouncement: false,  // 取得公告
                loadContentImage: false,  // 載入內容圖片
            },
            isPostingApi: {
                callToAction: false,  // 觸發公告功能
            }
        }
    },
    watch: {
        userInfo(newValue) {
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.getAnnouncement();
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        triggeredTypeIconName() {
            return this.announcement.triggeredType ? announcementUtil.getIconNameByTriggeredType(this.announcement.triggeredType) : '';
        }
    },
    created: function() {
        if (!this.$_.isEmpty(this.userInfo)) {
            this.getAnnouncement();
        }
    },
    methods: {
        getAnnouncement() {
            this.$httpRequest.get('/api/announcement/get_announcement')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (!this.$_.isEmpty(result)) {
                            this.announcement = {
                                id: result.id,
                                title: result.title,
                                contentType: null,
                                contentText: '',
                                contentImageUrl: '',
                                triggeredType: result.type,
                                linkedResource: {},
                                triggeredBtnText: result.button_text
                            }

                            // 公告類型(文字, 圖片)
                            // 內容圖片
                            if (this.$_.has(result, 'file') && !this.$_.isEmpty(result.file)) {
                                this.announcement.contentType = '1';
                                this.announcement.contentImageUrl = result.file.file_name;

                                this.$nextTick(() => {
                                    let img = document.createElement("img");
                                    img.src = this.announcement.contentImageUrl;

                                    img.onload = () => {
                                        this.isSetDataReady.loadContentImage = true;
                                    }
                                });
                            }
                            // 內容文字
                            else if (this.$_.has(result, 'content') && result.content) {
                                this.announcement.contentType = '0';
                                this.announcement.contentText = result.content;
                            }

                            // 觸發功能類型非 '無' 時, 會有連結項目
                            if (this.announcement.triggeredType !== '0' && !this.$_.isEmpty(result.resource)) {
                                this.announcement.linkedResource.id = result.resource.resource_id;
                                // 課程
                                if (this.announcement.triggeredType === '1') {
                                    this.announcement.linkedResource.alias = result.resource.alias;
                                }
                                // 優惠
                                if (this.announcement.triggeredType === '2') {
                                    this.announcement.linkedResource.code = result.resource.code;
                                }
                            }

                            this.isSetDataReady.getAnnouncement = true;

                            $('#announcementDialogue').modal('show');
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        callToAction() {
            if (this.announcement.triggeredType === '0') {
                $('#announcementDialogue').modal('hide');
                return;
            }

            let params = {
                announcementId: this.announcement.id
            }

            this.isPostingApi.callToAction = true;

            this.$httpRequest.post('/api/announcement/click_button', params)
                .then(response => {
                    this.isPostingApi.callToAction = false;

                    if (response.data.state == 'OK') {
                        $('#announcementDialogue').modal('hide');

                        // 課程
                        if (this.announcement.triggeredType === '1') {
                            this.redirectCoursePage();
                        }
                        // 優惠
                        if (this.announcement.triggeredType === '2') {
                            this.getNewCoupon();
                        }
                        // 說明
                        if (this.announcement.triggeredType === '3') {
                            this.redirectInstructionPage();
                        }
                    }
                })
                .catch(error => {
                    this.isPostingApi.callToAction = false;
                    console.error('Catched Error:', error);
                });
        },
        redirectCoursePage() {
            if (this.announcement.triggeredType !== '1' || !this.announcement.linkedResource.id) {
                return;
            }

            this.$router.push(`/course/${this.announcement.linkedResource.alias}`).catch(() => {});
        },
        getNewCoupon() {
            if (this.announcement.triggeredType !== '2' || !this.announcement.linkedResource.code) {
                return;
            }

            let params = {
                code: this.announcement.linkedResource.code,
                courseIds: []
            }

            this.isGettingNewCoupon = true;

            this.$httpRequest.post('/api/coupon/user_get_coupon', params)
                .then(response => {
                    this.isGettingNewCoupon = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.$store.dispatch('common/setAlert', { status: 'success', msg: '優惠領取成功' });
                        }
                    }
                })
                .catch(error => {
                    this.isGettingNewCoupon = false;
                    console.error('Catched Error:', error);
                });
        },
        redirectInstructionPage() {
            if (this.announcement.triggeredType !== '3' || !this.announcement.linkedResource.id) {
                return;
            }

            // this.$router.push(`/instruction/${this.announcement.linkedResource.id}`).catch(() => {});
        }
    }
}
