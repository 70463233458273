import InfiniteScroll from "vue-infinite-scroll"

export default {
    props: ['allExchangedCoursesInfo', 'newCourseList', 'deletingCourseIds'],
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            courseList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        newCourseList(newValue) {
            if (newValue) {
                this.courseList = newValue.concat(this.courseList);
                this.loadingIndex = this.courseList.length;
            }
        },
        deletingCourseIds(newValue) {
            if (newValue) {
                for (let id of newValue) {
                    this.courseList = this.$_.remove(this.courseList, el => {
                        return el.id !== id;
                    });
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        }
    },
    created: function() {
        if (this.allExchangedCoursesInfo.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                setTimeout(async () => {
                    await this.getCourseList();
                    this.busy = false;
                }, 200);
            }
        },
        async getCourseList() {
            let infos = [];

            for (let i = 0; i < this.loadCountPerPage; i++) {
                let tempCourseInfo = this.allExchangedCoursesInfo[this.loadingIndex];
                if (!tempCourseInfo) {
                    this.isGetAllList = true;
                    break;
                }
                infos.push(tempCourseInfo);
                this.loadingIndex++;
            }

            if (infos.length == 0) {
                return;
            }

            let newCourseList = await this.$store.dispatch('user/getExchangedCourseInfo', { infos: infos });
            this.courseList = this.courseList.concat(newCourseList);
        }
    }
}
