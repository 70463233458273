export default {
    props: {
        id: {
            type: String,
            default: 'defaultClientDialogue',
        },
        size: {
            type: [String, Number],
            default: 'sm',
        },
        tabletSize: {  // 768 ~ 991
            type: Number,
            default: 500,
        },
        centeredPosition: {
            type: Boolean,
            default: false,
        },
        contentStyle: {
            type: String,
            default: 'default',
        },
        contentHeight: {
            type: String,
            default: 'auto',
        },
        confirmBtnColor: {
            type: String,
            default: 'orange',
        },
        isDisabledConfirm: {
            type: Boolean,
            default: false,
        },
        isDisabledCancel: {
            type: Boolean,
            default: false,
        },
        arrowLeft: {
            type: Boolean,
            default: false,
        },
        arrowRight: {
            type: Boolean,
            default: false,
        },
        /** 兼容用 */
        modalBodyVersion: {
            type: String,
            default: 'v1',
        },
    },
    data: function() {
        return {
            sizeOptions: ['sm', 'md', 'lg'],
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        dialogueSize() {
            switch (this.size) {
                case 'sm':
                    return 'modal-sm';
                case 'md':
                    return 'modal-md';
                case 'lg':
                    return 'modal-lg';
                default:
                    return '';
            }
        },
        dialoguePosition() {
            return this.centeredPosition ? 'modal-dialog-centered' : '';
        },
        dialogueStyle() {
            if (!this.sizeOptions.includes(this.size)) {
                if (this.windowWidth >= 992) {
                    return {
                        maxWidth: `${this.size}px`,
                    }
                }
                if (this.windowWidth >= 768 && this.windowWidth < 992) {
                    return {
                        maxWidth: this.size > this.tabletSize ? `${this.tabletSize}px` : `${this.size}px`,
                    }
                }
            }
            return '';
        },
    },
    mounted: function() {
        this.$emit('load');
    },
    methods: {
        closeDialogue() {
            $(`#${this.id}`).modal('hide');
        },
    },
}
