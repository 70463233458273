// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const Sidebar = resolve => require(["@/components/admin/course/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/course/list/_table_list.vue"], resolve)

export default {
    components: {
        Dialogue,
        Sidebar,
        TableList
    },
    data: function() {
        return {
            activePage: null,

            allCourseIds: [],
            searchText: '',
            renderTableListKey: 0,

            newCourseName: '',

            isPostingApi: {
                addCourse: false  // 新增課程
            }
        }
    },
    watch: {
        isClickSidebarLink(newValue) {
            if (newValue) {
                this.getCourseIdsByState();
            }
            this.isClickSidebarLink = false;
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部課程',
                    state: '-1',
                    url: '/admin/course/list/all',
                    isShow: true
                },
                {
                    name: '編輯中',
                    state: '0',
                    url: '/admin/course/list/edit',
                    isShow: true
                },
                {
                    name: '待審核',
                    state: '1',
                    url: '/admin/course/list/audit',
                    isShow: true
                },
                {
                    name: '已排程',
                    state: '2',
                    url: '/admin/course/list/schedule',
                    isShow: true
                },
                {
                    name: '上架中',
                    state: '3',
                    url: '/admin/course/list/publish',
                    isShow: true
                },
                {
                    name: '停售中',
                    state: '4',
                    url: '/admin/course/list/stop_selling',
                    isShow: true
                },
                {
                    name: '已封存',
                    state: '5',
                    url: '/admin/course/list/archieve',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminCourse.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminCourse/setIsClickSidebarLink', value);
            }
        }
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getCourseIdsByState();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addCourseDialogue').on('shown.bs.modal', () => {  // 新增課程
            $('#addCourseInput').focus();
        });

        // 彈窗打開有按下 enter 按鍵後做對應動作
        $("#addCourseDialogue").keypress(event => {  // 新增課程
            let keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13') {
                this.addCourse();
            }
        });
    },
    methods: {
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/course/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getCourseIdsByState() {
            await this.getActivePage();

            let params = {
                state: this.activePage.state
            }

            this.allCourseIds = [];
            this.searchText = '';

            this.$httpRequest.get('/admin_api/course/get_course_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCourseIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getCourseIdsBySearchText() {
            if (!this.searchText) {
                return;
            }

            let params = {
                state: this.activePage.state,
                key: this.searchText
            }

            this.allCourseIds = [];

            this.$httpRequest.get('/admin_api/course/get_course_ids_by_search', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allCourseIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showAddCourseDialogue() {
            $('#addCourseDialogue').modal('show');
        },
        addCourse() {
            if (!this.newCourseName || this.isPostingApi.addCourse) {
                return;
            }

            let params = {
                name: this.newCourseName
            }

            this.isPostingApi.addCourse = true;

            this.$httpRequest.post('/admin_api/course/create_course', params)
                .then(response => {
                    this.isPostingApi.addCourse = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addCourseDialogue').modal('hide');
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                            // 進入課程編輯頁面
                            this.$router.push(`/admin/course/edit/info/${result}`);
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
