// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const Sidebar = resolve => require(["@/components/admin/article/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/article/list/_table_list.vue"], resolve)
const CourseTableList = resolve => require(["@/components/admin/article/list/_course_table_list.vue"], resolve)


export default {
    components: {
        Dialogue,
        Sidebar,
        TableList,
        CourseTableList
    },
    data: function() {
        return {
            activePage: null,

            allArticleIds: [],
            searchText: "",
            renderTableListCount: 0,

            newArticleName: "",
            newArticleType: 0,
            isJoinCourse: true, // 如果沒有課程ID pop up內容會先導入選擇課程的畫面，再出現輸入文章名稱的input

            isPostingApi: {
                addArticle: false  // 新增文章
            },

            // link course
            courseSearchText: '',
            allCourseIds: [],
            renderCourseTableListCount: 0,
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部文章',
                    state: '-1',
                    url: '/admin/article/list/all',
                    isShow: true
                },
                {
                    name: '編輯中',
                    state: '0',
                    url: '/admin/article/list/editing',
                    isShow: true
                },
                {
                    name: '待審核',
                    state: '1',
                    url: '/admin/article/list/auditing',
                    isShow: true
                },
                {
                    name: '已排程',
                    state: '2',
                    url: '/admin/article/list/scheduled',
                    isShow: true
                },
                {
                    name: '上架中',
                    state: '3',
                    url: '/admin/article/list/publishing',
                    isShow: true
                },
                {
                    name: '已封存',
                    state: '4',
                    url: '/admin/article/list/archieved',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        articleLinkCourseId: {
            get() {
                return this.$store.state.adminArticle.articleLinkCourseId;
            },
            set(value) {
                this.$store.commit('adminArticle/setArticleLinkCourseId', value);
            }
        },
        contentTypeOptList() {
            let opts = [];
            opts = [
                {
                    value: '0',
                    text: '英文'
                },
                {
                    value: '1',
                    text: '非語文'
                }
            ]
            return opts;
        },
    },
    created: function() {
        this.getArticleIdsByState();
    },
    mounted: function() {
        // 如果從課程過來需要打開新增文章pop up
        if (this.$route.query.cId) {
            this.isJoinCourse = false;
            $('#adminAddArticleDialogue').modal('show');
        }
    },
    methods: {
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/article/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getArticleIdsByState() {
            await this.getActivePage();

            let params = {
                state: this.activePage.stateNum
            }

            console.log('admin/article/_list.js - getArticleIdsByState', params);

            this.allArticleIds = [];

            // fake data
            if (this.activePage.stateNum == '-1') {
                for (let i = 0; i < 65; i++) {
                    this.allArticleIds.push(`${i}`);
                }
            } else {
                for (let i = 0; i < 42; i++) {
                    this.allArticleIds.push(`${i}`);
                }
            }
            this.renderTableListCount++;

            // !!! api
            // this.$httpRequest.get('', params)
            //     .then(response => {
            //         if (response.data.state == 'OK') {
            //             let result = response.data.result;

            //             if (result) {
            //                 this.allArticleIds = result;

            //                 this.renderTableListCount++;
            //             }
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log('catched error: ' + error);
            //     });
        },
        getArticleIdsBySearchText() {
            let params = {
                state: this.activePage.stateNum,
                searchText: this.searchText
            }

            console.log('admin/article/_list.js - getArticleIdsBySearchText', params);

            this.allArticleIds = [];

            // fake data
            if (this.searchText) {
                for (let i = 0; i < 26; i++) {
                    this.allArticleIds.push(`${i}`);
                }
            } else {
                if (this.activePage.stateNum == '-1') {
                    for (let i = 0; i < 65; i++) {
                        this.allArticleIds.push(`${i}`);
                    }
                } else {
                    for (let i = 0; i < 42; i++) {
                        this.allArticleIds.push(`${i}`);
                    }
                }
            }
            this.renderTableListCount++;

            // !!! api
            // this.$httpRequest.get('', params)
            //     .then(response => {
            //         if (response.data.state == 'OK') {
            //             let result = response.data.result;

            //             if (result) {
            //                 this.allArticleIds = result;

            //                 this.renderTableListCount++;
            //             }
            //         }
            //     })
            //     .catch(function (error) {
            //         console.log('catched error: ' + error);
            //     });
        },
        showAddArticleDialogue() {
            this.isJoinCourse = true;
            this.courseSearchText = '';
            this.allCourseIds = [];
            this.renderCourseTableListCount++;
            $('#adminAddArticleDialogue').modal('show');
        },
        addArticle() {
            if (this.isJoinCourse) {
                this.isJoinCourse = !this.isJoinCourse;
            }

            if (!this.newArticleName || this.isPostingApi.addArticle || this.isJoinCourse) {
                return;
            }

            let params = {
                name: this.newArticleName,
                // 如果是從課程過來會有課程ID，沒有再從articleLinkCourseId取得
                courseId: this.$route.query.cId ? this.$route.query.cId : this.articleLinkCourseId,
                type: this.newArticleType
            }

            this.isPostingApi.addArticle = true;

            this.$httpRequest.post('/admin_api/article/create_article', params)
                .then(response => {
                    this.isPostingApi.addArticle = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 成功建立文章將參數刪除
                            this.$router.replace({'query': null});
                            $('#adminAddArticleDialogue').modal('hide');
                            // 進入文章編輯頁面
                            this.$router.push(`/admin/article/edit/info/${result}`);
                        }
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        getCourseIdsBySearchText() {
            let params = {
                    key: this.courseSearchText,
                    state: -1
                };

            this.$httpRequest.get('/admin_api/course/get_course_ids_by_search', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allCourseIds = result;
                                this.renderCourseTableListCount++;
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                    });
        },
    }
}
