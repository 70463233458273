/**
 * 回傳觸發功能類型各數值對應的 icon 圖檔檔名
 * @param     string  val
 *
 * @return    string  對應的 icon 圖檔檔名
 */
function getIconNameByTriggeredType(val) {
    switch (val) {
        case '0':  // 無
        case '3':  // 說明
            return 'announcement';
        case '1':  // 課程
            return 'course';
        case '2':  // 優惠
            return 'coupon';
    }
}


const methods = {
    getIconNameByTriggeredType
}

export default methods
