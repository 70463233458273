import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue_client.vue"
// components
const FavoriteDialogue = resolve => require(["@/components/article/read/_favorite_article_dialogue.vue"], resolve)

export default {
    props: ['allMyArticleIds'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue,
        FavoriteDialogue
    },
    data: function() {
        return {
            articleList: [],
            oldArticleList: [],
            favoriteIds: [],
            articlesContent: {},
            isCheckedAll: false,
            articleId: '', // 我的最愛文章移動使用
            // 刪除最愛文章使用
            articleName: '',
            favoriteId: '',

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isShowDeleteArticles: {
            get() {
                return this.$store.state.user.isShowDeleteArticles;
            },
            set(value) {
                this.$store.commit('user/setShowDeleteArticles', value);
            }
        },
        isDisableOtherBtn: {
            get() {
                return this.$store.state.user.isDisableOtherBtn;
            },
            set(value) {
                this.$store.commit('user/setDisableOtherBtn', value);
            }
        },
    },
    created: function() {
        if (this.allMyArticleIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                setTimeout(() => {
                    this.getArticleList()
                        .then(() => this.busy = false)
                        .catch(error => console.log('catched error: ' + error));
                }, 200);
            }
        },
        getArticleList() {
            return new Promise((resolve, reject) => {

                let articleIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempArticleId = this.allMyArticleIds[this.loadingIndex];
                    if (!tempArticleId) {
                        this.isGetAllList = true;
                        break;
                    }
                    articleIds.push(tempArticleId);
                    this.loadingIndex++;
                }

                if (articleIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    bookmarkIds: articleIds
                }

                this.$httpRequest.post('/api/bookmark/get_bookmark_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result && !this.$_.isEmpty(result)) {
                                for (let id of articleIds) {
                                    let tempArticle = result[id];
                                    if (tempArticle) {
                                        this.articleList.push({
                                            id: id,
                                            articleId: tempArticle.resId,
                                            name: tempArticle.name,
                                            cover: tempArticle.coverUrl,
                                            content: tempArticle.contents,
                                            courseName: tempArticle.courseName,
                                            isChecked: false,
                                            isEditName: false
                                        });
                                    }
                                }

                                if (this.articleList.length > 0) {
                                    this.oldArticleList = this.$_.cloneDeep(this.articleList);
                                }

                                resolve();
                            } else {
                                reject("user/favorite/_article_list.js: 'getArticleList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("user/favorite/_article_list.js: 'getArticleList' get error");
                        }
                    })
                    .catch(error => {
                        console.log('catched error: ' + error);
                        reject("header/_article_list.js: 'getArticleList' get error");
                    });
            });
        },
        selectAllCheckbox() {
            if (this.isCheckedAll == true) {
                this.articleList.forEach(el => {
                    this.favoriteIds.push(el.id);
                })
            }
            else {
                this.favoriteIds = [];
            }
        },
        showDeleteFavoriteDialogue(favoriteId, articleName) {
            if (favoriteId != '') {
                this.favoriteId = favoriteId;
                this.articleName = articleName;
            }
            $('#deleteFavoriteDialogue').modal('show');
        },
        deleteArticles() { // 批次刪除
            let params = {
                bookmarkIds: this.favoriteIds,
                isNew: true,
            }

            if (this.favoriteId != '') {
                this.favoriteIds = [this.favoriteId];
                params.bookmarkIds = [this.favoriteId];
            }

            this.$httpRequest.post('/api/bookmark/delete_bookmarks', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        for(let i=this.articleList.length-1; i>=0; i--) {
                            let checkedIndex = this.favoriteIds.findIndex(item => item == this.articleList[i].id);
                            if (checkedIndex > -1) {
                                this.$parent.deleteArticleIds(this.articleList[i].id);
                                this.articleList.splice(i, 1);
                                this.favoriteIds.splice(checkedIndex, 1);
                            }
                        }
                        this.isShowDeleteArticles = false;
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    if (response.data.state == 'ERROR') {
                        console.log("user/read/_favorite_article_dialogue.js: 'saveClassificationName' get error");
                    }
                    this.articleId = '';
                    $('#deleteFavoriteDialogue').modal('hide');
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                    console.log("user/read/_favorite_article_dialogue.js: 'saveClassificationName' get error");
                });
        },
        cancelEditArticleName(index) {
            this.articleList[index].name = this.oldArticleList[index].name;
            this.articleList[index].isEditName = false;
            this.isDisableOtherBtn = false;
        },
        saveArticleName(index) {
            let params = {
                bookmarkId: this.articleList[index].id,
                name: this.articleList[index].name
            }

            this.$httpRequest.post('/api/bookmark/update_bookmark_name', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.isDisableOtherBtn = false;
                        this.articleList[index].isEditName = false;
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    if (response.data.state == 'ERROR') {
                        console.log("user/read/_favorite_article_dialogue.js: 'saveClassificationName' get error");
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                    console.log("user/read/_favorite_article_dialogue.js: 'saveClassificationName' get error");
                });
        },
        showFavoriteDialogue(articleId, favoriteId) {
            this.articleId = articleId;
            this.favoriteId = favoriteId;
            setTimeout(() => { // 因為讀取時間差，所以設timeout
                if (this.articleId) {
                    $('#favoriteDialogue').modal('show');
                }
            }, 150)

        },
    }
}
