var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"coupon-list lms-scrollbar vertical",attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"10","infinite-scroll-immediate-check":"false"}},[_vm._l((_vm.userCouponList),function(coupon){return _c('div',{key:coupon.id,staticClass:"coupon-item lms-wrapper coupon-wrapper",class:[!coupon.canUse ? 'gray' :
                    (coupon.couponType === '0' ? 'blue' :
                        (coupon.couponType === '1' || coupon.couponType === '2' ? 'orange' : '')
                    )]},[_c('div',{staticClass:"coupon-layout"},[_c('div',{staticClass:"coupon-tag"},[_c('div',{staticClass:"tag-wrapper"},[_c('div',{staticClass:"tag-content"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/icon/48x48/coupon_white.png")}}),_c('div',{staticClass:"tag-info"},[_c('div',[(coupon.discountType === '0')?[_c('span',{staticClass:"discount-info"},[_vm._v(_vm._s(coupon.discount))]),_vm._v(" 日 ")]:_vm._e(),(coupon.discountType === '1')?[_vm._v(" NT$ "),_c('span',{staticClass:"discount-info"},[_vm._v(_vm._s(new Intl.NumberFormat().format(coupon.discount)))])]:_vm._e(),(coupon.discountType === '2')?[_c('span',{staticClass:"discount-info"},[_vm._v(_vm._s(new Intl.NumberFormat().format(100 - coupon.discount)))]),_vm._v(" %OFF ")]:_vm._e()],2),(coupon.couponType === '0' &&
                                        coupon.discountType === '1')?_c('span',[_vm._v("(1個月)")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.orderUtil.setDiscountTypeText(coupon.discountType)))])])]),_c('div',{staticClass:"radial-edge"})])]),_c('div',{staticClass:"coupon-info"},[_c('div',{staticClass:"info-wrapper"},[_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"name-info"},[_c('span',{staticClass:"coupon-name word-ellipsis"},[_vm._v(" "+_vm._s(coupon.couponName)+" ")]),(coupon.canUse)?[_c('span',{staticClass:"course-name word-ellipsis"},[_vm._v(" 適用："+_vm._s(coupon.courseName)+" ")])]:[_c('span',{staticClass:"coupon-hint"},[_vm._v(" 不適用此課程 ")])]],2),_c('span',{staticClass:"end-date"},[(_vm.windowWidth >= 768)?_c('span',[_vm._v("有效期限：")]):_vm._e(),_vm._v(_vm._s(_vm.orderUtil.formatEndDatetime(coupon.endDate))+" ")])]),(coupon.canUse)?_c('div',{staticClass:"lms-radiobtn dotted md"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCoupon),expression:"selectedCoupon"}],attrs:{"type":"radio","id":`couponItem${coupon.id}`,"name":"selectcoupon","hidden":""},domProps:{"value":{
                                        id: coupon.id,
                                        type: coupon.couponType,
                                        name: coupon.couponName,
                                        discountType: coupon.discountType,
                                        discount: coupon.discount
                                    },"checked":_vm._q(_vm.selectedCoupon,{
                                        id: coupon.id,
                                        type: coupon.couponType,
                                        name: coupon.couponName,
                                        discountType: coupon.discountType,
                                        discount: coupon.discount
                                    })},on:{"change":function($event){_vm.selectedCoupon={
                                        id: coupon.id,
                                        type: coupon.couponType,
                                        name: coupon.couponName,
                                        discountType: coupon.discountType,
                                        discount: coupon.discount
                                    }}}}),_c('label',{attrs:{"for":`couponItem${coupon.id}`}})]):_vm._e()]),_c('div',{staticClass:"radial-edge"})])])])])}),(_vm.busy)?_c('div',{staticClass:"m-t-5"},[_c('DotLoading')],1):_vm._e(),(!_vm.busy && _vm.isGetAllList && !_vm.userCouponList.length)?_c('div',{staticClass:"empty-item"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"coupon-alarm"},[_vm._v("(所有優惠券均不適用於合購優惠課程)")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lms-wrapper list-empty-wrapper"},[_c('img',{staticClass:"empty-icon",attrs:{"src":require("@/assets/image/icon/300x300/coupon.png")}}),_c('span',{staticClass:"empty-text"},[_vm._v("尚無優惠券")])])
}]

export { render, staticRenderFns }