import InfiniteScroll from 'vue-infinite-scroll'

// utils
import searchUtil from '@/assets/js/utils/search'

export default {
    props: {
        selectedEventId: {
            required: true,
            type: String,
        },
    },
    directives: {
        InfiniteScroll,
    },
    data: function() {
        return {
            keyword: '',
            onKeywordInput: this.$node.debounce(this.getEventIds, 500),
            isSearching: false,  // 是否正在搜尋活動
            isShowSearchBox: false,

            allEIds: [],  // e: event
            eventList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        keyword() {
            this.isSearching = true;
            this.initialize();
        },
    },
    computed: {
        searchUtil() {
            return searchUtil;
        },
    },
    created: function() {
        this.getEventIds();
    },
    mounted: function() {
        this.registerDropdownEvents();
    },
    methods: {
        registerDropdownEvents() {
            // 一開啟活動下拉選單時
            $('#eventDropdown').on('show.bs.dropdown', () => {
                if (this.keyword) {
                    this.keyword = '';
                    this.getEventIds();
                }
            });
            // 一關閉活動下拉選單時
            $('#eventDropdown').on('hide.bs.dropdown', () => {
                if (this.keyword) {
                    document.getElementById('eventList').scrollTop = 0;  // 置頂列表卷軸
                }
            });
        },

        clearKeyword() {
            this.keyword = '';
            this.getEventIds();
            document.getElementById('eventListKeyword').focus();
        },

        initialize() {
            this.allEIds = [];
            this.eventList = [];
            this.busy = false;
            this.loadingIndex = 0;
            this.loadCountPerPage = 20;
            this.isGetAllList = false;
        },
        getEventIds() {
            let params = {};

            // 有輸入關鍵字
            if (this.keyword) {
                params.keyword = this.keyword;
            }

            this.$httpRequest.get('/api/event/get_event_list_ids', params)
                .then(response => {
                    this.isSearching = false;

                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.allEIds = result;

                            if (!this.allEIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    this.isSearching = false;
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getEventList();
            this.busy = false;

            if (this.allEIds.length) {
                this.isShowSearchBox = true;
            }
        },
        getEventList() {
            return new Promise((resolve, reject) => {
                let eIds = [];
                for (let i = 0; i < this.loadCountPerPage; i++) {
                    const eId = this.allEIds[this.loadingIndex];
                    if (!eId) {
                        this.isGetAllList = true;
                        break;
                    }
                    eIds.push(eId);
                    this.loadingIndex++;
                }

                if (!eIds.length) {
                    resolve();
                    return;
                }

                const params = {
                    eventIds: eIds,
                };

                this.$httpRequest.post('/api/event/get_event_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                for (const eId of params.eventIds) {
                                    const e = result[eId];

                                    if (!e) {
                                        continue;
                                    }

                                    this.eventList.push({
                                        id: eId,
                                        name: e.name,
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
    },
}
