// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const TitleBar = resolve => require(["@/components/admin/article/edit/_title_bar.vue"], resolve)
const Sidebar = resolve => require(["@/components/admin/article/_sidebar.vue"], resolve)
const Info = resolve => require(["@/components/admin/article/edit/info.vue"], resolve)
const Content = resolve => require(["@/components/admin/article/edit/content.vue"], resolve)
const Practice = resolve => require(["@/components/admin/article/edit/practice.vue"], resolve)
const Editors = resolve => require(["@/components/admin/article/edit/editors.vue"], resolve)
const RemoveSettings = resolve => require(["@/components/admin/article/edit/remove_settings.vue"], resolve)

export default {
    components: {
        Dialogue,
        TitleBar,
        Sidebar,
        Info,
        Content,
        Editors,
        RemoveSettings
    },
    data: function() {
        return {
            articleBasicInfoParams: {
                articleId: this.$route.params.id
            },

            editLogList: [],

            isSetDataReady: {
                getArticleBasicInfo: false
            },
            isPostingApi: {
                changeState: false  // 改變文章狀態
            }
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '文章資料',
                    url: `/admin/article/edit/info/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: '內容補充',
                    url: `/admin/article/edit/article/${this.$route.params.id}`,
                    isShow: true
                },
                {
                    name: '練習題',
                    url: `/admin/article/edit/practice/${this.$route.params.id}`,
                    isShow: true
                },
                // {
                //     name: '負責編輯',
                //     url: `/admin/article/edit/editors/${this.$route.params.id}`,
                //     isShow: this.$store.state.common.userInfo.permission == 'admin' || (this.$store.state.common.userInfo.permission == 'teacher' && this.articleBasicInfo.state == '0')
                // },
                {
                    name: '移除設定',
                    url: `/admin/article/edit/remove_settings/${this.$route.params.id}`,
                    isShow: this.$store.state.common.userInfo.permission == 'admin' || (this.$store.state.common.userInfo.permission == 'teacher' && this.articleBasicInfo.state == '0')
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        additionalSidebarList() {
            let list = [];

            list = [
                {
                    name: '文章報表',
                    url: `/admin/article/edit/statistics/${this.$route.params.id}`,
                    isShow: ['3', '4', '5'].includes(this.articleBasicInfo.state)
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        editLogTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "tab_name",
                    itemText: "頁籤名稱",
                    ownClass: "edit-log-tab-name",
                    isShow: true
                },
                {
                    itemName: "time",
                    itemText: "編輯時間",
                    ownClass: "edit-log-time",
                    isShow: true
                },
                {
                    itemName: "user",
                    itemText: "編輯人員",
                    ownClass: "edit-log-user",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        componentType() {
            switch (this.$route.params.subPage) {
                case 'info':
                    return Info;
                case 'article':
                    return Content;
                case 'practice':
                    return Practice;
                case 'editors':
                    return Editors;
                case 'remove_settings':
                    return RemoveSettings;
            }
        },
        articleBasicInfo() {
            return this.$store.state.adminArticle.articleBasicInfo;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminArticle.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminArticle/setIsClickSidebarLink', value);
            }
        },
        isFinishUpdatingData: {
            get() {
                return this.$store.state.adminArticle.isFinishUpdatingData;
            },
            set(value) {
                this.$store.commit('adminArticle/setIsFinishUpdatingData', value);
            }
        }
    },
    created: function() {
        this.initialize();
    },
    methods: {
        initialize() {
            let refreshArticleBasicInfo = () => {
                this.isSetDataReady.getArticleBasicInfo = false;

                this.$store.dispatch('adminArticle/getArticleBasicInfo', this.articleBasicInfoParams)
                    .then(() => {
                        this.isSetDataReady.getArticleBasicInfo = true;

                        // // 文章狀態 編輯中/待審核/已排程, 沒有 文章報表 頁面
                        // if (!['3', '4', '5'].includes(this.articleBasicInfo.state) && this.$route.params.subPage == 'statistics') {
                        //     this.$router.push('/admin/article/list/all');
                        // }
                        // 使用者權限為 teacher 時, 如果文章狀態不是編輯中, 沒有 移除/停售/封存文章 頁面
                        if (this.$store.state.common.userInfo.permission == 'teacher' &&
                            this.articleBasicInfo.state != '0' &&
                            this.$route.params.subPage == 'remove_settings') {
                            this.$router.push(`/admin/article/edit/info/${this.$route.params.id}`);
                        }
                        // 清除query參數
                        this.$router.replace({'query': null}).catch(() => {});
                    })
                    .catch((error) => {
                        console.log('catched error: ' + error);
                        // 代表從課程過來，需要courseId是因為如果articleBasicInfo沒資料會無法取得courseId
                        if (this.$route.query.cId) {
                            this.$router.push(`/admin/course/edit/articles/${this.$route.query.cId}`).catch(() => {});
                        }
                        else {
                            this.$router.push('/admin/article/list/all').catch(() => {});
                        }
                    });
            }

            refreshArticleBasicInfo();
        },

        getEditLogs() {
            $('.lms-btn.icon-circle.log').blur();

            let params = {
                articleId: this.$route.params.id
            }

            this.editLogList = [];

            this.$httpRequest.get('/admin_api/article/get_article_edit_log', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            result.forEach(el => {
                                this.editLogList.push({
                                    tabName: el.behavior,
                                    editedTime: this.$util.unixTimestampToDatetime(el.enterdate, this.$util.getBrowserCurrentTimeZone()),
                                    editedUser: el.name
                                });
                            });

                            $('#articleEditLogDialogue').modal('show');
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        changeArticleState(options) {
            // options = {
            //     activeDialogue: 'xxx',  // 目前開啟彈窗
            //     targetAction: 'xxx',  // 'edit' | 'audit' | 'schedule' | 'archive' | 'delete'
            //     isPublishNow: '0' | '1',  // 審核 -> 排程/上架 (action = 'schedule')
            //     publishTime: '0000/00/00 00:00',  // 排程上架時間 (action = 'schedule')
            //     noticeMsg: 'xxx'  // 移除/停售/封存通知 (action = 'delete' | 'archive')
            // }

            if (!this.$route.params.id || this.isPostingApi.changeState) {
                return;
            }

            let params = {
                articleId: this.$route.params.id,
                action: options.targetAction
            }

            // 文章狀態將改為 { 已排程 | 上架中 }
            if (params.action === 'schedule') {
                params.isNow = options.isPublishNow;  // 是否立即上架

                // 如果文章狀態將改為"已排程", 則參數需加上排程上架時間
                if (params.isNow == '0') {
                    params.time = options.publishTime;
                }
            }

            // 移除文章 or 文章狀態將改為已封存
            if (params.action === 'delete' || params.action === 'archive') {
                // 如果通知訊息有內容, 則參數需加上通知內容
                if (options.noticeMsg) {
                    params.msg = options.noticeMsg;
                }
            }

            this.isPostingApi.changeState = true;

            this.$httpRequest.post('/admin_api/article/change_article_state', params)
                .then(response => {
                    this.isPostingApi.changeState = false;

                    if (response.data.state == 'OK') {
                        $(`#${options.activeDialogue}`).modal('hide');  // 關閉提示彈窗
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });

                        // 除了移除文章導向"文章列表"頁面外, 其他成功改變狀態後, 皆導向編輯頁面中的"文章資料"頁面
                        let targetPage = '';
                        if (params.action === 'delete') {
                            targetPage = '/admin/article/list/all';
                        } else {
                            targetPage = `/admin/article/edit/info/${this.$route.params.id}`;
                            // 重新取得文章基本資料
                            this.$store.dispatch('adminArticle/getArticleBasicInfo', this.articleBasicInfoParams);
                        }
                        setTimeout(() => {
                            this.$router.push(targetPage).catch(() => {});
                        }, 100);
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
    }
}
