<template>
    <div
        class="lms-content"
        :style="{
            backgroundColor: $store.state.common.isAdminPage ? '#f9f9f9' : ''
        }">
        <Header v-if="!isAppWebview && isShowHeader" />
        <div class="lms-body">
            <slot v-if="isSetUserInfoReady" />
            <PageLoader v-else />
        </div>
        <Footer v-if="!isAppWebview && isShowFooter" />
        <Alert v-if="$store.state.common.isShowAlert" />
    </div>
</template>

<script>
import axios from 'axios'

let axiosInstance = axios.create();  // 建立實體

const Header = resolve => require(["@/components/header.vue"], resolve)
const Footer = resolve => require(["@/components/footer.vue"], resolve)
// common
const Alert = resolve => require(["@/components/common/alert.vue"], resolve)

export default {
    name: 'LayoutDefault',
    data: function() {
        return {
            uuid: this.createHash(20),
            isSetDataReady: {
                nowFirstPage: false,
                firstStartRecord: false
            },
        }
    },
    watch: {
        '$route.path'(url, oldurl) {
            if (oldurl && !this.isSetDataReady.nowFirstPage) {
                // console.log(oldurl)
                this.activityTime('end');
            }
            if (url) {
                // console.log(url)
                this.activityTime('start');
                if(this.isSetDataReady.nowFirstPage) {
                    this.isSetDataReady.firstStartRecord = true;
                }
            }
        },
    },
    components: {
        Header,
        Footer,
        Alert
    },
    computed: {
        isSetUserInfoReady: {
            get() {
                return this.$store.state.common.isSetUserInfoReady;
            },
            set(value) {
                this.$store.commit('common/setIsSetUserInfoReady', value);
            }
        },
        isShowHeader() {
            return this.$store.state.common.isShowHeader;
        },
        isShowFooter() {
            return this.$store.state.common.isShowFooter;
        },
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
    },
    created: function() {
        this.isSetDataReady.nowFirstPage = true;
        this.initLayout();

        // F5, 關閉視窗觸發
        window.onbeforeunload = () => {
            this.activityTime('end');
        }

        // 放大、縮放、切換視窗觸發
        document.onvisibilitychange = () => {
            if (document.visibilityState === "hidden") {
                this.activityTime('end');
            }
            else {
                this.activityTime('start');
            }
        };
        this.isSetDataReady.firstPageLoad = true;
    },
    methods: {
        async initLayout() {
            await new Promise((resolve) => setTimeout(resolve, 300));
            await this.$store.dispatch('common/getUserInfo');
            this.firstStart()
            this.isSetUserInfoReady = true;
        },
        firstStart() { // 因為第一次start是LayiutDefault自己本身載入，需要額外判斷
            this.isSetDataReady.nowFirstPage = false;
            if (!this.isSetDataReady.firstStartRecord) {
                this.activityTime('start');
                this.isSetDataReady.firstStartRecord = true;
            }
        },
        activityTime(status) {
            if (!this.userInfo.userId) {
                return;
            }

            this.uuid = status == 'start' ? this.createHash(20) : this.uuid;

            let params = {
                uuid: this.uuid,
                timestamp: +new Date(),
                user_id: this.userInfo.userId,
                action: status,
                device: 'web',
                user_agent: navigator.userAgent
            }

            axiosInstance.post(process.env.VUE_APP_TIME_SPENDS_URL, params)
                .then()
                .catch(error => {
                    console.log(error)
                });
        },
        createHash(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
              counter += 1;
            }
            return result;
        },
    },
}
</script>

<style lang="scss" scoped>
.lms-content {
    min-height: calc(100 * var(--vh));
    background-color: #fff;
    display: flex;
    flex-direction: column;

    & .lms-body {
        flex: 1;
        position: relative;

        & ::v-deep .full-h-content {
            min-height: calc(100vh - 380px);

            & .__inherit-mh {
                min-height: inherit;
            }
        }
    }
}
</style>
