// common
import Dialogue from "@/components/common/dialogue.vue"

// components
const CourseTableList = resolve => require(["@/components/user/group/_course_table_list.vue"], resolve)
const StudentTableList = resolve => require(["@/components/user/group/_student_table_list.vue"], resolve)
export default {
    props: ['originalCourseName', 'isAdmin'],
    components: {
        Dialogue,
        //AssignCourse
        CourseTableList,
        StudentTableList
    },
    data: function() {
        return {
            className: this.originalCourseName,
            oldClassName: this.originalCourseName,
            inputGroupCode: '',
            inputGroupName: '',
            inputStudentAccount: '',
            allCourseIds: [],
            renderCourseListCount: 0,
            activeTab: 'school',
            allStudentIds: [],
            renderStudentListCount: 0,
            classNameList: [],
            selectedClassName: '', // 儲存班級所選名稱，已讓再次讀取同學校學生清單component，可以記住目前所選的班級名稱

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        tabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'school',
                    text: '全校名單列表'
                },
                {
                    value: 'account',
                    text: '自行輸入帳號'
                }
            ]
            return tabs;
        },
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        // 群組列表相關
        isEditGroupName: {
            get() {
                return this.$store.state.user.isEditGroupName;
            },
            set(value) {
                this.$store.commit('user/setIsEditGroupName', value);
            }
        },
        isDeleteGroup: {
            get() {
                return this.$store.state.user.isDeleteGroup;
            },
            set(value) {
                this.$store.commit('user/setIsDeleteGroup', value);
            }
        },
        isGroupSave: {
            get() {
                return this.$store.state.user.isGroupSave;
            },
            set(value) {
                this.$store.commit('user/setIsGroupSave', value);
            }
        },
        isGroupAssign: {
            get() {
                return this.$store.state.user.isGroupAssign;
            },
            set(value) {
                this.$store.commit('user/setIsGroupAssign', value);
            }
        },
        groupData: {
            get() {
                return this.$store.state.user.updateGroupData;
            },
            set(value) {
                this.$store.commit('user/setUpdateGroupListData', value);
            }
        },
        disableGroupEdit() {
            return this.groupData && this.groupData.groupList.length > 0 ? false : true;
        },
        disableNoGroupIds() {
            return this.groupData && this.groupData.groupIds.length > 0 ? false : true;
        },
        // 成員列表相關
        isUserEdit: {
            get() {
                return this.$store.state.user.isUserEdit;
            },
            set(value) {
                this.$store.commit('user/setIsUserEdit', value);
            }
        },
        isUserSave: {
            get() {
                return this.$store.state.user.isUserSave;
            },
            set(value) {
                this.$store.commit('user/setIsUserSave', value);
            }
        },
        isUserDelete: {
            get() {
                return this.$store.state.user.isUserDelete;
            },
            set(value) {
                this.$store.commit('user/setIsUserDelete', value);
            }
        },
        isUserAssign: {
            get() {
                return this.$store.state.user.isUserAssign;
            },
            set(value) {
                this.$store.commit('user/setIsUserAssign', value);
            }
        },
        userData: {
            get() {
                return this.$store.state.user.updateUserData;
            },
            set(value) {
                this.$store.commit('user/setUpdateUserListData', value);
            }
        },
        oidcStudentIds: {
            get() {
                return this.$store.state.user.oidcStudentIds;
            },
            set(value) {
                this.$store.commit('user/setOidcStudentIds', value);
            }
        },
        disableUserEdit() {
            return this.userData && this.userData.modifyUserList.length > 0 ? false : true;
        },
        disableUser() {
            return this.userData && this.userData.userList.length > 0 ? false : true;
        },
        courseIds() {
            let courseData = this.$store.state.user.updateCourseData;
            return courseData && courseData.courseIds.length > 0 ? courseData.courseIds : [];
        },
    },
    methods: {
        reloadTable() {
            this.$parent.getInitialData();
        },
        changeTab(val) {
            this.activeTab = val;
        },
        showJoinGroupDialogue() {
            this.inputGroupCode = '';
            $('#joinGroupDialogue').modal('show');
        },
        joinGroup() {
            let params = {
                passcode: this.inputGroupCode
            }

            this.$httpRequest.post('/api/group/join_group', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        $('#joinGroupDialogue').modal('hide');
                        this.reloadTable();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        showAddGroupDialogue() {
            this.inputGroupName = '';
            this.inputStudentAccount = '';
            this.activeTab = 'school';
            this.studentData = null;
            this.oidcStudentIds = [];
            this.getStuedntList();
            $('#addGroupDialogue').modal('show');
        },
        getStuedntList(name) {
            return new Promise((resolve, reject) => {
                this.selectedClassName = name; // 把子component的班級名稱再補進來
                let params = {
                    class: name
                }

                this.$httpRequest.get('/api/group/get_school_student_and_class', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allStudentIds = result.studentIds;
                                this.classNameList = result.class;
                                this.renderStudentListCount++;
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        addGroup() {
            let params = {
                name: this.inputGroupName,
                members: this.inputStudentAccount,
                userIds: this.oidcStudentIds
            }

            this.$httpRequest.post('/api/group/create_group', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addGroupDialogue').modal('hide');
                            this.reloadTable();
                            this.$router.push('/user/group/' + result);
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        showDeleteGroupDialogue() {
            $('#deleteGroupDialogue').modal('show');
        },
        deleteGroup() {
            let params = {
                groupIds: this.groupData.groupIds
            }
            this.$httpRequest.post('/api/group/delete_groups', params)
                .then(response => {
                    $('#deleteGroupDialogue').modal('hide');
                    if (response.data.state == 'OK') {
                        this.$parent.$children[1].deleteGroups(this.groupData.groupIds);
                        this.groupData.groupIds = [];
                        this.groupData.groupList = [];
                        this.resetStatus('group', 'deleteGroup');
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        getCourseList() {
            return new Promise((resolve, reject) => {
                this.$httpRequest.get('/api/group/get_teacher_course_ids')
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.allCourseIds = result;
                                this.renderCourseListCount++;
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        removeSelected(type) {
            if ((type == 'groupDelete' || type == 'group') && !this.disableNoGroupIds) {
                this.groupData.groupIds = [];
            }
            if (type == 'user' || type =='userDelete' && !this.disableUser) {
                this.userData.userList = [];
            }
        },
        showAssignCourseDialogue() {
            $('#assignCourseDialogue').modal('show');
            this.getCourseList();
        },
        assignToCourse() {
            if (this.courseIds.length == 0) {
                return;
            }

            let params = {
                courseIds: this.courseIds
            },
            apiUrl = '',
            page = '';

            if (this.groupData && this.groupData.groupIds.length > 0) {
                params.groupIds = this.groupData.groupIds;
                apiUrl = 'assign_groups_to_course';
                page= 'group';
            }

            if (this.userData && this.userData.userList.length > 0) {
                params.groupId = this.$route.params.groupId;
                params.userIds = [];
                this.userData.userList.forEach(el => {
                    params.userIds.push(el.id);
                })
                apiUrl = 'assign_members_to_course';
                page= 'user';
            }

            this.$httpRequest.post('/api/group/' + apiUrl, params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        $('#assignCourseDialogue').modal('hide');
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        if (page == 'group') {
                            this.resetStatus(page, 'assign');
                        }
                        if (page == 'user') {
                            this.userData.userList = [];
                            this.resetStatus(page, 'assign');
                        }
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        saveEdit() {
            if (this.groupData.groupList.length == 0) {
                return;
            }

            let params = {
                    groups: this.groupData.groupList
                };

            this.isGroupSave = true;
            this.$httpRequest.post('/api/group/update_groups', params)
                .then(response => {
                    this.isGroupSave = false;
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        this.resetStatus('group', 'edit');
                        this.groupData.groupList = [];
                    }
                })
                .catch(error => {
                    this.isGroupSave = false;
                    console.log('catched error: ' + error);
                });
        },
        showAddUserDialogue() {
            this.inputStudentAccount = '';
            this.activeTab = 'school';
            this.studentData = null;
            this.oidcStudentIds = [];
            this.getStuedntList();
            $('#addUserDialogue').modal('show');
        },
        addUser() {
            if (!this.$route.params.groupId) {
                return;
            }

            let params = {
                groupId: this.$route.params.groupId,
                members: this.inputStudentAccount,
                userIds: this.oidcStudentIds
            }

            this.$httpRequest.post('/api/group/add_members', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addUserDialogue').modal('hide');
                            this.reloadTable();
                        } else {
                            this.$store.dispatch('common/setAlert', { msg: '你新增的帳號可能已重複或不正確，請檢查後再新增', status: 'success' });
                            console.log("user/group/_title.js: 'addUser' get error");
                        }
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        saveUserEdit() {
            let params = {
                    group: {
                        id: this.$route.params.groupId,
                        name: this.className
                    },
                    members: this.userData && this.userData.modifyUserList ? this.userData.modifyUserList : []
                };

            this.isUserSave = true;
            this.$httpRequest.post('/api/group/update_members', params)
                .then(response => {
                    this.isUserSave = false;
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        this.resetStatus('user', 'edit');
                        this.userData.modifyUserList = [];
                        this.oldClassName = this.className;
                    }
                })
                .catch(error => {
                    this.isUserSave = false;
                    console.log('catched error: ' + error);
                });
        },
        showDeleteUserDialogue() {
            $('#deleteUserDialogue').modal('show');
            console.log(this.userData);
        },
        deleteUser() {
            if (!this.$route.params.groupId || this.userData.userList.length == 0) {
                return;
            }

            let userIds = [];
            this.userData.userList.forEach(el => {
                userIds.push(el.id);
            });

            let params = {
                groupId: this.$route.params.groupId,
                userIds: userIds
            }

            this.$httpRequest.post('/api/group/remove_members', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#deleteUserDialogue').modal('hide');
                            this.reloadTable();
                            this.userData.userList = [];
                            this.resetStatus('user', 'delete');
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        resetStatus(page, type) {
            // page是指title.vue現在在哪一頁
            if (page == 'group') {
                if (type == 'all') {
                    this.isEditGroupName = false;
                    this.isGroupDelete = false;
                    this.isGroupAssign = false;
                }
                if (type == 'edit') {
                    this.isEditGroupName = false;
                }
                if (type == 'deleteGroup') {
                    this.isDeleteGroup = false;
                }
                if (type == 'assign') {
                    this.isGroupAssign = false;
                }
            }
            if (page == 'user') {
                if (type == 'all') {
                    this.isUserEdit = false;
                    this.isUserDelete = false;
                    this.isUserAssign = false;
                }
                if (type == 'edit') {
                    this.isUserEdit = false;
                }
                if (type == 'delete') {
                    this.isUserDelete = false;
                }
                if (type == 'assign') {
                    this.isUserAssign = false;
                }
            }
        }
    }
}
