import Layout from "@/layouts"

// components
const Orders = resolve => require(["@/components/order/orders.vue"], resolve)
const Cart = resolve => require(["@/components/order/cart.vue"], resolve)
const GiftOrders = resolve => require(["@/components/order/gift_orders.vue"], resolve)
const Checkout = resolve => require(["@/components/order/checkout.vue"], resolve)
const CheckoutPayment = resolve => require(["@/components/order/checkout/payment.vue"], resolve)
const CheckoutState = resolve => require(["@/components/order/checkout/state.vue"], resolve)

export default {
    name: 'Order',
    components: {
        Layout,
        Orders,
        Cart,
        GiftOrders,
        Checkout,
        CheckoutPayment,
        CheckoutState
    },
    watch: {
        userInfo(newValue) {
            // 已登入->未登入
            if (this.$_.isEmpty(newValue)) {
                this.$router.push('/').catch(() => {});
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        isAppWebview() {
            return this.$store.state.common.isAndroidWebview || this.$store.state.common.isiOSWebview;
        },
        isGiftOrder: {
            get() {
                return this.$store.state.order.isGiftOrder;
            },
            set(value) {
                this.$store.commit('order/setIsGiftOrder', value);
            }
        },
        currentOrderItem() {
            return this.$store.state.order.currentOrderItem;
        },
        currentGiftOrderItems() {
            return this.$store.state.order.currentGiftOrderItems;
        },
        componentType() {
            // 填寫付款資訊頁面
            if (this.$route.name.includes('OrderCheckout')) {
                // 金流支付頁面
                if (this.$route.params.page === 'payment') {
                    return CheckoutPayment;
                }
                // 付款成功/失敗頁面
                if (this.$route.params.state) {
                    return CheckoutState;
                }
                return Checkout;
            }

            // 依據 orderIndex 頁面決定是否為贈送訂單
            this.isGiftOrder = this.$route.params.orderIndex === 'gift-orders';

            // 若需要使用 localStorage 進行頁面導向時, 則不需先載入畫面
            if (this.isRedirectByLocalStorage) {
                return;
            }

            // 購買商品列表與選擇優惠券頁面
            switch (this.$route.params.orderIndex) {
                // 訂單內容 (訂閱制)
                case 'orders':
                    return Orders;
                // 購物車 (課程制)
                case 'cart':
                    return Cart;
                // 贈送內容 (訂閱/課程制)
                case 'gift-orders':
                    return GiftOrders;
            }
        },
        isRedirectByLocalStorage() {
            // 透過 localStorage 進行頁面導向處理
            // 1. 非 Webview
            // 2. 目前 '訂單內容' 沒有商品
            // 3. 目前 '贈送內容' 沒有商品
            // 4. 非購物車(cart)頁面  ([e.g.] /orders, /gift-orders)
            // 5. 非填寫付款資訊(checkout)的子頁面  ([e.g.] /checkout/payment, /checkout/{orderNo}/{success|fail})

            return !this.isAppWebview &&
                    this.$_.isEmpty(this.currentOrderItem) &&
                    !this.currentGiftOrderItems.length &&
                    this.$route.params.orderIndex !== 'cart' &&
                    !this.$route.name.includes('OrderCheckoutChildren');
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        // 使用者尚未登入時, 無法進入購買流程頁面
        if (this.$_.isEmpty(this.userInfo)) {
            if (!this.isAppWebview) {
                this.$router.push('/').catch(() => {});
                $('#loginDialogue').modal('show');
            }
        } else {
            this.initial();
        }
    },
    beforeDestroy: function() {
        // 初始訂單商品相關變數
        this.$store.commit('order/setCurrentOrderItem', {});
        this.$store.commit('order/setCurrentGiftOrderItems', []);
    },
    methods: {
        initial() {
            // 贈送訂單相關變數
            this.$store.commit('order/setIsCheckToRenewOrder', true);
            // 訂單商品相關變數
            this.$store.commit('order/setOrderItemCourseIds', []);
            // 我的優惠券相關變數
            this.$store.commit('user/setCouponsCanUseInfo', {});
            // 優惠券選取相關變數
            this.$store.commit('order/setSelectedCoupon', {});
            this.$store.commit('order/setConfirmSelectedCoupon', {});
            // 結帳與金流支付相關變數
            this.$store.commit('order/setCurrentSubTotal', 0);
            this.$store.commit('order/setCurrentTotalAmount', 0);
            // 付款結果相關變數
            this.$store.commit('order/setFinishedOrderItemList', []);

            if (this.isRedirectByLocalStorage) {
                // 若為購物車->填寫付款資訊頁面, 則重整需回到購物車頁面
                if (this.$route.name === 'OrderCheckout') {
                    if (localStorage.getItem('fromCart') === '1') {
                        localStorage.removeItem('fromCart');
                        this.$router.push('/cart').catch(() => {});
                        return;
                    }
                }

                // 如果 localStorage 中有原導向到訂單內容頁面的課程 id 時, 則重整後需導回該課程首頁
                let redirectCourseId = localStorage.getItem('fromCourse');
                if (redirectCourseId && redirectCourseId !== '0') {
                    localStorage.removeItem('fromCourse');
                    this.$router.push(`/course/${redirectCourseId}`).catch(() => {});
                } else {
                    this.$router.push('/').catch(() => {});
                }
            }
        }
    }
}
