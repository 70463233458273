import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const Sidebar = resolve => require(["@/components/admin/buying_bundles/_sidebar.vue"], resolve)
const TableList = resolve => require(["@/components/admin/buying_bundles/list/_table_list.vue"], resolve)

export default {
    components: {
        DatePicker,
        Dialogue,
        Sidebar,
        TableList
    },
    data: function() {
        return {
            activePage: null,

            allBuyingBundlesIds: [],
            searchText: '',
            renderTableListKey: 0,

            // 新增合購pop up的參數
            newName: '',
            buyingBundles1: {
                id: '',
                name: '',
                price: 0,
                showCourseName: false
            },
            buyingBundles2: {
                id: '',
                name: '',
                price: 0,
                showCourseName: false
            },
            discount: 0,
            endDate: '',

            isPostingApi: {
                addBuyingBundles: false  // 新增合購
            }
        }
    },
    computed: {
        sidebarList() {
            let list = [];

            list = [
                {
                    name: '全部',
                    state: 'all',
                    url: '/admin/buying-bundles/list/all',
                    isShow: true
                }
            ]

            list.forEach(el => {
                el.isActive = el.url == this.$route.path;
            });
            list = list.filter(item => item.isShow);

            return list;
        },
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminBuyingBundles.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminBuyingBundles/setIsClickSidebarLink', value);
            }
        },
        isAddBuyingBundlesDisable() { // 檢查合購參數是否有沒填的
            if (!this.newName
                || !this.buyingBundles1.showCourseName
                || !this.buyingBundles2.showCourseName
                || !this.discount) {
                    return true;
                }
            return false;
        }
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getBuyingBundlesIdsByState();
    },
    mounted: function() {
        // 彈窗打開後直接 focus 輸入框
        $('#addBuyingBundlesDialogue').on('shown.bs.modal', () => {  // 新增合購
            $('#addBuyingBundlesInput').focus();
        });
    },
    methods: {
        getActivePage() {
            return new Promise((resolve, reject) => {
                this.activePage = this.sidebarList.find(el => el.isActive);
                if (this.activePage) {
                    resolve();
                } else {
                    reject("admin/buying_bundles/_list.js: 'getActivePage' get error");
                }
            });
        },
        async getBuyingBundlesIdsByState() {
            await this.getActivePage();

            let params = {
                state: this.activePage.state
            }

            this.allBuyingBundlesIds = [];
            this.searchText = '';

            this.$httpRequest.get('/admin_api/course_bundle/get_course_bundle_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allBuyingBundlesIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        getBuyingBundlesIdsBySearchText() {
            if (!this.searchText) {
                this.getBuyingBundlesIdsByState();
                return;
            }

            let params = {
                state: this.activePage.state,
                text: this.searchText
            }

            this.allBuyingBundlesIds = [];

            this.$httpRequest.post('/admin_api/course_bundle/search_course_bundle_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allBuyingBundlesIds = result;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        checkLength() { // 檢查名稱是否大於12個字元
            if (this.newName.length > 12) {
                this.newName = this.newName.substring(0, 12);
            }
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        checkCourseStatus(id, num) { // 檢查課程是否可以合購
            if (!id) {
                return;
            }

            if (this.buyingBundles1.id == this.buyingBundles2.id) {
                this.$store.dispatch('common/setAlert', { msg: '輸入的課程ID不能與另一個一樣', status: 'danger' });
                return;
            }

            let params = {
                courseId: id
            }

            this.$httpRequest.get('/admin_api/course_bundle/check_course_state', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (num == 1) {
                                this.buyingBundles1.name = result.name;
                                this.buyingBundles1.price = parseInt(result.price);
                                this.buyingBundles1.showCourseName = true;
                            }

                            if (num == 2) {
                                this.buyingBundles2.name = result.name;
                                this.buyingBundles2.price = parseInt(result.price);
                                this.buyingBundles2.showCourseName = true;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });

        },
        checkDiscountNum(num) {
            num = this.checkIsNumber(num);
            // 如果折扣金額大於等於兩個課程加起來的價格，回傳0
            if (num >= (this.buyingBundles1.price + this.buyingBundles2.price)) {
                return 0;
            }
            return num;
        },
        deleteBindCourse(num) {
            if (num == 1) {
                this.buyingBundles1 = {
                    id: '',
                    name: '',
                    price: 0,
                    showCourseName: false
                };
            }
            if (num == 2) {
                this.buyingBundles2 = {
                    id: '',
                    name: '',
                    price: 0,
                    showCourseName: false
                };
            }
        },
        disableDate(date) {
            // disable小於明天以前的日期的結束時間
            let nextDate = new Date();
            nextDate.setDate(new Date().getDate() + 1);
            return date < nextDate.setHours(0, 0, 0, 0);
        },
        disableTime(date) {
            // disable小於目前時間(時)
            return date <= new Date();
        },
        showAddBuyingBundlesDialogue() {
            this.newName = '',
            this.buyingBundles1 = {
                id: '',
                name: '',
                price: 0,
                showCourseName: false
            };
            this.buyingBundles2 = {
                id: '',
                name: '',
                price: 0,
                showCourseName: false
            };
            this.discount = 0;
            $('#addBuyingBundlesDialogue').modal('show');
        },
        addBuyingBundles() {
            if (this.isAddBuyingBundlesDisable || this.isPostingApi.addBuyingBundles) {
                return;
            }

            let params = {
                courseBundleInfo: {
                    name: this.newName,
                    courseIds: [this.buyingBundles1.id, this.buyingBundles2.id],
                    discount: this.discount,
                    endDate: null //this.endDate ? this.$util.datetimeToUnixTimestamp(this.endDate) : null
                }
            }

            this.isPostingApi.addBuyingBundles = true;

            this.$httpRequest.post('/admin_api/course_bundle/add_course_bundle', params)
                .then(response => {
                    this.isPostingApi.addBuyingBundles = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            $('#addBuyingBundlesDialogue').modal('hide');
                            this.getBuyingBundlesIdsByState();
                            this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        }
    }
}
