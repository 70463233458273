import Layout from "@/layouts"

// components
const zhTW = resolve => require(["@/components/privacy/zh-TW.vue"], resolve)

export default {
    name: 'Privacy',
    components: {
        Layout,
        zhTW
    },
    computed: {
        layout() {
            return this.$store.state.common.layout;
        },
        componentType() {
            switch (this.$store.state.common.lang) {
                case 'zh-TW':
                    return zhTW;
                default:
                    return zhTW;
            }
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);
    }
}
