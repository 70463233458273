export default {
    props: {
        id: {
            type: String,
            default: 'sampleDialogue'
        },
        size: {
            type: [String, Number],
            default: 'sm'
        },
        tabletSize: {  // 768 ~ 991
            type: Number,
            default: 500
        },
        mobileSize: {  // < 768
            type: String,
            default: ''
        },
        confirmBtnColor: {
            type: String,
            default: 'orange'
        },
        isModalBodyPaddLess: {
            type: Boolean,
            default: false
        },
        isTitleCenter: {
            type: Boolean,
            default: false
        },
        closeBtnType: {
            type: String,
            default: 'inner'  // inner | outer
        },
        isInnerCloseBtnWhite: {
            type: Boolean,
            default: false
        },
        isDisabledConfirm: {
            type: Boolean,
            default: false
        },
        isShowArrowLeftBtn: {
            type: Boolean,
            default: false
        },
        isShowArrowRightBtn: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            sizeOptions: ['sm', 'md', 'lg'],
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        isAdminPage() {
            return this.$store.state.common.isAdminPage;
        },
        modalDialogueSize() {
            switch (this.size) {
                case 'sm':
                    return 'modal-sm';
                case 'md':
                    return 'modal-md';
                case 'lg':
                    return 'modal-lg';
                default:
                    return '';
            }
        },
        modalDialogueStyle() {
            if (!this.sizeOptions.includes(this.size)) {
                if (this.windowWidth >= 992) {
                    return {
                        maxWidth: this.size + 'px'
                    }
                }
                if (this.windowWidth >= 768 && this.windowWidth < 992) {
                    return {
                        maxWidth: this.tabletSize + 'px'
                    }
                }
                if (this.windowWidth < 768 && this.mobileSize) {
                    return {
                        maxWidth: this.mobileSize + 'px',
                        margin: '.5rem auto'
                    }
                }
            }
            return '';
        },
        btnHeightClassStyle() {
            return this.isAdminPage ? 'h-32' : 'h-40';
        }
    },
    mounted: function() {
        this.$emit('load');
    }
}
