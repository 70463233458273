// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const PublishedTableList = resolve => require(["@/components/admin/push_notification/announcement/_published_table_list.vue"], resolve)
const ScheduledTableList = resolve => require(["@/components/admin/push_notification/announcement/_scheduled_table_list.vue"], resolve)
const UnpublishedTableList = resolve => require(["@/components/admin/push_notification/announcement/_unpublished_table_list.vue"], resolve)
const EditAnnouncementDialogue = resolve => require(["@/components/admin/push_notification/announcement/_edit_announcement_dialogue.vue"], resolve)

export default {
    components: {
        Dialogue,
        PublishedTableList,
        ScheduledTableList,
        UnpublishedTableList,
        EditAnnouncementDialogue
    },
    data: function() {
        return {
            allAnnouncementIds: {},

            publishingItem: {},  // 準備上架的橫幅
            unpublishingItem: {},  // 準備下架的橫幅
            deletingItem: null,  // 準備刪除的公告
            deletedFromState: '',  // 從哪個狀態列表刪除公告 (scheduled: 排程 ; unpublished: 下架)

            renderTableListKey: 0,

            isSetDataReady: {
                getAllAnnouncementIds: false,  // 取得各狀態公告 ids
            },
            isPostingApi: {
                handleAnnouncement: false,  // 上架/下架/刪除公告
                deleteAllAnnouncements: false,  // 刪除所有下架公告
            }
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "state",
                    itemText: "公告狀態",
                    ownClass: "announcement-state",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "公告ID",
                    ownClass: "announcement-id",
                    isShow: true
                },
                {
                    itemName: "publishStart",
                    itemText: "起始時間",
                    ownClass: "announcement-publish-start",
                    isShow: true
                },
                {
                    itemName: "publishEnd",
                    itemText: "結束時間",
                    ownClass: "announcement-publish-end",
                    isShow: true
                },
                {
                    itemName: "title",
                    itemText: "公告標題",
                    ownClass: "announcement-title",
                    isShow: true
                },
                {
                    itemName: "advanced",
                    itemText: "進階",
                    ownClass: "announcement-advanced",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        newPublishedItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.newPublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setNewPublishedItem', value);
            }
        },
        newUnpublishedItem: {
            get() {
                return this.$store.state.adminPnAnnouncement.newUnpublishedItem;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setNewUnpublishedItem', value);
            }
        },
        deletedPublishedId: {
            get() {
                return this.$store.state.adminPnAnnouncement.deletedPublishedId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setDeletedPublishedId', value);
            }
        },
        deletedScheduledId: {
            get() {
                return this.$store.state.adminPnAnnouncement.deletedScheduledId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setDeletedScheduledId', value);
            }
        },
        deletedUnpublishedId: {
            get() {
                return this.$store.state.adminPnAnnouncement.deletedUnpublishedId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setDeletedUnpublishedId', value);
            }
        },
        isDeleteAllAnnouncements: {
            get() {
                return this.$store.state.adminPnAnnouncement.isDeleteAllAnnouncements;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setIsDeleteAllAnnouncements', value);
            }
        },
        editingAnnouncementId: {
            get() {
                return this.$store.state.adminPnAnnouncement.editingAnnouncementId;
            },
            set(value) {
                this.$store.commit('adminPnAnnouncement/setEditingAnnouncementId', value);
            }
        }
    },
    created: function() {
        this.initial();
        this.getAllAnnouncementIds();
    },
    methods: {
        // 初始公告所有設定值
        initial() {
            // 列表相關變數
            this.$store.commit('adminPnAnnouncement/setNewPublishedItem', {});
            this.$store.commit('adminPnAnnouncement/setNewScheduledItem', {});
            this.$store.commit('adminPnAnnouncement/setNewUnpublishedItem', {});
            this.$store.commit('adminPnAnnouncement/setEditedScheduledItem', {});
            this.$store.commit('adminPnAnnouncement/setDeletedPublishedId', null);
            this.$store.commit('adminPnAnnouncement/setDeletedScheduledId', null);
            this.$store.commit('adminPnAnnouncement/setDeletedUnpublishedId', null);
            this.$store.commit('adminPnAnnouncement/setIsDeleteAllAnnouncements', false);

            // 新增或編輯公告相關變數
            this.$store.commit('adminPnAnnouncement/setEditingAnnouncementId', null);
            this.$store.commit('adminPnAnnouncement/setLinkedResourceInfo', {});
        },

        // 取得所有狀態的公告資訊
        getAllAnnouncementIds() {
            this.$httpRequest.get('/admin_api/announcement/get_announcement_list_ids')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            let states = {
                                published: result.on,
                                scheduled: result.schedule,
                                unpublished: result.off
                            }
                            this.allAnnouncementIds = states;

                            this.isSetDataReady.getAllAnnouncementIds = true;
                            this.renderTableListKey++;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },

        // 新增/編輯公告
        editAnnouncement(id) {
            this.editingAnnouncementId = id;
            $('#editAnnouncementDialogue').modal('show');
        },

        // 上架/下架/刪除公告
        openPublishPromptDialogue(item) {
            this.publishingItem = item;
            $('#publishAnnouncementPromptDialogue').modal('show');
        },
        openUnpublishPromptDialogue(item) {
            this.unpublishingItem = item;
            $('#unpublishAnnouncementPromptDialogue').modal('show');
        },
        openDeletePromptDialogue(item, state) {
            this.deletingItem = item;
            this.deletedFromState = state;
            $('#deleteAnnouncementPromptDialogue').modal('show');
        },
        handleAnnouncement(item, event) {
            let params = {
                announcementId: item.id,
                event: event
            }

            this.isPostingApi.handleAnnouncement = true;

            this.$httpRequest.post('/admin_api/announcement/handle_announcement', params)
                .then(response => {
                    this.isPostingApi.handleAnnouncement = false;

                    if (response.data.state == 'OK') {
                        switch (event) {
                            // 上架
                            case 'on':
                                item.publishStart = Math.floor(Date.now() / 1000);  // 起始時間改為目前時間
                                this.newPublishedItem = item;
                                this.deletedScheduledId = item.id;
                                $('#publishAnnouncementPromptDialogue').modal('hide');
                                break;
                            // 下架
                            case 'off':
                                item.publishEnd = Math.floor(Date.now() / 1000);  // 結束時間改為目前時間
                                this.newUnpublishedItem = item;
                                this.deletedPublishedId = item.id;
                                $('#unpublishAnnouncementPromptDialogue').modal('hide');
                                break;
                            // 刪除
                            case 'delete':
                                if (this.deletedFromState === 'scheduled') {
                                    this.deletedScheduledId = item.id;
                                }
                                if (this.deletedFromState === 'unpublished') {
                                    this.deletedUnpublishedId = item.id;
                                }
                                $('#deleteAnnouncementPromptDialogue').modal('hide');
                                break;
                        }

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });
                    }
                })
                .catch(error => {
                    this.isPostingApi.handleAnnouncement = false;
                    console.log('catched error: ' + error);
                });
        },

        // 刪除所有下架公告
        openDeleteAllPromptDialogue() {
            $('#deleteAllAnnouncementsPromptDialogue').modal('show');
        },
        deleteAllAnnouncements() {
            this.isPostingApi.deleteAllAnnouncements = true;

            this.$httpRequest.get('/admin_api/announcement/delete_all_off_announcement')
                .then(response => {
                    this.isPostingApi.deleteAllAnnouncements = false;

                    if (response.data.state == 'OK') {
                        this.allAnnouncementIds.unpublished = [];
                        this.isDeleteAllAnnouncements = true;

                        this.$store.dispatch('common/setAlert', { status: 'success', msg: response.data.msg });

                        $('#deleteAllAnnouncementsPromptDialogue').modal('hide');
                    }
                })
                .catch(error => {
                    this.isPostingApi.deleteAllAnnouncements = false;
                    console.log('catched error: ' + error);
                });
        }
    }
}
