import Layout from "@/layouts"

export default {
    name: 'NotFound',
    components: {
        Layout
    },
    computed: {
        layout() {
            return this.$store.state.common.layout;
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);
    }
}
