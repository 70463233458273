// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const ArticleList = resolve => require(["@/components/course/_article_list.vue"], resolve)
const CourseInfo = resolve => require(["@/components/course/index/_course_info.vue"], resolve)
const OperatingBtns = resolve => require(["@/components/course/index/_operating_btns.vue"], resolve)
const SubscribePlans = resolve => require(["@/components/course/index/_subscribe_plans.vue"], resolve)
const BundleInfo = resolve => require(["@/components/course/index/_bundle_info.vue"], resolve)

export default {
    props: ['courseInfo'],
    components: {
        Dialogue,
        ArticleList,
        CourseInfo,
        OperatingBtns,
        SubscribePlans,
        BundleInfo
    },
    data: function() {
        return {
            coverBgHeight: 0,
            activeTab: 'intro',
            initTabDOMTop: null,

            countdownTimer: 0,  // 倒數計時器(秒)
            countdownTimerProcedure: null,  // 倒數計時器的 interval id

            courseBundleInfo: {},

            renderDialoguePlansKey: 0,

            isSetDataReady: {
                getCourseBundleInfo: false,  // 取得合購優惠資訊 (課程制)
            },
            isSavingData: {
                addOrdersItem: false,  // 新增目前課程至訂單內容 (訂閱制)
                addGiftOrderItems: false,  // 新增目前課程或合購課程至贈送內容 (訂閱/課程制)
            },
            isDoingAction: {
                addCourseToOrders: false,  // 新增目前課程至訂單內容 (訂閱制)
                addCourseWithPlanToOrders: false,  // 以訂閱方案新增目前課程至訂單內容 (訂閱制)

                addCourseToCart: false,  // 新增目前課程至購物車 (課程制)
                addCourseToCartAndRedirect: false,  // 新增課程至購物車並導向至購物車頁面 (課程制)
                addBundleCoursesToCart: false,  // 新增合購課程至購物車 (課程制)

                addCourseToGiftOrders: false,  // 新增目前課程至贈送內容 (訂閱/課程制)
                addCourseWithPlanToGiftOrders: false,  // 以訂閱方案新增目前課程至贈送內容 (訂閱制)
                addBundleCoursesToGiftOrders: false,  // 新增合購課程至贈送內容 (課程制)
            },
            isPostingApi: {
                addItemsToCart: false,  // 新增商品至購物車 (課程制)
            }
        }
    },
    watch: {
        windowWidth(newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    $(window).scrollTop(0);
                    this.initTabDOMTop = Math.floor($('.tab-wrapper').offset().top);
                    this.coverBgHeight = $('#coverImage').height();
                });
            }
            if (newValue >= 768 && oldValue < 768) {
                $('#subscribePlansDialogue').modal('hide');
                $('#bundleInfoDialogue').modal('hide');
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        selectedPrice: {
            get() {
                return this.$store.state.course.selectedPrice;
            },
            set(value) {
                this.$store.commit('course/setSelectedPrice', value);
            }
        },
        tabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'intro',
                    text: '課程簡介'
                },
                {
                    value: 'preview',
                    text: '試閱內容'
                },
            ];

            return tabs;
        },
        isShowSubscribePlansInfo() {
            return this.courseInfo.purchaseType === '0' && this.courseInfo.subscribePlans.length;
        },
        isShowCourseBundleInfo() {
            return this.courseInfo.purchaseType === '1' && this.isSetDataReady.getCourseBundleInfo && !this.$_.isEmpty(this.courseBundleInfo);
        },
        maxPlansDiscountPrice() {
            let maxDiscount = 0;

            for (let el of this.courseInfo.subscribePlans) {
                if (el.discountPrice > maxDiscount) {
                    maxDiscount = el.discountPrice;
                }
            }

            return maxDiscount;
        }
    },
    created: function() {
        // 初始選擇的課程售價資訊
        this.initSelectedPrice();

        // 購買課程, 需取得是否有與其他課程的合購優惠資訊
        if (this.courseInfo.purchaseType === '1') {
            this.getCourseBundleInfo();
        }

        // 此課程如果正在進行訂單結帳支付時, 則需依據訂單截止時間進行倒數計時
        if (this.courseInfo.isOrdered) {
            this.initCountdownTimer();
        }

        this.$store.commit('order/setGiftExchangeCount', 1);
    },
    mounted: function() {
        this.initTabDOMTop = Math.floor($('.tab-wrapper').offset().top);
        this.coverBgHeight = $('#coverImage').height();

        // 若從產品介紹點擊課程的免費試看, 則進入畫面時需捲動至 '試閱文章' tab
        if (this.$cookieStore.getCookie('is_scroll_to_pa') === '1') {
            this.changeTab('preview');
            this.$cookieStore.delCookie('is_scroll_to_pa');
        } else {
            // log
            this.$util.addLog({
                type: 'courseIntroduction',
                courseId: this.courseInfo.id
            });
        }

        // 一開啟訂閱方案列表彈窗時
        $('#subscribePlansDialogue').on('show.bs.modal', () => {
            this.renderDialoguePlansKey++;
        });
    },
    beforeDestroy: function() {
        // 清除計時器
        clearInterval(this.countdownTimerProcedure);
        this.countdownTimerProcedure = null;
    },
    methods: {
        changeTab(targetValue) {
            this.activeTab = targetValue;

            // log
            let logType = '';
            switch (this.activeTab) {
                case 'intro':
                    logType = 'courseIntroduction';
                    break;
                case 'preview':
                    logType = 'courseContent';
                    break;
                case 'witness':
                    logType = 'courseWitness';
                    break;
            }
            this.$util.addLog({
                type: logType,
                courseId: this.courseInfo.id
            });

            // smooth scroll
            let scrollTop = $(window).scrollTop();
            let offset = this.windowWidth >= 768 ? 20 : 60;
            if ((scrollTop + offset) == this.initTabDOMTop) {
                return;
            }
            $('html, body').animate({
                scrollTop: this.initTabDOMTop - offset
            }, 600);
        },

        initSelectedPrice() {
            this.selectedPrice = {
                id: '0',
                type: 'price',
                unit: '1',
                price: this.courseInfo.price,
                planName: ''
            }
        },

        showSubscribePlansDialogue() {
            $('#subscribePlansDialogue').modal('show');
        },

        // 課程制 - 取得合購優惠資訊
        getCourseBundleInfo() {
            let tempCourseId = this.courseInfo.id;

            let params = {
                courseIds: [tempCourseId]
            }

            this.$httpRequest.post('/api/course_bundle/get_course_bundle', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result && !this.$_.isEmpty(result)) {
                            let tempCaseId = result.course_correspond[tempCourseId];
                            let tempCaseInfo = result.bundle_case[tempCaseId];

                            if (this.$_.isEmpty(tempCaseInfo)) {
                                return;
                            }

                            let courses = [], coursesTotalPrice = 0;

                            for (let courseId in tempCaseInfo.bundle_courses) {
                                let tempCourse = tempCaseInfo.bundle_courses[courseId];

                                courses.push({
                                    id: courseId,
                                    alias: tempCourse.alias,
                                    name: tempCourse.name,
                                    cover: tempCourse.cover.cover_url,
                                    purchaseType: '1',
                                    suitable: tempCourse.suitable,
                                    difficultyLevel: tempCourse.difficulty_level,
                                    price: +tempCourse.price
                                });

                                coursesTotalPrice += (+tempCourse.price);
                            }

                            this.courseBundleInfo = {
                                id: tempCaseId,
                                name: tempCaseInfo.bundle_name,
                                discount: +tempCaseInfo.bundle_discount,
                                coursesTotalPrice: coursesTotalPrice,
                                isAllInCart: tempCaseInfo.exist_in_cart,  // 是否所有課程都已加入購物車
                                isAnyCoursePurchased: tempCaseInfo.exist_course,  // 是否已購買其中一堂課程
                                courses: courses
                            }

                            this.isSetDataReady.getCourseBundleInfo = true;
                        }
                    }
                })
                .catch(error => {
                    console.log('catched error: ' + error);
                });
        },
        showBundleInfoDialogue() {
            $('#bundleInfoDialogue').modal('show');
        },

        checkUserLogin() {
            if (this.$_.isEmpty(this.$store.state.common.userInfo)) {
                $('#subscribePlansDialogue').modal('hide');
                $('#bundleInfoDialogue').modal('hide');
                $('#loginDialogue').modal('show');
                return false;
            }
            return true;
        },

        // 訂閱制 - 新增目前課程至訂單內容
        async addOrdersItem(isSubscribePlan) {
            if (!this.checkUserLogin() || this.isSavingData.addOrdersItem || this.courseInfo.isOrdered) {
                return;
            }
            if (isSubscribePlan && this.selectedPrice.id === '0') {
                return;
            }

            // 訂閱方案
            if (isSubscribePlan) {
                this.isDoingAction.addCourseWithPlanToOrders = true;
            }
            // 課程售價
            else {
                this.isDoingAction.addCourseToOrders = true;
                this.initSelectedPrice();
            }

            let courseData = {
                id: this.courseInfo.id,
                alias: this.$route.params.id,
                name: this.courseInfo.name,
                cover: this.courseInfo.cover,
                purchaseType: this.courseInfo.purchaseType,
                suitable: this.courseInfo.suitable,
                difficultyLevel: this.courseInfo.difficultyLevel,
                price: this.courseInfo.price
            }

            let payload = {
                courseInfo: courseData,
                selectedPrice: this.selectedPrice
            }

            this.isSavingData.addOrdersItem = true;
            await this.$store.dispatch('order/addOrdersItem', payload);
            this.isSavingData.addOrdersItem = false;

            if (isSubscribePlan) {
                this.isDoingAction.addCourseWithPlanToOrders = false;
            } else {
                this.isDoingAction.addCourseToOrders = false;
            }

            // 寫入來源課程 id 至 localStorage, 以做重整導回該課程首頁需要
            localStorage.removeItem('fromCart');
            localStorage.setItem('fromCourse', this.$route.params.id);
            this.$router.push('/orders');
        },

        // 課程制 - 新增商品至購物車
        async addCourseToCart() {
            if (!this.checkUserLogin() || this.isPostingApi.addItemsToCart) {
                return;
            }

            $('.lms-btn.outline').blur();

            // 將課程加入購物車
            this.isPostingApi.addItemsToCart = true;
            try {
                let isExistBundleCourses = await this.$store.dispatch('order/addItemsToCart', { courseIds: [this.courseInfo.id] });

                this.isDoingAction.addCourseToCart = true;

                setTimeout(() => {  // 因為動畫播放, 所以等待 0.1秒 改變狀態
                    this.$emit('addToCart');
                    this.courseBundleInfo.isAllInCart = isExistBundleCourses[this.courseInfo.id];

                    this.isDoingAction.addCourseToCart = false;
                }, 100);
            } catch (error) {
                console.log('catched error: ', error);
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '新增商品至購物車失敗' });
            }
            this.isPostingApi.addItemsToCart = false;
        },
        async addCourseToCartAndRedirect() {
            if (!this.checkUserLogin() || this.isPostingApi.addItemsToCart) {
                return;
            }

            // 將課程加入購物車
            this.isPostingApi.addItemsToCart = true;
            try {
                let isExistBundleCourses = await this.$store.dispatch('order/addItemsToCart', { courseIds: [this.courseInfo.id] });

                this.isDoingAction.addCourseToCartAndRedirect = true;

                this.$emit('addToCart');
                this.courseBundleInfo.isAllInCart = isExistBundleCourses[this.courseInfo.id];

                this.isDoingAction.addCourseToCartAndRedirect = false;

                // 導向購物車頁面
                this.$router.push('/cart').catch(() => {});
            } catch (error) {
                console.log('catched error: ', error);
                this.$store.dispatch('common/setAlert', { status: 'danger', msg: '新增商品至購物車失敗' });
            }
            this.isPostingApi.addItemsToCart = false;
        },
        async addBundleCoursesToCart(courses) {
            if (!this.checkUserLogin() || this.isPostingApi.addItemsToCart || this.courseInfo.isOrdered) {
                return;
            }

            $('.lms-btn.outline').blur();

            this.isDoingAction.addBundleCoursesToCart = true;

            let courseIds = [];
            for (let el of courses) {
                // 若目前頁面的課程已加入購物車, 則不需再新增至購物車
                if (el.id === this.courseInfo.id && this.courseInfo.isInCart) {
                    continue;
                }
                courseIds.push(el.id);
            }

            // 將課程加入購物車
            this.isPostingApi.addItemsToCart = true;
            await this.$store.dispatch('order/addItemsToCart', { courseIds: courseIds });
            this.isPostingApi.addItemsToCart = false;

            this.$emit('addToCart');
            this.courseBundleInfo.isAllInCart = true;

            this.isDoingAction.addBundleCoursesToCart = false;
        },

        // 訂閱/課程制 - 新增目前課程或合購課程至贈送內容
        // type: 'plan', 'bundle'
        async addGiftOrderItems(type = '') {
            if (!this.checkUserLogin() || this.isSavingData.addGiftOrderItems || this.courseInfo.isOrdered) {
                return;
            }
            if (type === 'plan' && this.selectedPrice.id === '0') {
                return;
            }

            let payload = {
                orderItems: []
            }

            // 以 '合購優惠' 贈送課程 (課程制)
            if (type === 'bundle') {
                this.isDoingAction.addBundleCoursesToGiftOrders = true;

                for (let el of this.courseBundleInfo.courses) {
                    let tempCourseData = {
                        id: el.id,
                        alias: el.alias,
                        name: el.name,
                        cover: el.cover,
                        purchaseType: el.purchaseType,
                        suitable: el.suitable,
                        difficultyLevel: el.difficultyLevel,
                        price: el.price
                    }

                    payload.orderItems.push({
                        courseInfo: tempCourseData
                    });
                }
            }
            // 以 '課程售價' 或 '訂閱方案' 贈送課程 (訂閱/課程制)
            else {
                // 訂閱方案
                if (type === 'plan') {
                    this.isDoingAction.addCourseWithPlanToGiftOrders = true;
                }
                // 課程售價
                else {
                    this.isDoingAction.addCourseToGiftOrders = true;
                    this.initSelectedPrice();
                }

                let courseData = {
                    id: this.courseInfo.id,
                    alias: this.$route.params.id,
                    name: this.courseInfo.name,
                    cover: this.courseInfo.cover,
                    purchaseType: this.courseInfo.purchaseType,
                    suitable: this.courseInfo.suitable,
                    difficultyLevel: this.courseInfo.difficultyLevel,
                    price: this.courseInfo.price
                }

                payload.orderItems.push({
                    courseInfo: courseData,
                    selectedPrice: this.selectedPrice
                });
            }

            this.isSavingData.addGiftOrderItems = true;
            await this.$store.dispatch('order/addGiftOrderItems', payload);
            this.isSavingData.addGiftOrderItems = false;

            if (type === 'bundle') {
                this.isDoingAction.addBundleCoursesToGiftOrders = false;
            } else {
                if (type === 'plan') {
                    this.isDoingAction.addCourseWithPlanToGiftOrders = false;
                } else {
                    this.isDoingAction.addCourseToGiftOrders = false;
                }
            }

            // 寫入來源課程 id 至 localStorage, 以做重整導回該課程首頁需要
            localStorage.removeItem('fromCart');
            localStorage.setItem('fromCourse', this.$route.params.id);
            this.$router.push('/gift-orders');
        },

        initCountdownTimer() {
            this.countdownTimer = Math.floor(this.courseInfo.paymentDeadline - (Date.now() / 1000));
            if (this.countdownTimer < 0) {
                return;
            }

            this.countdownTimerProcedure = setInterval(() => {
                this.countdownTimer--;
                if (this.countdownTimer == 0) {
                    clearInterval(this.countdownTimerProcedure);
                    this.countdownTimerProcedure = null;
                    this.courseInfo.isOrdered = false;
                }
            }, 1000);
        }
    }
}
