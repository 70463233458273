import InfiniteScroll from 'vue-infinite-scroll'

// utils
import searchUtil from '@/assets/js/utils/search'

export default {
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            keyword: '',
            onKeywordInput: this.$node.debounce(this.getMissionIds, 500),
            isSearching: false,  // 是否正在搜尋任務
            isShowSearchBox: false,

            allMIds: [],  // m: mission
            missionList: [],

            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            selectedMIds: [],
            maxMissionsSelected: 25  // 最多可被勾選的任務數量
        }
    },
    watch: {
        keyword() {
            this.isSearching = true;
            this.initialize();
        },
        selectedMIds(newValue) {
            if (newValue) {
                this.$emit('missionSelect', newValue);
            }
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        searchUtil() {
            return searchUtil;
        }
    },
    created: function() {
        this.getMissionIds();
    },
    mounted: function() {
        this.registerDropdownEvents();
    },
    methods: {
        registerDropdownEvents() {
            // 一開啟任務下拉選單時
            $('#missionDropdown').on('show.bs.dropdown', () => {
                if (this.keyword) {
                    this.keyword = '';
                    this.getMissionIds();
                }
            });
            // 一關閉任務下拉選單時
            $('#missionDropdown').on('hide.bs.dropdown', () => {
                if (this.keyword) {
                    document.getElementById('missionList').scrollTop = 0;  // 置頂列表卷軸
                }
            });
        },

        clearKeyword() {
            this.keyword = '';
            this.getMissionIds();
            document.getElementById('missionListKeyword').focus();
        },

        initialize() {
            this.allMIds = [];
            this.missionList = [];
            this.busy = false;
            this.loadingIndex = 0;
            this.loadCountPerPage = 20;
            this.isGetAllList = false;
        },
        getMissionIds() {
            let params = {
                roleType: this.userInfo.permission === 'user' ? 'assigned' : 'created',
            };

            // 有輸入關鍵字
            if (this.keyword) {
                params.keyword = this.keyword;
            }

            this.$httpRequest.get('/api/mission/get_mission_list_ids', params)
                .then(response => {
                    this.isSearching = false;

                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allMIds = result;

                            if (!this.allMIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }

                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    this.isSearching = false;
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            await this.getMissionList();
            this.busy = false;

            if (this.allMIds.length) {
                this.isShowSearchBox = true;
            }
        },
        getMissionList() {
            return new Promise((resolve, reject) => {
                let mIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    const mId = this.allMIds[this.loadingIndex];
                    if (!mId) {
                        this.isGetAllList = true;
                        break;
                    }
                    mIds.push(mId);
                    this.loadingIndex++;
                }

                if (!mIds.length) {
                    resolve();
                    return;
                }

                let params = {
                    missionIds: mIds
                };

                this.$httpRequest.post('/api/mission/get_mission_names', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                for (let mId of params.missionIds) {
                                    let m = result[mId];

                                    if (!m) {
                                        continue;
                                    }

                                    this.missionList.push({
                                        id: mId,
                                        name: m.name
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },

        selectMission(event) {
            const mId = event.target.value;

            if (event.target.checked) {
                if (this.selectedMIds.length === this.maxMissionsSelected) {
                    event.target.checked = false;
                    this.$store.dispatch('common/setAlert', { msg: `最多只能選取${this.maxMissionsSelected}筆任務`, status: 'danger' });
                    return;
                }

                this.selectedMIds.push(mId);
            } else {
                let foundIndex = this.selectedMIds.indexOf(mId);
                this.$delete(this.selectedMIds, foundIndex);
            }
        }
    }
}
