import InfiniteScroll from 'vue-infinite-scroll'

// components
const CourseCard = resolve => require(["@/components/learning_center/components/course_card.vue"], resolve)

export default {
    props: {
        groupId: {
            type: String,
            default: '0',
        },
        keyword: {
            type: String,
            default: '',
        },
        sortType: {
            type: String,
            default: '',
        },
    },
    directives: {
        InfiniteScroll,
    },
    components: {
        CourseCard,
    },
    data: function() {
        return {
            // c: course
            allCIds: [],
            cIds: [],
            cInfos: {},
            pinningCIds: {},
            pinnedCIds: {},

            // load more
            busy: true,  // 是否觸發載入更多
            perPage: 12,  // 一次載入幾筆
            loadingIndex: 0,  // 目前載到所有資料中的第幾個 index
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    computed: {
        courseUtil() {
            return this.$store.state.course.courseUtil;
        },
    },
    created: function() {
        this.initCourseList();
    },
    methods: {
        async initCourseList() {
            await new Promise(resolve => setTimeout(resolve, 200));
            this.getCourseIds();
        },
        getCourseIds() {
            let params = {
                groupId: this.groupId,
            };
            // 關鍵字搜尋
            if (this.keyword) {
                params.keyword = this.keyword;
            }
            // 條件排序
            if (this.sortType) {
                params.sortType = this.sortType;
            }

            this.$httpRequest.get('/api/course/get_my_group_course_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        const result = response.data.result;

                        if (result) {
                            this.allCIds = result;
                            if (!this.allCIds.length) {
                                this.busy = false;
                                this.isGetAllList = true;
                            }
                            this.loadMore();
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        async loadMore() {
            if (this.isGetAllList) {
                return;
            }

            this.busy = true;
            let cIds = [];
            for (let i = 0; i < this.perPage; i++) {
                const cId = this.allCIds[this.loadingIndex];
                if (!cId) {
                    this.isGetAllList = true;
                    break;
                }
                cIds.push(cId);
                this.loadingIndex++;
            }
            await this.getCourseInfos(cIds);
            this.cIds = [...this.cIds, ...cIds];
            this.busy = false;
        },
        getCourseInfos(cIds) {
            return new Promise((resolve, reject) => {
                if (!cIds.length) {
                    resolve();
                    return;
                }

                const params = {
                    courseIds: cIds,
                };

                this.$httpRequest.post('/api/course/get_my_group_course_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            const result = response.data.result;

                            if (result) {
                                for (const cId of params.courseIds) {
                                    const c = result[cId];

                                    if (!c) {
                                        continue;
                                    }

                                    let cInfo = {
                                        alias: c.alias,
                                        name: c.name,
                                        cover: c.cover_url,
                                        updateFrequency: this.courseUtil.getUpdateFrequency(c.update_frequency),
                                        lastPublish: this.$util.unixTimestampToDatetime(c.last_article_published, this.$util.getBrowserCurrentTimeZone(), 'date', true),
                                        applicability: this.courseUtil.getApplicability(c.suitable),
                                        difficultyLevel: this.courseUtil.getDifficultyLevel(c.difficulty_level),
                                        pinned: c.is_pin,
                                        progress: {},
                                        expiredDate: '',
                                    };
                                    // 學生顯示課程完成進度
                                    if (this.$route.query.role === 'student') {
                                        if (c.article.total) {
                                            cInfo.progress.read = Math.round(c.article.read * 100 / c.article.total);
                                        }
                                        if (c.practice.total) {
                                            cInfo.progress.practice = Math.round(c.practice.done * 100 / c.practice.total);
                                        }
                                        const totalDone = c.article.read + c.practice.done;
                                        const totalCount = c.article.total + c.practice.total;
                                        cInfo.progress.total = totalCount ? Math.round(totalDone * 100 / totalCount) : 0;
                                    }
                                    // 老師顯示課程到期時間
                                    else {
                                        cInfo.expiredDate = c.expired_date ? this.$util.unixTimestampToDatetime(c.expired_date, this.$util.getBrowserCurrentTimeZone(), 'date', true) : '0';
                                    }
                                    this.$set(this.cInfos, cId, cInfo);

                                    if (cInfo.pinned) {
                                        this.$set(this.pinnedCIds, cId, 1);
                                    }
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        pinCourse(cId) {
            const params = {
                courseIds: [cId],
                action: this.cInfos[cId].pinned ? 'unpin' : 'pin',
            };

            this.$set(this.pinningCIds, cId, 1);

            this.$httpRequest.post('/api/course/set_course_pin', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.cInfos[cId].pinned = !this.cInfos[cId].pinned;
                        this.$delete(this.pinningCIds, cId);
                        if (this.cInfos[cId].pinned) {
                            this.$set(this.pinnedCIds, cId, 1);
                            // 移動課程至列表第一筆
                            this.cIds.sort((el) => el === cId ? -1 : 0);
                        } else {
                            this.$delete(this.pinnedCIds, cId, 1);
                            // 移動課程至非釘選區第一筆
                            const fromIndex = this.cIds.indexOf(cId);
                            const toIndex = Object.keys(this.pinnedCIds).length;
                            this.cIds.splice(toIndex, 0, this.cIds.splice(fromIndex, 1)[0]);
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        this.$delete(this.pinningCIds, cId);
                    }
                })
                .catch(error => {
                    this.$delete(this.pinningCIds, cId);
                    console.error('Catched Error:', error);
                });
        },
    },
}
