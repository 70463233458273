import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue.vue"
export default {
    props: ['allGroupIds'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            groupList: [],
            oldGroupList: [],
            isCheckedAll: false,
            // 用於離開班級的各種變數
            leftIndex: '',
            leftGroupId: '',
            leftGroupName: '',
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 15,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        isGroupAssign(newValue) {
            if (newValue) {
                this.resetSelected();
            }
        },
        isEditGroupName(newValue) {
            if (newValue == false) { // 只要停止編輯，就重新assign 資料到舊資料
                this.groupList = this.$_.cloneDeep(this.oldGroupList);
            }
        },
        isDeleteGroup(newValue) {
            if (newValue) {
                this.resetSelected();
            }
        },
        isGroupSave(newValue) {
            if (newValue == true) { // 只要儲存編輯，就重新assign 資料到新資料
                this.oldGroupList = this.$_.cloneDeep(this.groupList);
            }
        },
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "check",
                    itemText: "",
                    ownClass: "check",
                    isShowCheckbox: true,
                    isShow: this.isGroupAssign || this.isDeleteGroup
                },
                {
                    itemName: "name",
                    itemText: "班級",
                    ownClass: "name",
                    isEdit: !this.isEditGroupName && !this.isDeleteGroup && !this.isGroupAssign && this.$store.state.common.userInfo.permission != 'user' && this.groupList.length > 0,
                    isShow: true
                },
                {
                    itemName: "amount",
                    itemText: "人數",
                    ownClass: "amount",
                    isShow: true
                },
                {
                    itemName: "creater",
                    itemText: "創建教師",
                    ownClass: "creater",
                    isShow: true
                },
                {
                    itemName: "delete",
                    itemText: "",
                    ownClass: "delete",
                    isShow: !this.isEditGroupName && !this.isGroupAssign && !this.isDeleteGroup
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        isEditGroupName: {
            get() {
                return this.$store.state.user.isEditGroupName;
            },
            set(value) {
                this.$store.commit('user/setIsEditGroupName', value);
            }
        },
        isDeleteGroup: {
            get() {
                return this.$store.state.user.isDeleteGroup;
            },
            set(value) {
                this.$store.commit('user/setIsDeleteGroup', value);
            }
        },
        isGroupSave: {
            get() {
                return this.$store.state.user.isGroupSave;
            },
            set(value) {
                this.$store.commit('user/setIsGroupSave', value);
            }
        },
        isGroupAssign: {
            get() {
                return this.$store.state.user.isGroupAssign;
            },
            set(value) {
                this.$store.commit('user/setIsGroupAssign', value);
            }
        },
        groupData() {
            return this.$store.state.user.updateGroupData;
        },
        updateGroupListData() {
            let updateData = {},
                groupList = [],
                groupIds = [];

            this.groupList.forEach((el, index) => {
                if (el.name != this.oldGroupList[index].name) {
                    groupList.push(el);
                }
                if (el.isChecked == true) {
                    groupList.push(el);
                    groupIds.push(el.id);
                }
            });

            if (groupList) {
                updateData.groupList = groupList;
            }

            if (groupIds) {
                updateData.groupIds = groupIds;
                updateData.type = 'group';
            }

            return updateData;
        }
    },
    created: function() {
        if (this.allGroupIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getGroupList()
                    .then(() => {
                        this.busy = false;
                        // 監聽編輯內容是否更動
                        this.$watch('updateGroupListData', newValue => {
                            this.$store.commit('user/setUpdateGroupListData', newValue);
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        getGroupList() {
            return new Promise((resolve, reject) => {
                let groupIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempGroupId = this.allGroupIds[this.loadingIndex];
                    if (!tempGroupId) {
                        this.isGetAllList = true;
                        break;
                    }
                    groupIds.push(tempGroupId);
                    this.loadingIndex++;
                }

                if (groupIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    groupIds: groupIds
                }

                this.$httpRequest.post('/api/group/get_group_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    groupIds.forEach(id => {
                                        let tempGroup = result[id];
                                        if (tempGroup) {
                                            this.groupList.push({
                                                id: tempGroup.id,
                                                isChecked: false,
                                                name: tempGroup.name,
                                                amount: tempGroup.count,
                                                creater: tempGroup.fullname,
                                                isAdmin: tempGroup.admin == 1 ? true : false
                                            });
                                        }
                                    });
                                    this.oldGroupList = this.$_.cloneDeep(this.groupList);
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        selectAllCheckbox() {
            this.isCheckedAll = !this.isCheckedAll
            if (this.isCheckedAll == true) {
                this.groupList.forEach(el => {
                    el.isChecked = el.isAdmin ? true: false;
                })
            }
            else {
                this.groupList.forEach(el => {
                    el.isChecked = false;
                })
            }
        },
        deleteGroup(index, groupId) {
            let params = {
                groupId: groupId
            }
            this.$httpRequest.post('/api/group/delete_group', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.groupList.splice(index, 1);
                        this.oldGroupList.splice(index, 1);
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        deleteGroups(groupIds) {
            let lastIndex = this.groupList.length - 1;
            for(let i = lastIndex; i >= 0; i-- ) {
                for(let j = 0; j < groupIds.length; j++) {
                    if (this.groupList[i].id == groupIds[j]) {
                        this.groupList.splice(i, 1);
                        this.oldGroupList.splice(i, 1);
                    }
                }
            }
        },
        showLeftGroupDialogue(index, groupId) {
            this.leftIndex = index;
            this.leftGroupId = groupId;
            this.leftGroupName = this.groupList[index].name;
            $('#leftGroupDialogue').modal('show');
        },
        leftGroup() {
            let params = {
                groupId: this.leftGroupId
            }

            this.$httpRequest.post('/api/group/leave_group', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        $('#leftGroupDialogue').modal('hide');
                        this.groupList.splice(this.leftIndex, 1);
                        this.oldGroupList.splice(this.leftIndex, 1);
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => console.log('catched error: ' + error));
        },
        resetSelected() {
            this.isCheckedAll = false;
            this.groupList.forEach(el => {
                el.isChecked = false;
            })
        }
    }
}
