import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
// common
import Dialogue from "@/components/common/dialogue_client.vue"
// components
const TableList = resolve => require(["@/components/admin/user/_table_list.vue"], resolve)

export default {
    props: ['activePage', 'userStateList'],
    components: {
        DatePicker,
        Dialogue,
        TableList
    },
    data: function() {
        return {
            allUserIds: [],
            searchText: '',
            renderTableListCount: 0,

            // 課程類型
            selectedGroupId: 0,
            courseGroups: [],
            inputStartIssueNo: '',
            inputEndIssueNo: '',
            // 課程陣列
            newCourseItems: [
                {
                    id: '',
                    showName: false,
                    name: '',
                    price: 0
                }
            ],
            // 到期時間
            inputExpireDate: '',
            // 匯入使用者的參數
            selectPermission: '',
            importUser: {
                path: '',
                name: '',
                file: ''
            },

            uploadErrorMsg: {
                file: null,
            },

            isPostingApi: {
                importUsers: false // 匯入使用者
            }
        }
    },
    watch: {
        isClickSidebarLink(newValue) {
            if (newValue) {
                this.getUserIdsByState();
            }
            this.isClickSidebarLink = false;
        }
    },
    computed: {
        isClickSidebarLink: {
            get() {
                return this.$store.state.adminUser.isClickSidebarLink;
            },
            set(value) {
                this.$store.commit('adminUser/setIsClickSidebarLink', value);
            }
        },
        permissionOptList() {
            let opts = [];
            opts = [
                {
                    value: 'user',
                    text: '一般使用者'
                },
                {
                    value: 'teacher',
                    text: '老師'
                }
            ]
            return opts;
        },
        countCourseAmount() {
            let total = 0;
            this.newCourseItems.forEach(el => {
                if (el.id != '') {
                    total++;
                }
            });

            return total;
        },
    },
    created: function() {
        this.isClickSidebarLink = false;
        this.getUserIdsByState();
        this.getCourseGroups();
    },
    methods: {
        getUserIdsByState() {
            let params = {};

            switch(this.$route.params.subPage) {
                case 'all':
                case 'admin':
                    params.permission = this.$route.params.subPage;
                    break;
                case 'general':
                    params.permission = 'user';
                    break;
                case 'teach':
                    params.permission = 'teacher';
                    break;
            }

            this.allUserIds = [];
            this.renderTableListCount++;

            this.$httpRequest.get('/admin_api/user/get_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allUserIds = result;
                            this.renderTableListCount++;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getUserIdsBySearchText() {
            let params = {
                keyword: this.searchText,
                permission: []
            }

            switch(this.$route.params.subPage) {
                case 'all':
                case 'admin':
                    params.permission.push(this.$route.params.subPage);
                    break;
                case 'general':
                    params.permission.push('user');
                    break;
                case 'teach':
                    params.permission.push('teacher');
                    break;
            }

            this.allUserIds = [];

            this.$httpRequest.post('/admin_api/user/get_search_list_ids', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.allUserIds = result;
                            this.renderTableListCount++;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        getUserListReportFile() {
            this.$httpRequest.get('/admin_api/report/get_account_list')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let url = response.data.result;

                        let fileLink = document.createElement('a');
                        fileLink.href = url;
                        fileLink.setAttribute('download', 'account_list_YYYYMMDD.csv');
                        fileLink.click();
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        showImportUsersDialogue() {
            $('#importUsersDialogue').modal('show');
            this.initialImportUsers();
        },
        checkIsNumber(num) { // 檢查是否有數字以外的字元，有的話會直接無法輸入
            return num.replace(/[^0-9]/g, "");
        },
        getCourseGroups() {
            this.$httpRequest.get('/admin_api/order/get_course_groups')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.courseGroups = result;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        checkIssueNoStatus() {
            if (!this.inputStartIssueNo || !this.inputEndIssueNo) {
                return;
            }

            let params = {
                courseGroupId: this.selectedGroupId,
                startIssueNo: this.inputStartIssueNo,
                endIssueNo: this.inputEndIssueNo
            }

            this.$httpRequest.get('/admin_api/course/check_issue_no_state', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result,
                            msg = response.data.msg;

                        if (result) {
                            // 若第一筆資料的id是空的代表還沒有資料，所以必須把陣列清空
                            if (this.newCourseItems.length < 2) {
                                if (this.newCourseItems[0].id == '') {
                                    this.newCourseItems = [];
                                }
                            }
                            else {
                                let itemLen = this.newCourseItems.length-1;
                                for(let i = itemLen; i >= 0; i--) {
                                    // 若陣列有id為空的，會被刪除
                                    if (this.newCourseItems[i].id == '') {
                                        this.newCourseItems.splice(i, 1);
                                    }
                                }
                            }
                            result.forEach(el => {
                                // 檢查資料是否已存在於newCourseItems中，有的話continue
                                let sameData = this.$_.find(this.newCourseItems, ['id', el.id]);
                                if (sameData) {
                                    return;
                                }
                                let item = {
                                    id: el.id,
                                    name: el.name,
                                    showName: true,
                                    price: el.price
                                }
                                this.newCourseItems.push(item);
                            });

                            if (msg) {
                                this.$store.dispatch('common/setAlert', { msg: msg, status: 'danger', duration: 20000 });
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        checkCourseStatus(index, id) { // 檢查課程是否可以團購
            if (!id || isNaN(id) || id == 0) {
                return;
            }

            if (!this.$_.isNull(index) && this.newCourseItems.length != 0) {
                let isRepeat = false;
                this.newCourseItems.forEach((item, key) => {
                    if (item.id == id && key != index) {
                        this.$store.dispatch('common/setAlert', { msg: '輸入的課程ID不能與另一個一樣', status: 'danger' });
                        isRepeat = true;
                        return;
                    }
                });
                if (isRepeat) {
                    return;
                }
            }

            let params = {
                courseId: id
            }

            if (this.$_.isNull(index)) { // 如果是紙本課程，必須一定是預購課程
                params.isPaperCourse = 1;
            }

            this.$httpRequest.get('/admin_api/course/check_course_state', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            if (!this.$_.isNull(index)) {
                                this.newCourseItems[index].name = result.name;
                                this.newCourseItems[index].showName = true;
                                this.newCourseItems[index].price = result.price;
                            }
                            else {
                                this.paperItem.name = result.name;
                                this.paperItem.showName = true;
                                this.paperItem.price = result.price;
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });

        },
        addNewGroupBuying() { // 新增團購課程input所需參數
            this.newCourseItems.push({
                id: '',
                showName: false,
                name: '',
                price: 0
            });
        },
        deleteGroupBuying(index) { // 刪除團購課程input
            if (isNaN(index) && this.newCourseItems.length == 0) {
                return;
            }

            if (!this.$_.isNull(index)) {
                if (this.newCourseItems.length == 1) {
                    this.newCourseItems[0].id = '';
                    this.newCourseItems[0].name = '';
                    this.newCourseItems[0].showName = false;
                }
                else {
                    this.newCourseItems.forEach((item, key) => {
                        if (index == key) {
                            this.newCourseItems.splice(index, 1);
                        }
                    })
                }
            }
            else {
                this.paperItem = {
                        id: '',
                        showName: false,
                        name: '',
                        price: 0
                    }
            }
        },
        disableExpireDate(date) {
            return date < new Date().setHours(0, 0, 0, 0)
        },
        initialImportUsers() {
            this.selectPermission = '';
            this.selectedGroupId =  0;
            this.inputStartIssueNo = '';
            this.inputEndIssueNo = '';
            this.newCourseItems = [
                {
                    id: '',
                    showName: false,
                    name: '',
                    price: 0
                }
            ],
            this.inputExpireDate = '',
            this.importUser = {
                path: '',
                name: '',
                file: ''
            };
        },
        addImportUserExcel() {
            let v = this;

            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs.importUser.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['xlsx', 'xls'];
            let perFileLimitSize = 209715200;  // 200mb

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.importUser.path = dataURL;
                }

                this.importUser.name = tempUploadFile.name;
                this.importUser.file = tempUploadFile;
            }

            this.$refs.importUser.value = null;
        },
        importUsers() {
            let params = new FormData();
            params.append('permission', this.selectPermission);

            if (this.newCourseItems.length > 0) {
                this.newCourseItems.forEach( el => {
                    if (el.id != '') {
                        params.append('courseIds[]', el.id);
                    }
                });
            }
            params.append('expire', this.$util.datetimeToUnixTimestamp(this.inputExpireDate));
            params.append('file', this.importUser.file);

            this.isPostingApi.importUsers = true;
            this.$httpRequest.post('/admin_api/user/import_users', params)
                .then(response => {
                    this.isPostingApi.importUsers = false;
                    if (response.data.state == 'OK') {
                        this.initialImportUsers();
                        $('#importUsersDialogue').modal('hide');
                        this.getUserIdsByState();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                })
                .catch(error => {
                    this.isPostingApi.importUsers = false;
                    console.error('Catched Error:', error);
                });
        }
    }
}
