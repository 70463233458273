import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue.vue"
export default {
    props: ['allUserIds', 'passcode', 'isAdmin'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            groupCode: this.passcode,
            groupCodeArr: this.passcode.split(''),
            inputKeyword: '',
            isShowSearchDropdown: false, // search dropdown
            userList: [],
            oldUserList: [],
            isCheckedAll: false,
            searchIds: [],
            isSearch: false,
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 15,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
        }
    },
    watch: {
        inputKeyword(newValue) {
            if (newValue) {
                this.isShowSearchDropdown = true;
            }
            else {
                this.isShowSearchDropdown = false;
                this.inputSearchFilter = '';
                this.$parent.getInitialData();
            }
        },
        isUserEdit(newValue) {
            if (newValue == false) { // 只要停止編輯，就重新assign 資料到舊資料
                this.userList = this.$_.cloneDeep(this.oldUserList);
            }
        },
        isUserSave(newValue) {
            if (newValue == true) { // 只要儲存編輯，就重新assign 資料到新資料
                this.oldUserList = this.$_.cloneDeep(this.userList);
            }
        },
        isUserDelete(newValue) {
            if (newValue) {
                this.resetSelected();
            }
        },
        isUserAssign(newValue) {
            if (newValue) {
                this.resetSelected();
            }
        },
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "check",
                    itemText: "",
                    ownClass: "check",
                    isShowCheckbox: true,
                    isShow: this.isUserDelete || this.isUserAssign
                },
                {
                    itemName: "class",
                    itemText: "班級",
                    ownClass: "class",
                    isShow: true
                },
                {
                    itemName: "class-no",
                    itemText: "座號",
                    ownClass: "class-no",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "姓名",
                    ownClass: "name",
                    isShow: true
                },
                {
                    itemName: "account",
                    itemText: "帳號",
                    ownClass: "account",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        isUserEdit: {
            get() {
                return this.$store.state.user.isUserEdit;
            },
            set(value) {
                this.$store.commit('user/setIsUserEdit', value);
            }
        },
        isUserSave: {
            get() {
                return this.$store.state.user.isUserSave;
            },
            set(value) {
                this.$store.commit('user/setIsUserEdit', value);
            }
        },
        isUserDelete: {
            get() {
                return this.$store.state.user.isUserDelete;
            },
            set(value) {
                this.$store.commit('user/setIsUserDelete', value);
            }
        },
        isUserAssign: {
            get() {
                return this.$store.state.user.isUserAssign;
            },
            set(value) {
                this.$store.commit('user/setIsUserAssign', value);
            }
        },
        userData() {
            return this.$store.state.user.updateUserData;
        },
        updateUserListData() {
            let updateData = {},
                modifyUserList = [],
                userList = [];

            this.userList.forEach((el, index) => {
                let tempUser = {};

                if (el.class != this.oldUserList[index].class) { // 學生班級
                    tempUser.class = el.class;
                }

                if (el.classNo != this.oldUserList[index].classNo) { // 學生座號
                    tempUser.classNo = el.classNo;
                }

                if (el.name != this.oldUserList[index].name) { // 學生姓名
                    tempUser.name = el.name;
                }

                if (Object.keys(tempUser).length !== 0) {
                    tempUser.id = el.id;
                    modifyUserList.push(tempUser);
                }
                if (el.isChecked == true) {
                    userList.push(el);
                }
            });

            if (modifyUserList) {
                updateData.modifyUserList = modifyUserList;
            }

            if (userList) {
                updateData.userList = userList;
                updateData.type = 'user';
            }

            return updateData;
        }
    },
    created: function() {
        if (this.allUserIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getUserList()
                    .then(() => {
                        this.busy = false;
                        // 監聽編輯內容是否更動
                        this.$watch('updateUserListData', newValue => {
                            this.$store.commit('user/setUpdateUserListData', newValue);
                        });
                    })
                    .catch(error => console.log('catched error: ' + error));
            }
        },
        checkIsNumber(val) {
            /* eslint-disable */
            return val.replace(/[^0-9]/g, "");
        },
        getUserList() {
            return new Promise((resolve, reject) => {
                let userIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempUserId = this.isSearch ? this.searchIds[this.loadingIndex] : this.allUserIds[this.loadingIndex];
                    if (!tempUserId) {
                        this.isGetAllList = true;
                        break;
                    }
                    userIds.push(tempUserId);
                    this.loadingIndex++;
                }

                if (userIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    userIds: userIds
                }

                this.$httpRequest.post('/api/group/get_group_member_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    userIds.forEach(id => {
                                        let tempUser = result[id];
                                        if (tempUser) {
                                            this.userList.push({
                                                id: id,
                                                isChecked: false,
                                                class: tempUser.class,
                                                classNo: tempUser.classNo,
                                                name: tempUser.name,
                                                account: tempUser.account
                                            });
                                        }
                                    });
                                    this.oldUserList = this.$_.cloneDeep(this.userList);
                                }
                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        refreshPasscode() {
            let params = {
                groupId: this.$route.params.groupId
            }

            this.$httpRequest.post('/api/group/refresh_passcode', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.groupCode = result;
                                this.groupCodeArr = result.split('');
                            }
                        }
                    })
                    .catch(error => console.log('catched error: ' + error));
        },
        copyCode() {
            let textCopy = document.querySelector('#group-code');
            textCopy.setAttribute('type', 'text'); // 不是隱藏(hidden)才能複製
            textCopy.select();
            let successful = document.execCommand('copy');
            if (successful) {
                this.$store.dispatch('common/setAlert', { msg: '複製成功', status: 'success' });
            }
            /* unselect the range */
            textCopy.setAttribute('type', 'hidden');
            window.getSelection().removeAllRanges();
        },
        search(field) {
            let params = {
                groupId: this.$route.params.groupId,
                keyword: this.inputKeyword,
                field: field
            }

            this.$httpRequest.post('/api/group/search_member', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.loadingIndex = 0;
                                this.isGetAllList = false;
                                this.userList = [];
                                this.searchIds = result;
                                this.isSearch = true;
                                this.getUserList();
                            }
                        }
                    })
                    .catch(error => console.log('catched error: ' + error));
        },
        clearInputKeyword() {
            this.inputKeyword = '';
        },
        selectAllCheckbox() {
            this.isCheckedAll = !this.isCheckedAll
            if (this.isCheckedAll == true) {
                this.userList.forEach(el => {
                    el.isChecked = true;
                })
            }
            else {
                this.userList.forEach(el => {
                    el.isChecked = false;
                })
            }
        },
        resetSelected() {
            this.isCheckedAll = false;
            this.userList.forEach(el => {
                el.isChecked = false;
            })
        }
    }
}
