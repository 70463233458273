import Layout from "@/layouts"

// components
const User = resolve => require(["@/components/admin/user/index.vue"], resolve)
const Course = resolve => require(["@/components/admin/course/index.vue"], resolve)
const Article = resolve => require(["@/components/admin/article/index.vue"], resolve)
const Coupon = resolve => require(["@/components/admin/coupon/index.vue"], resolve)
const BuyingBundles = resolve => require(["@/components/admin/buying_bundles/index.vue"], resolve)
const GroupBuying = resolve => require(["@/components/admin/group_buying/index.vue"], resolve)
const SchoolBuying = resolve => require(["@/components/admin/school_buying/index.vue"], resolve)
const PushNotification = resolve => require(["@/components/admin/push_notification/index.vue"], resolve)
const Order = resolve => require(["@/components/admin/order/index.vue"], resolve)
const DataLog = resolve => require(["@/components/admin/data_log/index.vue"], resolve)

export default {
    name: 'Admin',
    components: {
        Layout,
        User,
        Course,
        Article,
        Coupon,
        BuyingBundles,
        GroupBuying,
        SchoolBuying,
        PushNotification,
        Order,
        DataLog
    },
    watch: {
        'isShowNotSavePrompt.course'(newValue) {
            this.showPromptOnBeforeunload(newValue);
        },
        'isShowNotSavePrompt.article'(newValue) {
            this.showPromptOnBeforeunload(newValue);
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        componentType() {
            switch(this.$route.params.page) {
                case 'user':
                    return User;
                case 'course':
                    return Course;
                case 'article':
                    return Article;
                case 'coupon-experience':
                case 'coupon-subscrib':
                case 'coupon-discount':
                case 'coupon-all':
                    return Coupon;
                case 'buying-bundles':
                    return BuyingBundles;
                case 'group-buying':
                    return GroupBuying;
                case 'school-buying':
                    return SchoolBuying;
                case 'push-notification':
                    return PushNotification;
                case 'order':
                    return Order;
                case 'data-log':
                    return DataLog;
            }
        },
        isShowNotSavePrompt: {
            get() {
                return {
                    // 各個頁面自己的事件
                    course: this.$store.state.adminCourse.isShowNotSaveCoursePrompt,
                    article: this.$store.state.adminArticle.isShowNotSaveArticlePrompt,
                }
            },
            set(value) {
                this.$store.state.adminCourse.isShowNotSaveCoursePrompt = value.course;
                this.$store.state.adminArticle.isShowNotSaveArticlePrompt = value.article;
            }
        }
    },
    beforeRouteUpdate(to, from, next) {  // 切換 admin.vue 下的子層頁面 (history mode)
        let pickedObject = this.$_.pickBy(this.isShowNotSavePrompt, v => {
            return v === true;
        });

        if (!this.$_.isEmpty(pickedObject)) {
            let confirmed = confirm('系統可能不會儲存您所做的變更。');
            if (confirmed) {
                this.isShowNotSavePrompt = this.$_.mapValues(this.isShowNotSavePrompt, () => false);
                this.handleCancelSavingMutations();
                this.$store.commit('common/setHeaderChildrenActiveLink', '');
                next();
            } else {
                next(false);
            }
        } else {
            this.$store.commit('common/setHeaderChildrenActiveLink', '');
            next();
        }
    },
    beforeRouteLeave(to, from, next) {  // 跳離 admin.vue 這個主路由 (history mode)
        let pickedObject = this.$_.pickBy(this.isShowNotSavePrompt, v => {
            return v === true;
        });

        if (!this.$_.isEmpty(pickedObject)) {
            let confirmed = confirm('系統可能不會儲存您所做的變更。');
            if (confirmed) {
                this.isShowNotSavePrompt = this.$_.mapValues(this.isShowNotSavePrompt, () => false);
                this.handleCancelSavingMutations();
                this.$store.commit('common/setHeaderChildrenActiveLink', '');
                next();
            } else {
                next(false);
            }
        } else {
            this.$store.commit('common/setHeaderChildrenActiveLink', '');
            next();
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        // 使用者尚未登入或權限非 admin 時, 無法進入系統後台頁面
        if (this.$_.isEmpty(this.userInfo) || this.userInfo.permission != 'admin') {
            this.$router.push('/');
        }

        this.$store.commit('common/setHeaderActiveLink', 'admin');
        this.$store.commit('common/setIsAdminPage', true);
    },
    beforeDestroy: function() {
        window.onbeforeunload = null;
    },
    methods: {
        showPromptOnBeforeunload(isShow) {  // 離開頁面提示(redirect mode)
            if (isShow) {
                window.onbeforeunload = function(e) {
                    e = e || window.event;
                    let msg = '系統可能不會儲存您所做的變更。';
                    if (e) {
                        e.preventDefault();
                        e.returnValue = msg;
                    }
                    return msg;
                }
            } else {
                window.onbeforeunload = null;
            }
        },
        handleCancelSavingMutations() {  // 取消儲存後, 各類別做對應處理
            this.$store.commit('adminCourse/cancelSavingCourse');  // 課程
            this.$store.commit('adminArticle/cancelSavingArticle'); // 文章
        }
    }
}
