<template>
    <div class="lms-content">
        <slot />
        <Alert v-if="$store.state.common.isShowAlert" />
    </div>
</template>

<script>
// common
const Alert = resolve => require(["@/components/common/alert.vue"], resolve)

export default {
    name: 'LayoutFull',
    components: {
        Alert
    }
}
</script>
