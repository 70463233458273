import Layout from "@/layouts"
import { mapGetters } from 'vuex'
import '@/global/markdown/md_preview.css'

// common
const MobileUnsupported = resolve => require(["@/components/common/mobile_unsupported.vue"], resolve)
// components
const CourseInfoBar = resolve => require(["@/components/article/_course_info_bar.vue"], resolve)
const LoginPromptDialogue = resolve => require(["@/components/article/_login_prompt_dialogue.vue"], resolve)
const Read = resolve => require(["@/components/article/read.vue"], resolve)
const Listen = resolve => require(["@/components/article/listen.vue"], resolve)
const Teach = resolve => require(["@/components/article/teach.vue"], resolve)
const Practice = resolve => require(["@/components/article/practice.vue"], resolve)
const Practicing = resolve => require(["@/components/article/practicing.vue"], resolve)
const PracticeHistory = resolve => require(["@/components/article/practice/history.vue"], resolve)
const NotSavePracticeHistoryDialogue = resolve => require(["@/components/article/practice/_not_save_history_prompt_dialogue.vue"], resolve)
const Favorites = resolve => require(["@/components/article/favorites.vue"], resolve)

export default {
    name: 'Article',
    components: {
        Layout,
        MobileUnsupported,
        CourseInfoBar,
        LoginPromptDialogue,
        Read,
        Listen,
        Teach,
        Practice,
        Practicing,
        PracticeHistory,
        NotSavePracticeHistoryDialogue,
        Favorites
    },
    data: function() {
        return {
            redirectArticleIds: {  // 前一篇與後一篇文章 ids
                '-1': null,  // 前一篇
                '1': null  // 後一篇
            },

            isSetDataReady: {
                getArticleData: false,  // 取得文章資訊
            }
        }
    },
    watch: {
        '$route.params.id'(newValue) {
            // 切換至其他文章
            if (newValue) {
                this.initial();
            }
        },
        isShowNotSavePrompt(newValue) {
            this.showPromptOnBeforeunload(newValue);
        },
        windowWidth(newValue) {
            // 解析度 < 768 時, 關閉彈窗
            if (newValue < 768) {
                $('.modal').modal('hide');
            }
        },
        async userInfo(newValue) {
            // 已登入 -> 未登入
            if (this.$_.isEmpty(newValue)) {
                // 付費文章, 導回首頁並跳出登入彈窗
                if (!this.$_.isNull(this.articleData) && !this.articleData.is_public) {
                    this.$router.push('/').catch(() => {});
                    $('#loginDialogue').modal('show');
                }
                // 初始課程購買狀態
                this.courseBasicInfo.isCourseUser = false;
                // 初始化使用者收藏單字與筆記
                this.$store.commit('article/setUserMarkedWords', []);
                this.$store.commit('article/setArticleNotes', {});
                // 未登入試閱文章用變數
                this.$store.commit('article/setAllMarkedWordIds', []);
                this.$store.commit('article/setTempWordId', 0);
                this.$store.commit('article/setTempWordInfoList', {});
                this.$store.commit('article/setTempSortedWordList', []);
            }
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                await this.getCourseBasicInfo();
                if (this.courseBasicInfo.isCourseUser) {
                    this.getArticleIdByAction('-1');
                    this.getArticleIdByAction('1');
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isShowCourseInfoBar: 'article/isShowCourseInfoBar',
            isShowLoginPrompt: 'article/isShowLoginPrompt'
        }),
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        courseBasicInfo() {
            return this.$store.state.course.courseBasicInfo;
        },
        articleUtil() {
            return this.$store.state.article.articleUtil;
        },
        articleData() {
            return this.$store.state.article.articleData;
        },
        nextTargetPath: {
            get() {
                return this.$store.state.article.nextTargetPath;
            },
            set(value) {
                this.$store.commit('article/setNextTargetPath', value);
            }
        },
        userMarkedWords: {
            get() {
                return this.$store.state.article.userMarkedWords;
            },
            set(value) {
                this.$store.commit('article/setUserMarkedWords', value);
            }
        },
        isShowNotSavePrompt: {
            get() {
                return this.$store.state.article.isShowNotSavePrompt;
            },
            set(value) {
                this.$store.commit('article/setIsShowNotSavePrompt', value);
            }
        },
        listeningSentencesPlayList: {
            get() {
                return this.$store.state.article.listeningSentencesPlayList;
            },
            set(value) {
                this.$store.commit('article/setListeningSentencesPlayList', value);
            }
        },
        teachingCommentsPlayList: {
            get() {
                return this.$store.state.article.teachingCommentsPlayList;
            },
            set(value) {
                this.$store.commit('article/setTeachingCommentsPlayList', value);
            }
        },
        isPracticing() {
            return this.$store.state.article.isPracticing;
        },
        isPracticeRelatedPage() {
            return ['practicing', 'practice-history'].includes(this.$route.params.page);
        },
        isShowNotSavePracticeHistoryPrompt() {
            return this.$store.state.article.isShowNotSavePracticeHistoryPrompt;
        },
        componentType() {
            // route 參數沒有 page 的狀況, 導向文章閱讀頁面
            if (!this.$route.params.page) {
                return Read;
            }

            // 題目練習, 在練習進行中時重整頁面則導回分類列表頁面
            if (this.$route.params.page === 'practicing') {
                if (!this.isPracticing) {
                    this.$router.replace(`/${this.$route.params.articleIndex}/${this.$route.params.id}/practice`).catch(() => {});
                    return Practice;
                } else {
                    return Practicing;
                }
            }

            return this.$route.params.page;
        }
    },
    // 切換 article.vue 下的子層頁面 (history mode)
    beforeRouteUpdate(to, from, next) {
        if (this.isShowNotSavePracticeHistoryPrompt) {
            $('#notSavePracticeHistoryPromptDialogue').modal('show');
        } else {
            if (this.isShowNotSavePrompt) {
                let confirmed = confirm('系統可能不會儲存您所做的變更。');
                if (confirmed) {
                    this.isShowNotSavePrompt = false;
                    next();
                } else {
                    next(false);
                }
            } else {
                next();
            }
        }
    },
    // 跳離 article.vue 這個主路由 (history mode)
    beforeRouteLeave(to, from, next) {
        if (this.isShowLoginPrompt) {
            this.nextTargetPath = to.fullPath;
            $('#loginPromptDialogue').modal('show');
        } else {
            if (this.isShowNotSavePrompt) {
                let confirmed = confirm('系統可能不會儲存您所做的變更。');
                if (confirmed) {
                    this.isShowNotSavePrompt = false;
                    next();
                } else {
                    next(false);
                }
            } else {
                next();
            }
        }
    },
    created: function() {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        if (this.windowWidth >= 768) {
            this.initial();
        }
    },
    mounted: function() {
        window.addEventListener('scroll', this.prevNextTogglesScrollHandler);
    },
    beforeDestroy: function() {
        window.onbeforeunload = null;

        window.removeEventListener('scroll', this.prevNextTogglesScrollHandler);
    },
    methods: {
        // 離開頁面前提示 (redirect mode)
        showPromptOnBeforeunload(isShow) {
            if (isShow) {
                window.onbeforeunload = function(e) {
                    e = e || window.event;
                    let msg = '系統可能不會儲存您所做的變更。';
                    if (e) {
                        e.preventDefault();
                        e.returnValue = msg;
                    }
                    return msg;
                }
            } else {
                window.onbeforeunload = null;
            }
        },

        // 初始文章所有設定值與取得文章資訊
        initial() {
            // 體驗試閱文章相關變數
            this.$store.commit('article/setNextTargetPath', '');
            // 單字查詢相關變數
            this.$store.commit('article/setActiveSearchedWord', { id: null });
            this.$store.commit('article/setDictionaryResult', {});
            this.$store.commit('article/setIsShowDictBriefResult', false);
            this.$store.commit('article/setIsShowDictResultDialogue', false);
            this.$store.commit('article/setArticleContentHeight', 0);
            // 收藏單字相關變數
            this.$store.commit('article/setUserMarkedWords', []);
            this.$store.commit('article/setIsMarkWordSuccess', false);
            this.$store.commit('article/setAllMarkedWordIds', []);
            this.$store.commit('article/setTempWordId', 0);
            this.$store.commit('article/setTempWordInfoList', {});
            this.$store.commit('article/setTempSortedWordList', []);
            // 選擇單字程度相關變數
            this.$store.commit('article/setWordLevels', []);
            this.$store.commit('article/setSelectedWordLevel', { id: '0', name: '不顯示' });
            this.$store.commit('article/setArticleLevelWords', []);
            // 筆記相關變數
            this.$store.commit('article/setArticleNotes', {});
            this.$store.commit('article/setIsOpenNote', false);
            this.$store.commit('article/setIsScrollRightArticleContent', false);
            this.$store.commit('article/setIsShowNotSavePrompt', false);
            // 文章聽讀與講解播放清單相關變數
            this.$store.commit('article/setListeningSentencesPlayList', []);
            this.$store.commit('article/setTeachingCommentsPlayList', []);
            // 題目練習相關變數
            this.$store.commit('article/setArticlePracticeInfo', null);
            this.$store.commit('article/setIsPracticing', false);
            this.$store.commit('article/setPracticeQuestionInfo', null);
            this.$store.commit('article/setIsShowNotSavePracticeHistoryPrompt', false);

            // 取得文章資訊
            this.isSetDataReady.getArticleData = false;
            this.getArticleData();
        },

        // 取得文章資訊
        async getArticleData() {
            try {
                await this.$store.dispatch('article/getArticleData', {
                    articleId: this.$route.params.id,
                    isPreview: this.$route.params.articleIndex === 'article-preview'
                });
                await this.getCourseBasicInfo();
                // 僅有購買課程的使用者有切換上下篇與返回課程路徑
                if (this.courseBasicInfo.isCourseUser || this.userInfo.permission === 'admin') {
                    await this.getArticleIdByAction('-1');
                    await this.getArticleIdByAction('1');
                }
                this.isSetDataReady.getArticleData = true;
                this.$util.updateMeta(this.courseBasicInfo.courseInfo.metaTitle);   // 文章與其練習頁面的標題使用課程的meta
            } catch (error) {
                this.$router.push('/').catch(() => {});
                console.error('Catched Error:', error);
            }
        },

        // 取得課程基本資訊
        async getCourseBasicInfo() {
            await this.$store.dispatch('course/getCourseBasicInfo', {
                courseId: this.articleData.course_id
            });
        },

        // 取得前一篇 or 後一篇文章 id
        async getArticleIdByAction(action) {
            this.redirectArticleIds[action] = await this.$store.dispatch('article/getArticleIdByAction', {
                articleId: this.$route.params.id,
                courseId: this.articleData.course_id,
                action: action
            });
        },
        // 導向前一篇或後一篇文章頁面
        async redirectArticleByAction(action) {
            if (this.$_.isNull(this.redirectArticleIds[action]) || this.redirectArticleIds[action] === '0') {
                return;
            }

            this.$router.push(`/${this.$route.params.articleIndex}/${this.redirectArticleIds[action]}`);
        },
        // 前後篇觸發按鈕 DOM 區塊捲軸事件處理
        prevNextTogglesScrollHandler() {
            let elemFooterHeight = $('.lms-footer').height();  // footer 高度

            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  // 取得 elem 因包含 overflow 而沒顯示在螢幕上的內容高度
            let windowHeight = $(window).innerHeight();  // 取得目前可視範圍高度
            let scrollTop = $(window).scrollTop(); // 取得卷軸目前的 Y座標 位置

            if (windowHeight + scrollTop >= scrollHeight - elemFooterHeight) {
                $('.prev-next-toggles').addClass('absoulte-position').removeClass('sticky-position');
            } else {
                $('.prev-next-toggles').addClass('sticky-position').removeClass('absoulte-position');
            }
        }
    }
}
