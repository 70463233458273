import InfiniteScroll from 'vue-infinite-scroll'

// utils
import searchUtil from '@/assets/js/utils/search'

export default {
    props: {
        searchedKeyword: {
            required: true,
            type: String
        },
        flags: {
            required: true,
            type: Array
        },
        selectedCourseId: {
            required: true,
            type: [Number, String]
        }
    },
    directives: {
        InfiniteScroll
    },
    data: function() {
        return {
            resultList: [],
            articlesContent: {},
            sort: 0,

            // load more
            busy: true,  // 是否觸發載入更多
            isGetAllList: false  // 是否全部載入完畢
        }
    },
    watch: {
        windowWidth(newValue, oldValue) {
            if (newValue < 768 && oldValue >= 767 || newValue >= 768 && oldValue < 767) {
                for (let id in this.articlesContent) {
                    this.articlesContent[id].html = searchUtil.highlightKeyword(this.articlesContent[id].origin, this.inputKeyword, true);
                }
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        inputKeyword() {
            return this.$store.state.common.inputKeyword;
        },
        searchTotal: {
            get() {
                return this.$store.state.common.searchTotal;
            },
            set(value) {
                this.$store.commit('common/setSearchResultTotal', value);
            }
        }
    },
    created: function() {
        this.loadMore();
    },
    methods: {
        async loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                let newList = await this.getResultList();
                this.resultList = this.resultList.concat(newList);
                this.busy = false;
            }
        },
        getResultList() {
            return new Promise(resolve => {
                if (!this.searchedKeyword || !this.flags.length) {
                    resolve([]);
                    return;
                }

                let params = {
                    keyword: this.searchedKeyword,
                    flags: this.flags,
                    courseId: this.selectedCourseId,
                    sort: this.sort
                }

                this.$httpRequest.post('/api/search/get_article_by_keyword', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                let resultList = [];

                                for (let el of result.articles) {
                                    let id = el.article_id;
                                    resultList.push({
                                        articleId: el.article_id,
                                        type: el.type,
                                        cover: el.file_name,
                                        name: el.article_name,
                                        courseName: el.course_name
                                    });

                                    let content = {
                                        origin: el.content,
                                        html: searchUtil.highlightKeyword(el.content, this.inputKeyword, true)
                                    }
                                    this.$set(this.articlesContent, id, content);
                                }
                                this.searchTotal = result.count;

                                this.sort = result.sort;

                                this.isGetAllList = result.count == result.articles.length + this.resultList.length ? true : false;
                                resolve(resultList);
                            }
                        }
                    })
                    .catch(error => {
                        console.error('Catched Error:', error);
                    });
            });
        }
    }
}
