import InfiniteScroll from "vue-infinite-scroll"
// common
import Dialogue from "@/components/common/dialogue.vue"
export default {
    props: ['allOrderIds'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue
    },
    data: function() {
        return {
            orderList: [],
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢
            // 訂單詳細資料的一些參數
            orderDetailItem: '',
            orderItemList: [],
            isOpenCancel: false, // 是否打開取消區
            cancelReason: '',
            courseBundle: []
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "no",
                    itemText: "訂單編號",
                    ownClass: "no",
                    isShow: true
                },
                {
                    itemName: "device",
                    itemText: "來源",
                    ownClass: "device",
                    isShow: true
                },
                {
                    itemName: "user-id",
                    itemText: "使用者ID",
                    ownClass: "user-id",
                    isShow: true
                },
                {
                    itemName: "account",
                    itemText: "使用者帳號",
                    ownClass: "user-account",
                    isShow: true
                },
                {
                    itemName: "date",
                    itemText: "訂單日期",
                    ownClass: "order-date",
                    isShow: true
                },
                {
                    itemName: "status",
                    itemText: "狀態",
                    ownClass: "order-status",
                    isShow: true
                },
                {
                    itemName: "advance",
                    itemText: "進階",
                    ownClass: "advance",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
        courseTableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "type",
                    itemText: "課程類型",
                    ownClass: "course-type",
                    isShow: true
                },
                {
                    itemName: "id",
                    itemText: "課程編號",
                    ownClass: "course-id",
                    isShow: true
                },
                {
                    itemName: "name",
                    itemText: "課程名稱",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "price",
                    itemText: "價格",
                    ownClass: "course-price",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        },
    },
    created: function() {
        if (this.allOrderIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getOrderList()
                    .then(() => this.busy = false)
                    .catch((error) => console.log('catched error: ' + error));
            }
        },
        getOrderList() {
            return new Promise((resolve, reject) => {
                let orderIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempOrderId = this.allOrderIds[this.loadingIndex];
                    if (!tempOrderId) {
                        this.isGetAllList = true;
                        break;
                    }
                    orderIds.push(tempOrderId);
                    this.loadingIndex++;
                }

                if (orderIds.length == 0) {
                    return;
                }

                let params = {
                    orderIds: orderIds
                }

                this.$httpRequest.get('/admin_api/order/get_list_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    orderIds.forEach(id => {
                                        let tempOrder = result[id];
                                        if (tempOrder) {
                                            tempOrder.wordStatus = this.fromatStatus(tempOrder.status);
                                            tempOrder.expired_date = this.$util.unixTimestampToDatetime(tempOrder.expired_date, this.$util.getBrowserCurrentTimeZone());
                                            tempOrder.finished_date = this.$util.unixTimestampToDatetime(tempOrder.finished_date, this.$util.getBrowserCurrentTimeZone());
                                            tempOrder.enterdate = this.$util.unixTimestampToDatetime(tempOrder.enterdate, this.$util.getBrowserCurrentTimeZone());
                                            this.orderList.push(tempOrder);
                                        }
                                    });
                                }
                                resolve();
                            } else {
                                reject("admin/order/_table_list.js: 'getOrderList' get error");
                            }
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/order/_table_list.js: 'getOrderList' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/order/_table_list.js: 'getOrderList' get error");
                    });
            });
        },
        setStateText(state) {
            let filteredData = this.$parent.userStateList.find(el => el.stateNum == state);
            return filteredData.name;
        },
        fromatStatus(status) {
            switch(status) {
                case '0':
                    return '未付款';
                case '1':
                    return '已付款';
                case '2':
                    return '逾期未付';
                case '3':
                    return '付款失敗';
                case '4':
                    return '已取消';
                case '5':
                    return '免付款';
            }
        },
        showOrderDetailDialogue(data) {
            this.cancelReason = '';
            this.orderDetailItem = data;
            $('#showOrderDetailDialogue').modal('show');

            let params = {
                orderNo: data.order_no
            }

            this.$httpRequest.get('/admin_api/order/get_order_items', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            this.orderItemList = result.order_items;
                            this.courseBundle = result.course_bundle;
                            if (this.orderDetailItem.discount_type == '2') {    // 使用全站券則算出折扣金額來取代discount的%數
                                let totalOriginalPrice = 0;
                                this.orderItemList.forEach(item => totalOriginalPrice += parseInt(item.price));
                                this.orderDetailItem.coupon_discount = totalOriginalPrice - parseInt(this.orderDetailItem.price);
                            }
                        } else {
                            console.log("admin/order/_table_list.js: 'getOrderItems' get error");
                        }
                    }
                    if (response.data.state == 'ERROR') {
                        console.log("admin/order/_table_list.js: 'getOrderItems' get error");
                    }
                })
                .catch(function (error) {
                    console.log('catched error: ' + error);
                });
        },
        confirm() {
            return new Promise((resolve, reject) => {

                let params = {
                    orderNo: this.orderDetailItem.order_no,
                    cancelReason: this.cancelReason
                }

                this.$httpRequest.post('/admin_api/order/cancel_order_by_admin', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (result.success) {
                                    this.orderDetailItem.status = 4;
                                    this.getOrderList();
                                    this.$store.dispatch('common/setAlert', { msg: '取消成功', status: 'success' });
                                }
                                else {
                                    this.cancelReason = '';
                                    this.$store.dispatch('common/setAlert', { msg: result.msg, status: 'danger' });
                                }
                                resolve();
                            } else {
                                reject("admin/order/_table_list.js: 'confirm' get error");
                            }
                            $('#showOrderDetailDialogue').modal('hide');
                        }
                        if (response.data.state == 'ERROR') {
                            reject("admin/order/_table_list.js: 'confirm' get error");
                        }
                    })
                    .catch(function (error) {
                        console.log('catched error: ' + error);
                        reject("admin/order/_table_list.js: 'confirm' get error");
                    });
            });
        }
    }
}
