import { render, staticRenderFns } from "./_course_table_list.vue?vue&type=template&id=4ab28c58&scoped=true&"
import script from "@/assets/js/components/user/group/_course_table_list.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/user/group/_course_table_list.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/user/group/_course_table_list.scss?vue&type=style&index=0&id=4ab28c58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab28c58",
  null
  
)

export default component.exports