import { render, staticRenderFns } from "./dialogue.vue?vue&type=template&id=00e4ad6e&scoped=true&"
import script from "@/assets/js/components/common/dialogue.js?vue&type=script&lang=js&"
export * from "@/assets/js/components/common/dialogue.js?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/common/dialogue.scss?vue&type=style&index=0&id=00e4ad6e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e4ad6e",
  null
  
)

export default component.exports