// common
import Dialogue from "@/components/common/dialogue_client.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/scss/index.scss'
import '@/assets/scss/vendors/vue2-datepicker.scss'
import InfiniteScroll from "vue-infinite-scroll"
// components
const UserList = resolve => require(["@/components/admin/school_buying/common/_user_list.vue"], resolve)
const SchoolInfo = resolve => require(["@/components/admin/school_buying/common/_school_info.vue"], resolve)

export default {
    props: ['allSchoolBuyingIds', 'sidebarList'],
    directives: {
        InfiniteScroll
    },
    components: {
        Dialogue,
        DatePicker,
        UserList,
        SchoolInfo
    },
    data: function() {
        return {
            schoolBuyingList: [],
            oldSchoolBuyingList: [],
            schoolBuyingListIndex: '',
            // load more
            busy: true,  // 是否觸發載入更多
            loadingIndex: 0,  // 目前載到所有資料中的第幾個index
            loadCountPerPage: 20,  // 一次載入幾筆
            isGetAllList: false,  // 是否全部載入完畢

            schoolBuyingId: 0,
            // 取得帳號清單
            users: [],
            // 匯入帳號資訊
            importUserInfo: {
                path: '',
                name: '',
                file: '',
                isBundleOIDC: 0,
            },
            listItem: {
                id: '',
                inputBaanNo: '',
                selectedCounty: '',
                selectedDistrict: '',
                selectedEdu: '',
                isWholeSchool: false,
                selectedSchoolId: '',
                selectedSchoolName: '',
                selectedCourseIds: '',
                selectedCourseList: '',
                selectedActivityDate: '',
                selectedStartIssueNo: '',
                inputIssueCount: '',
                selectedStartDateTime: '',
                selectedEndDateTime: '',
                inputBuyerEmail: '',
                selectedCollaborativeContract: '',
                inputDescription: '',
                inputNote: '',
            },
            durationTextList: ['一年', '二年', '三年', '四年', '五年'],
            uploadErrorMsg: {
                file: null,
            },

            isGettingApi: {
                downloadCertification: false
            },

            isPostingApi: {
                accountUploading: false, // 匯入帳號
                deleting: false
            },

            // user list
            allUserIds: []
        }
    },
    computed: {
        tableHeadList() {
            let list = [];
            list = [
                {
                    itemName: "edit",
                    itemText: "編輯訂單",
                    ownClass: "edit",
                    isShow: true
                },
                {
                    itemName: "certification",
                    itemText: "原廠授權書下載",
                    ownClass: "certification",
                    isShow: true
                },
                {
                    itemName: "import-users",
                    itemText: "匯入帳號",
                    ownClass: "import-users",
                    isShow: true
                },
                {
                    itemName: "baan",
                    itemText: "銷貨單號 (Baan)",
                    ownClass: "baan",
                    isShow: true
                },
                {
                    itemName: "activity-status",
                    itemText: "課程開通狀態",
                    ownClass: "activity-status",
                    isShow: true
                },
                {
                    itemName: "activity-date",
                    itemText: "開通日期",
                    ownClass: "activity-date",
                    isShow: true
                },
                {
                    itemName: "school-name",
                    itemText: "學校",
                    ownClass: "school-name",
                    isShow: true
                },
                {
                    itemName: "collaborative-contract",
                    itemText: "品項名稱",
                    ownClass: "collaborative-contract",
                    isShow: true
                },
                // {
                //     itemName: "whole-school",
                //     itemText: "全縣|全校",
                //     ownClass: "whole-school",
                //     isShow: true
                // },
                {
                    itemName: "course-name",
                    itemText: "購買課程",
                    ownClass: "course-name",
                    isShow: true
                },
                {
                    itemName: "issue-range",
                    itemText: "課程期數(年限)",
                    ownClass: "issue-range",
                    isShow: true
                },
                {
                    itemName: "start-date",
                    itemText: "課程啟用日期",
                    ownClass: "start-date",
                    isShow: true
                },
                {
                    itemName: "end-date",
                    itemText: "課程結束日期",
                    ownClass: "end-date",
                    isShow: true
                },
                {
                    itemName: "view-users",
                    itemText: "使用者帳號清單",
                    ownClass: "view-users",
                    isShow: true
                },
                {
                    itemName: "description",
                    itemText: "產品說明",
                    ownClass: "description",
                    isShow: true
                },
                {
                    itemName: "delete",
                    itemText: "",
                    ownClass: "delete",
                    isShow: true
                }
            ]
            list = list.filter(item => item.isShow);
            return list;
        }
    },
    created: function() {
        if (this.allSchoolBuyingIds.length) {
            this.loadMore();
        } else {
            this.busy = false;
            this.isGetAllList = true;
        }
    },
    methods: {
        loadMore() {
            if (this.isGetAllList) {
                return;
            } else {
                this.busy = true;
                this.getSchoolBuyingList()
                    .then(() => this.busy = false)
                    .catch(error => console.error('catched error: ' + error));
            }
        },
        getSchoolBuyingList() {
            return new Promise((resolve, reject) => {
                let schoolBuyingIds = [];

                for (let i = 0; i < this.loadCountPerPage; i++) {
                    let tempOrderId = this.allSchoolBuyingIds[this.loadingIndex];
                    if (!tempOrderId) {
                        this.isGetAllList = true;
                        break;
                    }
                    schoolBuyingIds.push(tempOrderId);
                    this.loadingIndex++;
                }

                if (schoolBuyingIds.length == 0) {
                    resolve();
                    return;
                }

                let params = {
                    ids: schoolBuyingIds
                }

                this.$httpRequest.post('/admin_api/order/get_school_order_info', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                if (!this.$_.isEmpty(result)) {
                                    schoolBuyingIds.forEach(id => {
                                        let tempSchoolBuying = result[id];
                                        if (tempSchoolBuying) {
                                            let isCourseGroup = tempSchoolBuying.course_info.is_group ? true : false,
                                                courseName = Object.values(tempSchoolBuying.course_info.name),
                                                tempCourse = Object.entries(tempSchoolBuying.course_info.name),
                                                courseList = [],
                                                isWholeCounty = tempSchoolBuying.county ? true : false;

                                                tempCourse.map( ([key, val]) => {
                                                    courseList.push({
                                                        id: key,
                                                        name: val,
                                                        isCourseGroup : isCourseGroup
                                                    })
                                                });

                                            this.schoolBuyingList.push({
                                                id: id,
                                                baan: tempSchoolBuying.order_no,
                                                isWholeCounty: isWholeCounty,
                                                county: isWholeCounty ? tempSchoolBuying.county : tempSchoolBuying.school_name.county,
                                                district: !isWholeCounty ? tempSchoolBuying.school_name.district : '',
                                                edu: !isWholeCounty ? tempSchoolBuying.school_name.edu : '',
                                                schoolId : tempSchoolBuying.school_id,
                                                schoolName: !isWholeCounty ? (tempSchoolBuying.school_name.name ? tempSchoolBuying.school_name.name : '') : '',
                                                isWholeSchool: tempSchoolBuying.is_full_school,
                                                importCount: tempSchoolBuying.import_count,
                                                isIdealiCourse: tempSchoolBuying.type == 'general' ? true : false,
                                                courseName: courseName.toString(),
                                                certificationDate: this.$util.unixTimestampToDatetime(tempSchoolBuying.cert_date, this.$util.getBrowserCurrentTimeZone(), 'date'),
                                                activityStatus: tempSchoolBuying.start_date != 0 ? (new Date() > new Date(this.$util.unixTimestampToDatetime(tempSchoolBuying.start_date, this.$util.getBrowserCurrentTimeZone())) ? true : false) : false ,
                                                activityDate: tempSchoolBuying.start_date != 0 ? this.$util.unixTimestampToDatetime(tempSchoolBuying.start_date, this.$util.getBrowserCurrentTimeZone(), 'date') : '',
                                                issueStart: isCourseGroup && tempSchoolBuying.start ? tempSchoolBuying.start : '',
                                                issueCount: isCourseGroup && tempSchoolBuying.end ? tempSchoolBuying.end : '',
                                                startDate: !isCourseGroup ? this.$util.unixTimestampToDatetime(tempSchoolBuying.start, this.$util.getBrowserCurrentTimeZone(), 'date') : '',
                                                endDate: !isCourseGroup ? this.$util.unixTimestampToDatetime(tempSchoolBuying.end, this.$util.getBrowserCurrentTimeZone(), 'date') : '',
                                                duration: tempSchoolBuying.duration,
                                                durationText: tempSchoolBuying.duration > 0 ? this.durationTextList[tempSchoolBuying.duration-1] : '',
                                                certificateLink: tempSchoolBuying.certificate_link,
                                                buyerEmail: tempSchoolBuying.email,
                                                collaborativeContract: tempSchoolBuying.item_name,
                                                description: tempSchoolBuying.description,
                                                note: tempSchoolBuying.note,
                                                isCourseGroup : isCourseGroup,
                                                courseIds : tempSchoolBuying.course_info.ids,
                                                courseList: courseList
                                            });
                                        }
                                    });
                                }

                                resolve();
                            }
                        }
                    })
                    .catch(error => {
                        console.error('catched error: ' + error);
                        reject("admin/school_buying/list/_table_list.js: 'getSchoolBuyingList' get error");
                    });
            });
        },
        downloadCertification(id) {
            let params = {
                id: id
            }

            this.$httpRequest.post('/admin_api/order/download_certificate', params)
                .then(async response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            try {
                                await this.$util.downloadFile(result.url, result.file_name);
                                this.isPostingApi.downloadExcel = false;
                            } catch (error) {
                                this.isPostingApi.downloadExcel = false;
                                console.error('Catched Error:', error);
                            }
                        }

                    }
                    else {
                        this.$store.dispatch('common/setAlert', { msg: '資料處理中，請稍後再試', status: 'danger' });
                    }
                })
                .catch(error => {
                    console.error('catched error: ' + error);
                });
        },
        showSchoolBuyingViewUsersDialogue(id) {
            this.listItem.id = id;
            $('#schoolBuyingViewUsersDialogue').modal('show');
        },
        showSchoolBuyingImportAccountDialogue(id, isWholeSchool) {
            this.listItem.id = id;
            this.listItem.isWholeSchool = isWholeSchool;
            this.importUserInfo =  {
                path: '',
                name: '',
                file: '',
                isBundleOIDC: 0,
            };
            $('#schoolBuyingImportAccountDialogue').modal({
                backdrop: 'static',
                show: true
            });
        },
        addImportAccountExcel() {
            let v = this;

            this.uploadErrorMsg.file = null;

            let tempUploadFile = this.$refs.importUser.files[0];

            let files = [tempUploadFile];
            let validFileTypes = ['xlsx', 'xls'];
            let perFileLimitSize = 209715200;  // 200mb

            this.uploadErrorMsg.file = this.$util.validateFiles(files, validFileTypes, perFileLimitSize);

            if (!this.uploadErrorMsg.file) {

                let reader = new FileReader();
                reader.readAsDataURL(tempUploadFile);
                reader.onload = function() {
                    let dataURL = reader.result;
                    v.importUserInfo.path = dataURL;
                }

                this.importUserInfo.name = tempUploadFile.name;
                this.importUserInfo.file = tempUploadFile;
            }

            this.$refs.importUser.value = null;
        },
        importAccount() {
            let params = new FormData();

            params.append('oslId', this.listItem.id);
            params.append('file', this.importUserInfo.file);
            params.append('isBundleOIDC', this.importUserInfo.isBundleOIDC ? 1 : 0);

            this.isPostingApi.accountUploading = true;
            this.$httpRequest.post('/admin_api/user/import_order_school_users', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    this.isPostingApi.accountUploading = false;
                    $('#schoolBuyingImportAccountDialogue').modal('hide');
                })
                .catch(error => {
                    this.isPostingApi.accountUploading = false;
                    $('#schoolBuyingImportAccountDialogue').modal('hide');
                    console.error('catched error: ' + error);
                });
        },
        showSchoolBuyingEditingDialogue(item) {
            this.listItem.id = item.id;
            this.listItem.inputBaanNo = item.baan;
            this.listItem.isWholeCounty = item.isWholeCounty;
            this.listItem.selectedCounty = item.county;
            this.listItem.selectedDistrict = item.district;
            this.listItem.selectedEdu = item.edu;
            this.listItem.isWholeSchool = item.isWholeSchool ? true : false;
            this.listItem.inputImportCount = item.importCount;
            this.listItem.selectedSchoolId = item.schoolId;
            this.listItem.selectedSchoolName = item.schoolName;
            this.listItem.selectedCourseIds = item.courseIds;
            this.listItem.selectedCourseList = item.courseList;
            this.listItem.selectedCertificationDate = item.certificationDate
            this.listItem.selectedActivityDate = item.activityDate;
            this.listItem.selectedDuration = item.duration === '0' ? '' : item.duration;
            this.listItem.selectedStartIssueNo = item.issueStart;
            this.listItem.inputIssueCount = item.issueCount;
            this.listItem.selectedStartDateTime = item.startDate;
            this.listItem.selectedEndDateTime = item.endDate;
            this.listItem.inputBuyerEmail = item.buyerEmail;
            this.listItem.selectedCollaborativeContract = {
                name: item.collaborativeContract,
                description: item.description
            };
            this.listItem.inputDescription = item.description;
            this.listItem.inputNote = item.note;

            $('#schoolBuyingEditingDialogue').modal({
                backdrop: 'static',
                show: true
            });
        },
        showSchoolBuyingDeletingDialogue(id) {
            this.listItem.id = id;
            $('#schoolBuyingDeletingDialogue').modal({
                backdrop: 'static',
                show: true
            });
        },
        deleteSchoolBuying() {
            $('#schoolBuyingDeletingDialogue').modal('hide');

            let params = {
                id: this.listItem.id
            }

            this.isPostingApi.deleting = true;
            this.$httpRequest.post('/admin_api/order/delete_school_order', params)
                .then(response => {
                    if (response.data.state == 'OK') {
                        this.$parent.getSchoolBuyingIdsByState();
                        this.$store.dispatch('common/setAlert', { msg: response.data.msg, status: 'success' });
                    }
                    this.isPostingApi.deleting = false;
                    $('#schoolBuyingDeletingDialogue').modal('hide');
                })
                .catch(function (error) {
                    console.error('catched error: ' + error);
                    this.isPostingApi.deleting = false;
                    $('#schoolBuyingDeletingDialogue').modal('hide');
                });
        }
    }
}
