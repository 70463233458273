// common
import Dialogue from "@/components/common/dialogue.vue"
// components
const ArticleList = resolve => require(["@/components/course/_article_list.vue"], resolve)

export default {
    props: ['courseInfo'],
    components: {
        Dialogue,
        ArticleList
    },
    data: function() {
        return {
            activeCategoryId: null,
            categoryList: [],
            initCategoryTabsDOMTop: null,
            isShowArrowBtn: false,
            allArticleIds: [],

            isScrollingTabs: false,  // 是否正在進行滑動分類

            renderArticleListKey: 0,
            orderAsc: false
        }
    },
    watch: {
        windowWidth(newValue, oldValue) {
            if (newValue) {
                this.$nextTick(() => {
                    $(window).scrollTop(0);
                    this.initCategoryTabsDOMTop = Math.floor($('.tab-wrapper.category-wrapper').offset().top);
                });
            }
            if ((newValue < 768 && oldValue >= 768) || (newValue >= 768 && oldValue < 768)) {
                this.setIsShowArrowBtn();
            }
        },
        '$route.params.categoryId'(newValue) {
            this.getCategoryArticleIds(newValue, false);
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        fromCategory: {
            get() {
                return this.$store.state.article.fromCategory;
            },
            set(value) {
                this.$store.commit('article/setFromCategory', value);
            }
        },
        introInfoTabList() {
            let tabs = [];
            tabs = [
                {
                    value: 'intro',
                    text: '課程簡介'
                },
                {
                    value: 'witness',
                    text: '好評口碑'
                }
            ]
            return tabs;
        }
    },
    created: function() {
        this.activeCategoryId = this.$route.params.categoryId || null;
        this.getCategoryArticleIds(this.activeCategoryId, false)
            .then(() => {
                this.$nextTick(() => {
                    this.setIsShowArrowBtn();
                    this.setCategoryScroll();
                });
            })
            .catch(error => {
                console.error('Catched Error:', error);
            });
    },
    mounted: function() {
        this.initCategoryTabsDOMTop = Math.floor($('.tab-wrapper.category-wrapper').offset().top);

        $('#courseIntroDialogue').on('shown.bs.modal', () => {
            this.$refs.introContent.scrollTop = 0;
        });

        if (this.$cookieStore.getCookie('is_scroll_to_pa') === '1') {
            this.$cookieStore.delCookie('is_scroll_to_pa');
        }
    },
    methods: {
        showCourseIntroDialogue() {
            $('#courseIntroDialogue').modal('show');
        },

        getCategoryArticleIds(categoryId, isSmoothScroll) {
            return new Promise((resolve, reject) => {
                // smooth scroll
                if (isSmoothScroll) {
                    let scrollTop = $(window).scrollTop();
                    let offset = this.windowWidth >= 768 ? 20 : 60;
                    if ((scrollTop + offset) != this.initCategoryTabsDOMTop) {
                        $('html, body').animate({
                            scrollTop: this.initCategoryTabsDOMTop - offset
                        }, 600);
                    }
                }

                // 初步檢查 categoryId 的值
                if (+categoryId < -1) {
                    this.$store.dispatch('common/setAlert', { msg: '分類不存在', status: 'danger' });
                    return;
                }

                let params = {
                    courseId: this.courseInfo.id
                };

                // 如果 categoryId 不為 null，則表示為使用者自行切換分類 tab 以查看該分類下的文章
                if (categoryId !== null && categoryId !== undefined) {
                    this.activeCategoryId = categoryId;
                    params.categoryId = this.activeCategoryId;
                }

                this.$httpRequest.get('/api/course/get_category_and_article_ids', params)
                    .then(response => {
                        if (response.data.state == 'OK') {
                            let result = response.data.result;

                            if (result) {
                                this.categoryList = [];
                                for (let el of result.categories) {
                                    this.categoryList.push({
                                        id: el.id,
                                        name: el.name
                                    });
                                }

                                if (!this.categoryList.length) {
                                    reject(`Get Info Error: "CourseId_${params.courseId}" doesn't have category list`);
                                    return;
                                }

                                // 若無指定 categoryId 則需 assign 第一個分類的 id 做為 activeCategoryId
                                if (categoryId === null || categoryId === undefined) {
                                    this.activeCategoryId = this.categoryList[0]['id'];
                                }
                                this.fromCategory = {
                                    id: this.activeCategoryId,
                                    courseId: params.courseId
                                };

                                // 若是正序，查出來的資料必須陣列反轉
                                this.allArticleIds = this.orderAsc == true ? result.articles.reverse() : result.articles;
                                this.renderArticleListKey++;

                                resolve();
                            }
                        }
                    })
                    .catch(error => reject(error));
            });
        },
        setIsShowArrowBtn() {
            const elem = document.getElementById('categoryList');
            if (elem) {
                // dom的滾動寬度 > dom的可視範圍寬度, 則表示會出現捲軸, 需顯示箭頭按鈕
                this.isShowArrowBtn = this.windowWidth >= 768 && (elem.scrollWidth > elem.clientWidth);
            }
        },
        setCategoryScroll() { // 定位activity的位子
            let category = '';
            this.categoryList.forEach((el, index) => {
                if ( index != 0 && el.id == this.activeCategoryId) {
                    category = index;
                }
            });
            if (category) {
                this.smoothScrollCategoryList(true, category);
            }
        },
        smoothScrollCategoryList(isScrollLeft, len) {
            if (this.windowWidth < 768) {
                return;
            }

            this.isScrollingTabs = true;

            let offset = len ? $('#categoryList > li').width() * +len : $('#categoryList > li').width() * 6;
            $('#categoryList').animate({
                scrollLeft: isScrollLeft ? `+=${offset}` : `-=${offset}`
            }, 1000);

            setTimeout(() => this.isScrollingTabs = false, 1000);
        },
    }
}
