import Layout from "@/layouts"

// components
const ResultList = resolve => require(["@/components/search/_result_list.vue"], resolve)

export default {
    name: 'Search',
    components: {
        Layout,
        ResultList
    },
    data: function() {
        return {
            searchedKeyword: '',

            myCourseList: [],
            selectedCourseId: 0,
            selectedCourseIndex: 0,

            sortingOrder: '',

            filterTypeList: {},
            selectedTypes: {},

            flags: [],

            isSetDataReady: {
                searchArticles: false,  // 依據關鍵字搜尋文章
            }
        }
    },
    watch: {
        '$route.query.q'() {
            this.searchByKeyword();
        },
        userInfo(newValue) {
            // 未登入 -> 已登入
            if (!this.$_.isEmpty(newValue)) {
                this.searchByKeyword();
            }
        }
    },
    computed: {
        windowWidth() {
            return this.$store.state.common.windowWidth;
        },
        userInfo() {
            return this.$store.state.common.userInfo;
        },
        layout() {
            return this.$store.state.common.layout;
        },
        isSetHeaderDataReady: {
            get() {
                return this.$store.state.common.isSetHeaderDataReady;
            },
            set(value) {
                this.$store.commit('common/setIsSetHeaderDataReady', value);
            }
        },
        inputKeyword: {
            get() {
                return this.$store.state.common.inputKeyword;
            },
            set(value) {
                this.$store.commit('common/setInputKeyword', value);
            }
        },
        isShowSearchBar: {
            get() {
                return this.$store.state.common.isShowSearchBar;
            },
            set(value) {
                this.$store.commit('common/setIsShowSearchBar', value);
            }
        },
        searchTotal: {
            get() {
                return this.$store.state.common.searchTotal;
            },
            set(value) {
                this.$store.commit('common/setSearchResultTotal', value);
            }
        },
        renderListKey() {
            return `${this.selectedCourseId}|${JSON.stringify(this.selectedTypes)}`;
        }
    },
    // 跳離 search.vue 這個主路由 (history mode)
    beforeRouteLeave(to, from, next) {
        this.inputKeyword = '';

        // for "優化前過渡版本 (Mobile)"
        this.isShowSearchBar = false;

        next();
    },
    created: function () {
        if (this.layout.name !== 'LayoutDefault') return;

        this.$store.commit('common/setHeaderActiveLink', '');
        this.$store.commit('common/setIsAdminPage', false);

        this.searchByKeyword();
    },
    methods: {
        searchByKeyword() {
            this.inputKeyword = decodeURIComponent(this.$route.query.q);
            this.searchedKeyword = this.inputKeyword;

            // 設定 Meta Tag
            let pageTitle = `${this.inputKeyword} - ${process.env.VUE_APP_TITLE}`;
            this.$util.updateMeta(pageTitle);

            // 初始化
            this.myCourseList = [
                {
                    id: 0,
                    name: '全部已購買的課程'
                }
            ];
            this.filterTypeList = {
                content: '文章閱讀',
                comment: '內容講解',
                tag: '文章標籤'
            }

            // 預設是所有的選項都需要checked
            for (let key in this.filterTypeList) {
                this.$set(this.selectedTypes, key, true);
                this.flags.push(key);
            }

            // for "優化前過渡版本 (Mobile)"
            this.isShowSearchBar = true;

            this.getCourses();
        },
        getCourses() {
            this.isSetDataReady.searchArticles = false;

            this.$httpRequest.get('/api/course/get_course_id_name')
                .then(response => {
                    if (response.data.state == 'OK') {
                        let result = response.data.result;

                        if (result) {
                            // 設定課程列表
                            for (let el of result) {
                                this.myCourseList.push({
                                    id: el.id,
                                    name: el.name
                                });
                            }

                            // 搜尋成功後需重新取得 header '最近搜尋紀錄' 資訊
                            this.isSetHeaderDataReady.getMyRecentSearchedData = false;
                            this.isSetDataReady.searchArticles = true;
                        }
                    }
                })
                .catch(error => {
                    console.error('Catched Error:', error);
                });
        },
        resetFlags() {
            this.flags = [];
            for (let key in this.filterTypeList) {
                if (this.selectedTypes[key] == true ) {
                    this.flags.push(key);
                }
            }
            if (!this.flags.length) {
                this.searchTotal = 0;
            }
        }
    }
}
